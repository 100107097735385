.addon-offer-widget-logo {
  height: 55px;
}

.addon-offer-widget {
  margin: 0 0 20px;
  line-height: 20px;
  background: #ebeef2;
  border: 1px solid #576166;
}

.addon-offer-widget-content {
  padding: 20px 40px 20px 20px;
  font-size: 1em;
  color: #0b1419;
  background: #ebeef2;
}

.addon-offer-widget--collapsible .addon-offer-widget-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 5px 15px 20px;
}

.addon-offer-widget--collapsible .base-link {
  margin: 0 15px 0 0;
}

.addon-offer-widget--collapsible .base-link i {
  padding: 10px 0;
  font-size: 1em;
  color: #0c4261;
}

.addon-offer-widget--collapsible p.addon-offer-widget-content__action {
  width: 100%;
}

.addon-offer-widget--expanded .addon-offer-widget-content {
  flex-direction: column;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
}

.addon-offer-widget-content__action {
  display: flex;
  align-items: center;
}

.addon-offer-widget--expanded .addon-offer-widget-content__action {
  align-items: flex-start;
  justify-content: center;
}

.addon-offer-widget--expanded .addon-offer-widget-content__action input {
  margin: 3px 10px 0 0;
}

.addon-offer-widget--expanded .base-link {
  margin: 0;
}

.addon-offer-widget ul {
  margin: 15px 0 20px;
  color: #576166;
}

.addon-offer-widget-content > div {
  width: 100%;
}

.addon-offer-widget a {
  color: #0c4261;
  text-decoration: underline;
}

.addon-offer-widget-content h1,
.addon-offer-widget-content p {
  width: 100%;
}

.addon-offer-widget-content h1 {
  font-size: 2em;
  font-weight: 500;
  color: #0c4261;
}

.addon-offer-widget-content__disclaimer {
  margin: 15px 0;
}

.addon-offer-widget-content__action .addon-offer-widget-logo {
  display: none;
}

.addon-offer-widget-content__action input {
  margin: 0 10px 0 0;
}

.addon-offer-widget-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background: #fff;
}

.addon-offer-widget-footer__links {
  display: flex;
  flex-direction: column;
  margin: 0 0 20px;
  font-size: 0.85em;
  line-height: 2em;
  text-align: center;
}

.addon-offer-widget-content__details {
  margin: 10px 0 0;
  font-size: 14px;
}

/* Migrate to less @sm */
@media screen and (width >= 768px) {
  .addon-offer-widget-content__action .addon-offer-widget-logo {
    display: block;
  }

  .addon-offer-widget-content ul {
    list-style: none;
  }
}

/* Migrate to less @md */
@media screen and (width >= 992px) {
  .addon-offer-widget-content {
    padding: 20px 40px;
    background: #ebeef2;
  }

  .addon-offer-widget--expanded .addon-offer-widget-content {
    flex-direction: row;
    text-align: left;
  }

  .addon-offer-widget--expanded .base-link {
    margin: 0 15px 0 0;
  }

  .addon-offer-widget--expanded .addon-offer-widget-content__action {
    justify-content: flex-start;
  }

  .addon-offer-widget-content ul {
    list-style: disc;
  }

  .addon-offer-widget-content h1,
  .addon-offer-widget-content p {
    width: 90%;
  }

  .addon-offer-widget-content__action input {
    margin: 0 10px 0 0;
  }

  .addon-offer-widget-footer {
    flex-direction: row;
    padding: 20px 40px 40px;
  }

  .addon-offer-widget-footer__links {
    margin: 0;
    font-size: 0.75em;
    line-height: 1.5em;
    text-align: left;
  }

  .addon-offer-widget-footer__disclaimer {
    margin-top: 6px;
  }
}
