@import '../../../../../less/external/variables.less';

.chub-checkout-form {
  &__heading {
    background-color: @grey_light;
    border: 1px solid @grey_field;
  }

  &__heading-btn {
    display: block;
    width: 100%;
    padding: 10px;
    text-align: left;
    background: transparent;
    border: none;

    .fa {
      width: 10px;
      margin-right: 5px;
      text-align: center;
    }
  }

  &__form-body,
  &__form-footer {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  &__form-body {
    padding: 10px;
    border: 1px solid @grey_field;
    border-top: none;
  }

  &__form-footer {
    margin-top: 20px;
  }

  &__btn {
    font-size: 1.2em !important;

    @media @xssm {
      height: 36px;
    }

    @media @mdlg {
      height: 46px;
    }
  }

  &__btn--goback {
    background: #f0f0f0;
  }

  &__input {
    &--2x {
      grid-column: 1 / 3;
    }

    .help-block {
      position: absolute;
      margin: 0;
    }

    @media screen and (width <= 600px) {
      grid-column: 1 / 3;
    }
  }
}
