.search-suggestions__section {
  margin-top: @gutter;
  margin-bottom: 10px;
}

.search-suggestions__section .hack-h-tag-h5 {
  margin-bottom: 5px;
}

.search-suggestions__selectable {
  display: block;
  padding-left: 4px;
  margin-left: -15px;
  font-family: Georgia, serif;
  cursor: pointer;
  border-left: 4px solid @white;
}

.search-suggestions__selectable:hover {
  border-left: 4px solid @orange;
}

.search-suggestions__selectable .search-suggestions__selectable-content {
  padding: 5px;
}

.search-suggestions__selectable:hover .search-suggestions__selectable-content {
  .selector-hover();

  color: @grey;
  background: none;
  border-left: none;
}

.action-suggestion.friendly {
  font-size: 18px;
  line-height: 24px;
}

.action-suggestion.friendly i {
  font-size: 40px;
  color: @link-color;
}

/**********************
       No results
***********************/

.no-results-for-term-info {
  font-family: FigtreeBold, sans-serif;
  font-size: @font-size-h3;
  font-weight: 700;
  color: @blue_dark;
}

.no-results-term.bold {
  color: @link-color;
}
