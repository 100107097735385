.site-title-attributes {
  font-size: 20px;
  font-weight: 700;
}

.ada-accessible-icon {
  width: 28px;
  padding: 2px;
  margin-top: 2px;
  margin-bottom: 2px;
  font-size: 15px;
  font-weight: 700;
  color: @white;
  text-align: center;
  background-color: @blue_dark;
  .border-radius(4px);
}

.smiley1 .btn-selection-group__label,
.smiley2 .btn-selection-group__label,
.smiley3 .btn-selection-group__label,
.smiley4 .btn-selection-group__label {
  display: inline-block;
  padding-top: 2px;
  padding-right: 15px;
  padding-left: 20px;
}

.smiley1,
.smiley2,
.smiley3,
.smiley4 {
  position: relative;
  padding-right: 30px;
  padding-left: 30px;
  text-align: left;
}

.smiley1::before,
.smiley2::before,
.smiley3::before,
.smiley4::before {
  position: absolute;
  display: inline-block;
  width: 25px;
  font-family: FontAwesome;
  font-size: 18px;
  color: @grey;
}

.smiley1::before {
  content: '\f118';
}

.smiley2::before {
  content: '\f11a';
}

.smiley3::before {
  content: '\f119';
}
