/*********************************************************************************************
  Purpose:
  --------
  Contains styles for the FlipCard component.

  Notes:
  ------
  Document all elements with comments.
**********************************************************************************************/

.card-container {
  position: relative;
  z-index: 1;
  width: 100%;
  perspective: 400px;
}

.cardsize-1x,
.cardsize-1x .card-main-content {
  height: 140px;

  @media @xs {
    height: 130px;
  }
}

.cardsize-2x,
.cardsize-2x .card-main-content {
  height: 180px;

  @media @xs {
    height: 162px;
  }
}

.cardsize-3x,
.cardsize-3x .card-main-content {
  height: 200px;

  @media @xs {
    height: 180px;
  }
}

.cardsize-4x,
.cardsize-4x .card-main-content {
  height: 280px;
}

.cardsize-5x,
.cardsize-5x .card-main-content {
  height: 380px;

  @media @xs {
    height: 300px;
  }
}

.cardsize-6x,
.cardsize-6x .card-main-content {
  height: 410px;

  @media @xs {
    height: 340px;
  }
}

.card-body {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: all 0.5s ease-out;
}

.card-container-flip {
  transform: rotateY(180deg);
}

.card-front-flip,
.card-back-flip {
  visibility: hidden;
  filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  opacity: 0;
  transition:
    opacity 0.75s ease-out,
    visibility 0.5s ease-out;
}

.card-side {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  // overflow: hidden;
  color: #212121;
  background-color: #fff;
  box-shadow:
    0 10px 35px rgb(50 50 93 / 10%),
    0 2px 15px rgb(0 0 0 / 7%);
  backface-visibility: hidden;
}

.side-front [class^='col-xs']:first-of-type {
  padding-left: 0;
}

.side-front {
  backface-visibility: visible;
}

.side-back {
  backface-visibility: visible;
  transform: rotateY(180deg);
}

.card-action-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.card-action-button {
  .icon-action i {
    background-image: radial-gradient(at center, @white 50%, transparent 50%);

    &::before {
      color: @orange;
    }
  }
}
