.sw-widget .addon-offer-widget-logo {
  height: 30px;
}

.sw-widget .addon-offer-content__details {
  margin: 10px 0 0;
  font-size: 14px;
}

/* Migrate to less @md */
@media screen and (width >= 992px) {
  .sw-widget .addon-offer-widget-content {
    background: url('../../../../../web/owp-webclient/assets/images/ra/sensible-weather/sensible-background.svg'),
      #ebeef2;
    background-repeat: no-repeat;
    background-position: top 5px right 5px;
    background-size: auto 90%;
  }
}
