.facility-fees {
  width: 100%;
  margin-top: 10px;
}

.facility-fees td {
  vertical-align: top;
}

.fee-group__product-name,
.fee-group__season-rates {
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid @border;
}

.facility-fees .fee-season {
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid @border;
}

.facility-fees-container {
  margin-top: @gutter;
}

.fee-group__product-name {
  width: 40%;
}

.fee-group__season-rates {
  width: 60%;
}

.fee-group__rate.t-right {
  @media @xs {
    text-align: left;
  }
}

.fee-group__season-dates {
  @media @xs {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid @border;
  }
}

.fee-product-group {
  .clr();
}
