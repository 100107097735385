.svg-map-container {
  position: relative;
  margin-bottom: @gutter;
  overflow: hidden;
}

.svg-map-widgets {
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  margin: 10px;
}

.svg-map-widgets button {
  float: left;
  width: 30px;
  height: 30px;
  padding-top: 4px;
  margin-bottom: 4px;
}

.svg-map-widgets button i {
  font-size: 10px;
}

.product-display-container {
  @media @xssm {
    position: relative;
  }

  @media @mdlg {
    position: absolute;
    bottom: 14px;
    left: 10px;
    z-index: @z_below_overlay_base;
    width: 60%;
    padding: 15px;
    background-color: rgb(255 255 255 / 90%);
    border: 1px solid @white;
    box-shadow: 1px 1px 3px 0 rgb(0 0 0 / 100%);
    .border-radius(3px);
  }
}

.product-display-image {
  margin-bottom: 15px;
}

.product-display-container .hack-h-tag-h1 {
  font-size: 22px;
  color: @link-color;
  letter-spacing: 0;
}

.product-display-container__call-to-action {
  margin-top: 15px;

  @media @mdlg {
    padding-left: 0;
  }
}

.product-display-container .btn-success,
.product-display-container .btn-primary {
  width: auto;
  padding-right: 20px;
  padding-left: 20px;

  @media @mdlg {
    margin: 0 auto;
  }
}

.map-site-info-value {
  font-family: Figtree, sans-serif;
  color: @blue_dark;
}

.svg-map-container {
  @media @mdlg {
    width: 100%;
    padding-bottom: 100%;
  }
}

.svg-map {
  @media @mdlg {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.svg-map-widgets {
  z-index: @z_below_overlay_base;
}
