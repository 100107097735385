@import '../../../../../less/themes/ra/variables.less';

.chub-spot {
  display: grid;
  grid-template: 'image image' 'info booking';
  grid-template-rows: 200px 1fr;
  grid-template-columns: 1fr 80px;
  gap: 5px;
  min-width: 300px;
  margin: 0;
  overflow: hidden;
  border-radius: 3px;
  box-shadow: rgb(0 0 0 / 45%) 1px 0 8px 0;

  &--category {
    grid-template-columns: 1fr 160px;
    grid-template-areas: 'image image' 'category category';
  }

  &__image-wrapper {
    grid-area: image;
  }

  &__image-btn,
  &__image-stub {
    position: relative;

    &::before {
      position: absolute;
      z-index: 2;
      color: #fff;
      content: '\f03e';
    }
  }

  &__image-btn {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0;
    overflow: hidden;
    background-color: #ddd;
    border: none;
    border-radius: 0;

    &::before {
      right: 10px;
      bottom: 10px;
    }
  }

  &__image-loader {
    display: block;
    width: 100%;
    height: 100%;
  }

  &__image {
    object-fit: cover;
  }

  &__image-stub {
    width: 100%;
    height: 100%;
    background-color: #ddd;

    &::before {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__info-column {
    display: flex;
    flex-direction: column;
    grid-area: info;
    gap: 5px;
    padding: 0 0 5px 5px;
  }

  &__info-column--category {
    padding: 0 5px 5px 5px;
    grid-area: category;
  }

  &__description {
    font-size: 0.9em;
  }

  &__amenities {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    padding: 0;
    margin: 0;
    margin-top: auto;
    list-style: none;
  }

  &__amenity {
    padding: 2px 3px;
    margin: 0;
    font-size: 0.8em;
    line-height: 1;
    background-color: #fafafa;
    border: 1px @status-foreground-reversed solid;
    border-radius: 3px;
  }

  &__booking-column {
    display: flex;
    flex-direction: column;
    grid-area: booking;
    justify-content: space-between;
    min-height: 110px;
    padding: 0 5px 5px 0;
  }

  &__price-info {
    padding-top: 5px;
    padding-right: 5px;
    text-align: right;
  }

  &__price {
    font-size: 1.3em;
    line-height: 1.1;
    color: @status-available;
  }

  &__price-details {
    font-size: 0.8em;
  }

  &__total-price {
    font-size: 0.8em;
    color: #000;
  }

  &__booking-btn {
    height: auto;
    font-size: 0.9em;
    line-height: 1.4;
    text-transform: uppercase;
  }
}
