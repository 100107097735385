/***********************
        weather
************************/
.weather {
  margin: 0 auto;
  margin-bottom: 20px;
  border: 1px solid @weather-border;
  with: 100%;

  @media @xssm {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.weather-current {
  height: 200px;
  padding: 25px 0;
  text-align: center;
  background-color: @weather-current-bg;
}

.weatherAPI-text {
  padding-right: 5px;
  margin-top: 45px;
  font-size: 12px;
  text-align: right;
}

.weather-current-icon {
  padding-top: 10px;
  font-size: 50px;
  color: @white;
  text-align: center;
}

.weather-current-tempdegree {
  position: relative;
  font-size: 22px;
  color: @white;

  .weather-fahrenheit-tempdegree {
    display: inline-block;
    margin-right: 5px;
  }

  .weather-fahrenheit-icon {
    position: absolute;
    top: 4px;
    font-size: 30px !important;
    font-weight: 700;
  }
}

.weather-current-hit {
  font-size: 10px;
  color: @weather-current-hit;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.weather-today {
  height: 40px;
  padding: 13px 15px;
  font-size: 12px;
  color: @weather-today;
  background-color: @weather-today-bg;
  .clr;
}

.weather-label {
  float: left;
}

.weather-value {
  float: right;
}

.weather-icon {
  position: absolute;
  left: 50%;
  margin-left: -10px;
  font-size: 20px;
}

.weather-forecast {
  padding: 15px;
  list-style: none;
  background-color: @weather-forecast-bg;

  .weather-forecast-item {
    position: relative;
    height: 40px;
    padding: 10px 0;
    font-size: 12px;
    font-weight: 400;
    color: @weather-forecast-item;
    text-align: center;
    .clr;
  }
}
