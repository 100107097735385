/* style */
.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

/* colours */
.blue-dark-text {
  color: @blue_dark;
}

.cerulean-text {
  color: @cerulean;
}

.grey-text {
  color: @grey;
}

.white-text {
  color: @white;
}

.orange-text {
  color: @orange;
}

.green-text {
  color: @green_cart_timer;
}

.red-text {
  color: @red;
}

/* sizes */
.font-size-1x {
  font-size: @font-size-text-styler-1x;
  line-height: 100%;
}

.font-size-2x {
  font-size: @font-size-text-styler-2x;
  line-height: 100%;
}

.font-size-3x {
  font-size: @font-size-text-styler-3x;
  line-height: 100%;
}

.font-size-4x {
  font-size: @font-size-text-styler-4x;
  line-height: 100%;
}

.font-size-5x {
  font-size: @font-size-text-styler-5x;
  line-height: 100%;
}

.font-size-6x {
  font-size: @font-size-text-styler-6x;
  line-height: 100%;
}

.font-size-7x {
  font-size: @font-size-text-styler-7x;
  line-height: 100%;
}

.font-size-8x {
  font-size: @font-size-text-styler-8x;
  line-height: 100%;
}

/* font-weights */
.font-weight-100 {
  font-weight: 100;
}

.font-weight-200 {
  font-weight: 300;
}

.font-weight-300 {
  font-family: FigtreeLight, sans-serif;
  font-weight: 300;
}

.font-weight-400 {
  font-family: Figtree, sans-serif;
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-family: FigtreeMedium, sans-serif;
  font-weight: 600;
}

.font-weight-700 {
  font-family: FigtreeBold, sans-serif;
  font-weight: 700;
}

.font-weight-800 {
  font-weight: 800;
}

.font-weight-900 {
  font-weight: 900;
}

/* font families */
.default-text {
  font-family: Figtree, sans-serif;
  color: @blue_dark;
}

.default-serif-text {
  font-family: Georgia;
  color: @blue_dark;
}

.default-header {
  font-family: Figtree, sans-serif;
  color: @blue_dark;

  &.font-weight-700 {
    font-family: FigtreeBold, sans-serif;
  }
}

.default-serif-header {
  font-family: Georgia;
  color: @blue_dark;
}

.font-family-Georgia {
  font-family: Georgia;
}

.font-family-Figtree {
  font-family: Figtree, sans-serif;
}

/* letter spacing */
.letter-spacing-normal {
  letter-spacing: normal;
}

.letter-spacing-1px {
  letter-spacing: 1px;
}

.letter-spacing-2px {
  letter-spacing: 2px;
}

.letter-spacing-3px {
  letter-spacing: 3px;
}

/* text-transform */
.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

/* truncate text lines */
.text-truncate-after {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 50%;
  height: 1.3em;
  text-align: right;
  content: '';
  background: linear-gradient(to right, rgb(255 255 255 / 0%), #fff 80%);
}
.text-truncate-display (@lines) {
  position: relative;
  display: block;
  max-height: 1.3em * @lines;
  overflow: hidden;
  line-height: 1.3em;
}
.text-truncate-webkit (@lines) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: @lines;
  -webkit-box-orient: vertical;
}

.text-truncate-1 {
  .text-truncate-display(1);

  &::after {
    .text-truncate-after;
  }
}

@supports (-webkit-line-clamp: 1) {
  .text-truncate-1 {
    .text-truncate-webkit(1);

    &::after {
      display: none;
    }
  }
}

.text-truncate-2 {
  .text-truncate-display(2);

  &::after {
    .text-truncate-after;
  }
}

@supports (-webkit-line-clamp: 2) {
  .text-truncate-2 {
    .text-truncate-webkit(2);

    &::after {
      display: none;
    }
  }
}

.text-truncate-3 {
  .text-truncate-display(3);

  &::after {
    .text-truncate-after;
  }
}

@supports (-webkit-line-clamp: 3) {
  .text-truncate-3 {
    .text-truncate-webkit(3);

    &::after {
      display: none;
    }
  }
}

.text-truncate-4 {
  .text-truncate-display(4);

  &::after {
    .text-truncate-after;
  }
}

@supports (-webkit-line-clamp: 4) {
  .text-truncate-4 {
    .text-truncate-webkit(4);

    &::after {
      display: none;
    }
  }
}

.text-truncate-5 {
  .text-truncate-display(5);

  &::after {
    .text-truncate-after;
  }
}

@supports (-webkit-line-clamp: 5) {
  .text-truncate-5 {
    .text-truncate-webkit(5);

    &::after {
      display: none;
    }
  }
}

.text-truncate-6 {
  .text-truncate-display(6);

  &::after {
    .text-truncate-after;
  }
}

@supports (-webkit-line-clamp: 6) {
  .text-truncate-6 {
    .text-truncate-webkit(6);

    &::after {
      display: none;
    }
  }
}
