.chub-confir__datails {
  margin-top: 20px;
  border: 1px solid #ccc;
}

.chub-confir__datails-2 {
  padding: 20px;
}

.chub-confir__datails-title-box {
  width: 100%;
  padding: 10px;
  font-size: 1.5em;
  font-weight: 700;
  color: #000;
  text-align: start;
  background-color: #eee;
  border-bottom: 1px solid #ccc;
}

.bold {
  font-weight: 700;
}

.chub-confir__item {
  font-weight: normal;
}

.status-value {
  font-weight: 700;
  color: green;
}

.line {
  width: 100%;
  margin: 10px 0;
  border-top: 1px solid #ccc;
}

.chub-confir__totals-table {
  width: 100%;
  padding: 20px;

  td {
    width: 50%;
    text-align: start;
  }
}

.chub-confir__table-container {
  display: flex;
}

.table__totals-table {
  box-sizing: border-box;
  width: 100%;
  max-width: 360px;
  padding: 0 30px;
  margin-left: auto;

  td {
    width: 50%;
    text-align: right;
  }
}

.table__total-row td {
  font-family: Figtree, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

.status-in-progress {
  color: blue;
}

.status-complete {
  color: green;
}
