.chub-search {
  &__form {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;

    @media all and (width > 1290px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__form-column {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(6, 1fr);
    grid-auto-flow: row dense;
    gap: 10px;
    align-content: start;
  }

  &__visit-dates {
    white-space: nowrap;
  }

  &__input {
    grid-column: auto / span 6;
  }

  &__input--half {
    grid-column: auto / span 3;
  }

  &__input--third {
    grid-column: auto / span 2;
  }

  &__input--two-third {
    grid-column: auto / span 4;
  }

  &__input--error {
    color: red;
  }

  &__input--help {
    display: block;
    min-height: 22.4px;
  }

  &__submit-btn {
    grid-column: 1 / -1;
    text-transform: uppercase;
  }
}
