.chub-spots {
  &__title,
  &__desc {
    text-align: center;
  }

  &__title {
    margin: 10px 0 5px;
    font-size: 1.2em;
    font-weight: 700;

    &--spaced {
      margin: 0 0 30px;
    }
  }

  &__desc {
    margin: 5px 0 10px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__spot {
    width: 100%;
  }

  &__gallery {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1250;
    width: 100%;
    height: 100%;
  }
}
