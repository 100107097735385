//Start Ctane - styles to be moved to different less file
#owpUpdateProfile {
}

#owpUpdateProfile #pagearea {
}

#owpUpdateProfile #plactivelogo {
  display: none;
}
//End Ctane

body.owpLayout #page {
  background: none;
}

body.owpLayout #pagearea {
  padding: 0 26px;
}

body.owpLayout #contentArea {
  margin-top: 20px;
}

body.owpLayout #rightcol_wrapper {
  width: 500px;
  margin-top: 30px;
}

body.owpLayout label {
  font-weight: normal;
}

body.owpLayout form {
  margin-top: 10px;
}

body.owpLayout #leftcol_wrapper {
  margin: 30px 60px 0 0;
  background-image: none !important;
  border-right: 2px solid #ddd;
}

body.owpLayout #leftcol_wrapper .r {
  margin: 3px 0;
}

body.owpLayout #leftcol_wrapper label.extra {
  display: none;
}

body.owpLayout #togglepassword {
  margin: -6px 3px 0 0;
}

body.owpLayout span.groupLabel {
  font-family: FigtreeBold, sans-serif;
  font-size: 13px !important;
  font-weight: 700;
}

body.owpLayout h1 {
  padding-bottom: 15px !important;
  font-size: 24px;
  text-transform: none;
  letter-spacing: 0;
  background-image: none !important;
  border-bottom: 2px solid #ddd;
}

body.owpLayout h2 {
  margin-top: 0 !important;
  font-size: 16px !important;
  text-transform: none;
  letter-spacing: 0;
}

body.owpLayout p.grey_txt {
  margin-top: 15px;
  font-size: 12px;
}

body.owpLayout #passwordCheckBoxGrp .groupcard {
  display: table;
}

body.owpLayout #togglepassword {
  display: table-cell;
  margin: 10px 5px 10px 0;
}

body.owpLayout label[for='togglepassword'] {
  display: table-cell;
  vertical-align: middle;
}

body.owpLayout .r {
  margin: 3px 0;
}

body.owpLayout .group {
  float: left;
}

body.owpLayout input[type='text'],
body.owpLayout input[type='password'],
body.owpLayout input[type='text'] select {
  height: 30px;
}

body.owpLayout input[type='checkbox'] {
  width: 12px;
  height: 12px;
  margin-right: 6px;
}

body.owpLayout #passwordBoxGrp,
body.owpLayout #passwordTxtGrp {
  float: left;
  margin-top: -2px;
  clear: left;
}

body.owpLayout #passwordCheckBoxGrp {
  margin-top: -3px;
}

body.owpLayout #additionalPhoneGroup .sgrps {
  padding-top: 10px;
}

body.owpLayout #pswrdInsGrp {
  margin-bottom: 0 !important;
}

body.owpLayout #signinbutton button,
body.owpLayout #signupbutton button,
body.owpLayout #continue_clicked_button,
body.owpLayout #forgotpwd_wrapper button,
body.owpLayout #changepolicy_wrapper button,
body.owpLayout #policyRevisionForm button {
  height: 50px !important;
  font-family: FigtreeMedium, Arial, sans-serif !important;
  font-size: 18px !important;
  cursor: pointer;
  background: #ed6e17;
  border-radius: 5px !important;
  transition: iitial;
}

body.owpLayout #forgotpwd_wrapper button:disabled,
body.owpLayout #forgotpwd_wrapper button:disabled:hover {
  color: #fff !important;
  cursor: default !important;
  background-color: #c1c1c1 !important;
  background-image: none !important;
}

@media (width <= 767px) {
  body.owpLayout #pagearea {
    padding: 0 0 40px;
  }

  body.owpLayout h1 {
    width: 94%;
  }

  body.owpLayout #signin_wrapper p.grey_txt {
    display: block !important;
  }

  body.owpLayout #contentArea {
    margin-top: 20px;
  }

  body.owpLayout #leftcol_wrapper {
    margin-top: 20px !important;
  }

  body.owpLayout #leftcol_wrapper,
  body.owpLayout #rightcol_wrapper {
    width: 94%;
    background-color: none !important;
  }

  body.owpLayout #leftcol_wrapper {
    border-right: none;
  }

  body.owpLayout .formrow_wrapper {
    border: none !important;
  }

  body.owpLayout form#existing_cust .attrs {
    float: none;
    width: 100%;
  }

  body.owpLayout input[type='text'],
  body.owpLayout input[type='password'] {
    width: 100% !important;
    height: 30px;
  }

  body.owpLayout form#new_cust select {
    width: 100%;
    height: 30px;
    margin: 0;
    text-indent: 2px;
    background-color: white !important;
    border: 1px solid #bbb;
    border-radius: 4px;
    box-shadow:
      0 1px 4px rgb(0 0 0 / 10%) inset,
      0 1px 1px rgb(255 255 255 / 70%);
    transition:
      border 0.2s linear 0s,
      box-shadow 0.2s linear 0s;
  }

  body.owpLayout div#leftcol_wrapper div.msg.error {
    margin: 0 0 15px;
  }

  body.owpLayout div#leftcol_wrapper div.error_item {
    margin: 0;
  }

  body.owpLayout #rightcol_wrapper .group {
    width: 100%;
  }

  body.owpLayout .additionalLinkStyle {
    padding: 0 0 8px;
  }

  body.owpLayout div.promo {
    margin: 10px;
  }

  body.owpLayout div.promo .chbox,
  body.owpLayout div.promo label {
    display: table-cell !important;
  }

  body.owpLayout #passwordBoxGrp,
  body.owpLayout #passwordTxtGrp {
    width: 100%;
    margin-bottom: 0;
  }

  body.owpLayout .topbar-navigation--level-2 {
    padding: 10px !important;
  }
}

/* GO - AWO-102636 - PCR 8287 - BETA Launch - UWP Campground Directory (Brand Updates) & AWO-101432 - PCR 8287 - BETA Launch - UWP Articles Pages (Brand Updates) */
body.owpLayout #page {
  margin-bottom: 40px;
}

body.owpLayout#articlePage #page {
  background-color: #fff;
}

body.owpLayout .main-header--overlay {
  position: relative;
}

body.owpLayout h2 {
  margin-bottom: 5px;
}

body.owpLayout h3 {
  margin-top: 5px;
}

body.owpLayout #colsidebar2 {
  display: none;
}

body.owpLayout div.stateindex ul {
  margin-top: 10px;
}

body.owpLayout a.book.now {
  height: 38px;
}

body.owpLayout h2 {
  margin-bottom: 5px;
}

body.owpLayout h3 {
  margin-top: 10px;
}

body.owpLayout #colsidebar2 {
  display: none;
}

body.owpLayout div.stateindex ul {
  margin-top: 10px;
}

body.owpLayout a.book.now {
  height: 38px;
}

body.owpLayout#articlePage
  .unifSearchCriteria
  div.criteria_section
  span.loading {
  float: right;
}

body.owpLayout#articlePage form {
  margin-top: 0;
}

body.owpLayout#articlePage #acm-search #btnDiv button {
  margin-top: 10px;
}

body.owpLayout#articlesPage .outdoor_spot_wrapper {
  width: 236px;
}

body.owpLayout#articlesPage .outdoor_spot_wrapper.ad {
  width: 316px;
}

body.owpLayout .composite_autocomplete {
  z-index: 16500000;
  width: 310px;
  padding: 6px;
  font-size: 14px;
  background-color: #fff;
  border: 1px solid;
  border-color: gray;
  box-shadow: rgb(0 0 0 / 30%) 0 5px 5px;
}

/* End GO - */
