/*********************************************************************************************
  Purpose:
  --------
  Contains styles for multiple common/internal components.
  Styles for individual components may be moved to separate files later.

  Notes:
  ------
  Document all elements with comments - at some point styles for components may be moved
  to separate files. So documenting is important.
**********************************************************************************************/

/***********************
     SIMPLE HERO
************************/
.simple-hero {
  position: relative;
  height: 335px;
  background-color: #313741;
  background-image: url('../../../../../web/owp-webclient/assets/images/ra/no.photo.header.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  @media @xssm {
    height: 260px;
  }
}

.simple-hero-header {
  position: absolute;
  bottom: 40px;
}

/***************************
      ContactUsCardFront
      ContactUsCardBack
****************************/
@contact-left-pos: 10px;
@contact-top-bottom-pos: 10px;

.contact-card-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.contact-card-logo {
  max-width: 90%;
  max-height: 50px;

  @media @xssm {
    // height:35px;
  }
}

.contact-card-top-section {
  position: absolute;
  top: @contact-top-bottom-pos;
  left: @contact-left-pos;

  &.contact-title {
    padding-top: 20px;
    padding-right: 10px;
    line-height: 1;
  }
}

.contact-card-bottom-section {
  position: absolute;
  bottom: @contact-top-bottom-pos;
  left: @contact-left-pos;
}

/***************************
      ContactUsLayout
  ****************************/
.contact-us-page-hero {
  background-image: url('../../../../../web/owp-webclient/assets/images/ra/contact-us-hero.jpeg');
}

/***************************
      Padder
  ****************************/
.padder-pad-top-10 {
  padding-top: 10px;
}

.padder-pad-top-20 {
  padding-top: 20px;
}

.padder-pad-bottom-10 {
  padding-bottom: 10px;
}

.padder-pad-bottom-20 {
  padding-bottom: 20px;
}

.padder-pad-left-10 {
  padding-left: 10px;
}

.padder-pad-left-20 {
  padding-left: 20px;
}

.padder-pad-right-10 {
  padding-right: 10px;
}

.padder-pad-right-20 {
  padding-right: 20px;
}

/***************************
      HackMarketingContentWrapper
  ****************************/

.hack-home-outdoor-guide {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media only screen and (width <= 1400px) {
    width: 1370px;
  }

  @media (width <= 1200px) {
    width: 1170px;
  }

  @media (width <= 992px) {
    width: 970px;
  }

  @media (width <= 768px) {
    width: 100%;
    max-width: 100%;
  }
}

.hack-marketing-content-wrapper {
  @media only screen and (width <= 1400px) {
    width: 1370px;
  }

  @media (width <= 1200px) {
    width: 1170px;
  }

  @media (width <= 992px) {
    width: 970px;
  }

  @media (width <= 768px) {
    width: 100%;
    max-width: 100%;
  }
}

.hack-marketing-content-wrapper [class='container-fluid'] {
  padding-right: 10px;
  padding-left: 10px;
  margin-right: 0;
  margin-left: 0;
}

.hack-marketing-content-wrapper [class*='simple-container'] {
  padding-bottom: 0;
}

.hack-marketing-content-wrapper [class*='col-'] {
  padding-right: 5px;
  padding-left: 5px;
}

.hack-marketing-content-wrapper .card-container {
  margin-bottom: 10px;
}

.hack-marketing-content-wrapper .home-marketing-spot {
  height: 100%;
  margin-right: 0;
  margin-left: 0;
  cursor: default;

  @media @xs {
    margin-top: 0;
  }
}

.hack-marketing-content-wrapper
  .card-main-content--no-backcontent
  .home-marketing-spot {
  cursor: pointer;
}

.hack-marketing-content-wrapper
  .card-main-content--no-backcontent
  .home-marketing-spot-image-only {
  cursor: default;
}

.hack-marketing-content-wrapper .home-marketing-spot--opacity {
  height: 100%;
  background: linear-gradient(
    180deg,
    rgb(0 0 0 / 0%) 0%,
    rgb(0 0 0 / 70%) 100%
  );
}

.section-header__action .icon-action {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.hack-marketing-content-wrapper .icon-action i {
  font-size: 1em;
}

.hack-marketing-content-wrapper .home-marketing-spot .hoverable-text {
  font-size: 16px;
}

/***************************
      SimpleImageCard
  ****************************/
@simpleImageCardImgWdHtMDLG: 104px;
@simpleImageCardImgWdHtXSSM: 80px;
@simpleImageCardImgMidMDLG: (@simpleImageCardImgWdHtMDLG / 2);
@simpleImageCardImgMidXSSM: (@simpleImageCardImgWdHtXSSM / 2);

.simple-image-card__container:hover {
  @media @mdlg {
    border-left: 3px solid @orange;
  }
}

.simple-image-card__container {
  position: relative;
  top: 50%;
  display: table;
  width: 100%;
  padding-left: 10px;
  border-left: 3px solid @white;

  // margin-left:10px;
  .transition_ease();

  @media @xssm {
    margin-top: -@simpleImageCardImgMidXSSM;
  }

  @media @mdlg {
    margin-top: -@simpleImageCardImgMidMDLG;
  }
}

.simple-image-card__image {
  @media @xssm {
    width: @simpleImageCardImgWdHtXSSM;
    height: @simpleImageCardImgWdHtXSSM;
  }

  @media @mdlg {
    width: @simpleImageCardImgWdHtMDLG;
    height: @simpleImageCardImgWdHtMDLG;
  }

  cursor: pointer;
  background-image: url('../../../../../web/owp-webclient/assets/images/ra/card_default.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border: 1px solid @border;
}

.simple-image-card__detail {
  position: relative;
  padding: 0 10px;
  text-align: left;
  vertical-align: baseline;

  @media @xssm {
    padding-left: 15px;
  }

  @media @mdlg {
    padding-left: 25px;
  }
}

.simple-image-card__image-wrapper {
  @media @xssm {
    width: @simpleImageCardImgWdHtXSSM;
  }

  @media @mdlg {
    width: @simpleImageCardImgWdHtMDLG;
  }
}

.simple-image-card__image-wrapper,
.simple-image-card__detail {
  display: table-cell;
}

.simple-image-card__title {
  @media @xssm {
    font-size: @font-size-h5__mobile;
    line-height: 13px;
  }

  @media @mdlg {
    font-size: @font-size-h5;
    line-height: 16px;
  }

  font-family: FigtreeBold, sans-serif;
  font-size: @font-size-h5;
  font-weight: 700;
  color: @blue_dark;
  cursor: pointer;
}

.simple-image-card__title:hover {
  color: @orange;
}

.simple-description-card__image {
  @media @xssm {
    height: 200px;
  }

  @media @mdlg {
    height: 300px;
  }

  cursor: pointer;
  background-image: url('../../../../../web/owp-webclient/assets/images/ra/card_default.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border: 1px solid @border;
}

.dropdown-card__row {
  @media @xs {
    display: block;
  }

  @media @smmdlg {
    display: flex;
  }

  margin-top: -10px;
  background-color: white;
}

.dropdown-card__row .section-height {
  height: auto;
}

.dropdown-card__image {
  @media @xs {
    display: block;
  }

  @media @smmdlg {
    display: flex !important;
  }

  align-items: center;
  justify-content: center;
  height: 100%;
  margin-left: 10px;
}

.dropdown-card__image img {
  width: 100%;
  max-width: 100%;
}
