@import '../../../../less/external/variables.less';

/********************************
--------- NEW RA Spot2Nite ---------
*********************************/

.s2nicon {
  width: 45px;
  height: 45px;
}

.facility-name2 {
  padding: 15px 0 5px 5px;
  margin: 0;
  font-size: 2em;
  font-weight: 700;
  color: #333;
  text-align: left;
}

.park-features-container {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;
  padding: 5px 0 10px 5px;
}

.park-feature {
  display: inline-table;
  width: 120px;
  min-height: 100px;
  padding: 20px 5px;
  margin: 4px;
  font-size: 1.2em;
  text-align: center;
  border: 1px solid #606060;
  border-radius: 3px;
}

.park-feature-text {
  font-size: small;
  color: black;
}

.park-feature img {
  padding: 5px;
  font-size: 2em;
}

.park-feature h4 {
  margin: 0;
}

h1 {
  margin-bottom: 20px;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: calc(100% - 10px);
}

.tag {
  padding: 4px 10px;
  margin: 4px 3px;
  color: black;
  text-align: center;
  background-color: #f2f2f2;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.location-card {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: start;
  width: 100%;
  padding: 20px;
  text-align: left;
  background: none;
  box-shadow: none;
}

.location-card img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.location-card h2 {
  width: 100%;
  margin-top: 10px;
  font-size: 1.5em;
}

.location-card h3 {
  width: 100%;
  margin-top: 10px;
  font-size: 1em;
}

.location-card p {
  margin: 5px 0;
  font-size: 0.8em;
  color: #666;
}

.facility-layout {
  margin-top: 2em;
}

.facility-layout h1 {
  margin-bottom: 20px;
}

.chub-overview {
  margin-bottom: 30px;

  &__search {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid @grey_field;
  }

  &__spots {
    margin-top: 20px;
  }

  &__section {
    margin-top: 30px;
  }

  &__section-description {
    white-space: pre-line;
  }

  &__section-heading {
    margin-bottom: 0.7em;
  }
}
