/*********************************************************************************************
  Purpose:
  --------
  Contains basic styles for all theme/project specific elements.

  Notes:
  ------
  Document all elements with comments.
  Component styles should never be part of this - only general styles.
**********************************************************************************************/

/**********************************
              MIXINS
 Initially moved from mixins.less
***********************************/
.graphic-icon {
  background-image: url('../../../../web/owp-webclient/assets/images/ra/icon-sprite.png');
  background-repeat: no-repeat;
}

.cart-icon {
  .graphic-icon();

  position: absolute;
  display: inline-block;
  width: 27px;
  height: 22px;
  background-position: -50px -50px;
}

.cart-icon--reversed {
  .graphic-icon();

  width: 27px;
  height: 22px;
  background-position: -50px -100px;
}

.icon-badge {
  position: absolute;
  top: -5px;
  right: -12px;
  display: inline-block;
  min-width: 10px;
  padding: 3px 5px;
  font-size: 10px;
  font-weight: normal;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  background-color: #ed6e17;
  border-radius: 10px;
}

.narrow-screen {
  padding-top: 20px;
  margin-top: 30px;
  margin-bottom: 30px;

  @media @mdlg {
    width: 768px;
    padding-top: 130px;
    margin: 30px auto;
  }
}

.narrow-screen-with-search {
  margin-top: 70px;

  @media @mdlg {
    margin: 30px auto;
  }
}

/*************************************
            TYPOGRAPHY
 Initially moved from typography.less
**************************************/
._section-title() {
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: Figtree, sans-serif;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.section-title {
  ._section-title();
}

.amenity-section-title {
  padding-top: 30px;
  font-family: FigtreeBold, sans-serif;
  font-size: 14px;
  font-weight: 700;

  @media @xssm {
    padding-top: 0;
    padding-bottom: 0;
  }

  @media @mdlg {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.section-title--reversed {
  ._section-title();

  color: @white;
}

.lead-copy p {
  font-family: Figtree, sans-serif;
  font-size: 14px;
}

.info-bar__info-block .hack-h-tag-h5 {
  font-size: @font-size-small;
}

/**********************
     TEXT MIXINS
***********************/
.form-title() {
  height: 45px;
  padding: 14px;
  font-family: FigtreeBold, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: @white;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 3px;
  background-color: @grey;
}

.article-title() {
  font-family: FigtreeBold, sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: @blue_dark;
  letter-spacing: 3px;
}

.footer-title() {
  font-family: FigtreeBold, sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: @blue_dark;
}

.product-content-title() {
  font-family: FigtreeBold, sans-serif;
  font-size: 15px;
  font-weight: 700;
  color: @blue_dark;
}

.product-content-title-mobile() {
  font-family: FigtreeBold, sans-serif;
  font-size: @font-size-h5__mobile;
  font-weight: 700;
  color: @blue_dark;
}

.extra-large {
  font-family: FigtreeLight, sans-serif;
  font-size: @font-size-extra-large;
}

.info-title {
  margin-top: 10px;
  margin-bottom: 10px;
  color: @grey;
  text-transform: none;
  letter-spacing: 0;
}

.product-search-form .control-label,
.product-search-form .group-label,
.facility-search-form .control-label,
.facility-search-form .group-label {
  font-family: FigtreeBold, sans-serif;
  font-weight: 700;
  color: @blue_dark;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.product-search-form .checkbox label .facility-search-form .checkbox label {
  text-transform: none;
  letter-spacing: 0;
}

.action-suggestion {
  font-size: 13px;
  line-height: 18px;
  color: @grey;
}

.emphasis {
  color: @link-color;
}

/**********************
       LANGUAGE
***********************/

.language-toggle {
  margin-right: 40px;
}

/**********************
    FACILITY HEADER
***********************/

.facility-layout {
  @media @xssm {
    margin-top: 90px;
  }

  &__lob-subheading,
  &__lob-subfooter {
    margin-bottom: 20px;
    line-height: 1.4;
  }
}

.facility-header--legacy {
  position: relative;
  height: 335px;
  margin-bottom: 20px;
  background-color: #313741;
  background-image: url('../../../../web/owp-webclient/assets/images/ra/no.photo.header.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  @media @xssm {
    height: 180px;
  }
}

.facility-header--legacy-carousel {
  position: relative;
  margin-bottom: 20px;

  @media @xssm {
    height: 180px;
  }

  @media @mdlg {
    height: 500px;
  }
}

.facility-header--legacy__carousel-image {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  @media @xssm {
    height: 180px;
  }

  @media @mdlg {
    height: 500px;
  }
}

.facility-title-affiliations {
  font-family: Figtree, sans-serif;
  font-size: 14px;
  line-height: 16px;
  color: @white;
  letter-spacing: 1px;
}

.facility-header--legacy__facility-title .facility-title-affiliations {
  margin-top: 4px;
}

.facility-header--legacy__facility-title .container {
  @media @mdlg {
    padding-left: 10px;
    margin: 0 auto;
    border-left: 3px solid @orange;
  }

  @media @xssm {
    width: auto;
  }
}

.facility-header--legacy__facility-title {
  position: absolute;
  bottom: 60px;
  left: 0;
  z-index: @z_below_overlay_above_base;
  margin-bottom: 0;
  font-family: FigtreeBold, sans-serif;
  font-size: 48px;
  font-weight: 700;
  color: @white;

  @media @mdlg {
    bottom: 0;
    width: 100%;
    height: 120px;
    line-height: 44px;
  }

  @media @xssm {
    position: relative;
    bottom: 0;
    left: 0;
    padding-left: 0;
    margin-bottom: 10px;
    font-size: @font-size-h1;
    color: @blue_dark;
    border-left: 0;
  }
}

.rating {
  color: @orange;
}

.facility-header--legacy__facility-title .rating {
  font-family: Figtree, sans-serif;
  font-size: 14px;
  color: @white;
}

.facility-header--legacy__hero-transparency-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: @z_video_overlay;
  width: 100%;
  height: 140px;
}

.facility-layout .simple-selector {
  @media @xssm {
    display: none;
  }
}

.facility-overview-details {
  @media @mdlg {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  @media @xssm {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.carousel-inner::after {
  position: absolute;
  bottom: 0;
  z-index: @z_below_overlay_base;
  display: block;
  width: 100%;
  content: ' ';
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgb(0 0 0 / 0%)),
    color-stop(100%, rgb(0 0 0 / 40%))
  );
  background: linear-gradient(
    to top,
    rgb(0 0 0 / 0%) 0%,
    rgb(0 0 0 / 40%) 100%
  );
  background: linear-gradient(
    to bottom,
    rgb(0 0 0 / 0%) 0%,
    rgb(0 0 0 / 40%) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#33000000',GradientType=0 );

  @media @mdlg {
    height: 140px;
  }

  @media @xssm {
    height: 50px;
  }
}

.carousel-indicators {
  z-index: @z_below_overlay_above_base_level_2;
}

/**********************
        FOOTER
***********************/
.footer {
  color: @white;
  background-color: @blue_dark;

  @media @xssm {
    padding-bottom: 80px;
  }

  @media @mdlg {
    padding-bottom: 40px;
  }
}

.footer__brand {
  height: 100px;
  background-image: url('../../../../web/owp-webclient/assets/images/ra/active-logo.png');
  background-repeat: no-repeat;
  background-position: center center;
}

.footer__links {
  margin-top: 40px;
}

.footer__links ul,
.footer__base-links ul,
.footer__links li,
.footer__base-links li {
  margin-left: 0;
  list-style: none;
}

.footer__title {
  padding-bottom: 10px;
  margin-bottom: 10px;
  color: @white;
  border-bottom: 1px solid @border;
}

.footer__feature-title {
  .footer-title();
}

.footer__base-links ul {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid @border;
}

.footer__base-links ul li,
.footer__links ul li {
  line-height: 24px;
}

.footer__links ul li a,
.footer__base-links ul li a,
.footer__links ul li a:active,
.footer__base-links ul li a:active,
.footer__links ul li a:visited,
.footer__base-links ul li a:visited {
  color: @white;
}

.footer__base-links ul li a:hover,
.footer__base-links ul li a:focus,
.footer__links ul li a:hover,
.footer__links ul li a:focus {
  color: @orange;
}

.footer__base-links ul li {
  float: left;
  padding-right: 10px;
}

.footer__copyright {
  margin-top: 10px;
}

.footer__traceId {
  display: none;
}

.footer__note {
  font-style: italic;
}

.footer__links i {
  width: 25px;
  margin-right: 5px;
  font-size: 20px;
}

.footer__mobile-apps-links {
  margin-top: 40px;
}

/**********************
    SOCIAL SHARES
***********************/
.circle {
  width: 30px;
  height: 30px;
  padding-top: 7px;
  padding-left: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 50%;
  transition: background-color 0.3s ease 0s;
}

.circle i {
  font-size: 16px;
  color: #fff;
}

.circle.googleplus {
  padding-left: 8px;
  background-color: @googleplus;
}

.circle.googleplus:hover {
  background-color: @googleplus-hover;
}

.circle.facebook {
  padding-left: 11px;
  background-color: @facebook;
}

.circle.facebook:hover {
  background-color: @facebook-hover;
}

.circle.twitter {
  padding-left: 8px;
  background-color: @twitter;
}

.circle.twitter:hover {
  padding-left: 8px;
  background-color: @twitter-hover;
}

.circle.pinterest {
  padding-left: 8px;
  background-color: @pinterest;
}

.circle.pinterest:hover {
  padding-left: 8px;
  background-color: @pinterest-hover;
}

.social-links {
  position: absolute;
  left: -50px;
  width: 30px;
  height: 100px;
}

/**********************
       INFO BAR
***********************/
.info-bar {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: @gutter;
  margin-bottom: @gutter;
  border-top: 1px solid @grey_light;
  border-bottom: 1px solid @grey_light;
}

.facility-geo-map {
  padding-top: 10px;
  margin-bottom: @gutter;
  clear: both;
}

/***********************
      BREADCRUMBS
************************/
.product-details-container .breadcrumb {
  margin-left: 0;
}

.product-details-container {
  @media @xssm {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.product-details-container .facility-header--legacy__facility-title .container {
  @media @xssm {
    padding-right: 0;
    padding-left: 0;
  }
}

/***********************
     FLAG ICONS
************************/
.language-flags {
  display: inline-block;
  width: 80px;

  @media @xs {
    display: none;
  }
}

.flag {
  float: left;
  width: 16px;
  height: 11px;
  margin-right: 10px;
  background-image: url('../../../../web/owp-webclient/assets/images/ra/flags.png');
  background-repeat: no-repeat;
}

.flag--es-MX {
  background-position: 0 0;
}

.flag--en-US {
  background-position: -24px 0;
}

.expand-label--open,
.expand-label--close {
  color: @link-color;
  cursor: pointer;
}

/***********************
      DECORATORS
************************/
.icon-decorator {
  .icon-badge();
}

.icon-decorator--info {
  .icon-badge();

  background-color: @orange;
}

.icon-decorator--warning {
  .icon-badge();

  background-color: @status-warning;
}

.icon-decorator--error {
  .icon-badge();

  background-color: @status-error;
}

/***********************
         ERRORS
************************/
.container--errors .hack-h-tag-h1 {
  .extra-large();
}

.container--errors .hack-h-tag-h4 {
  .info-title();
}

.container--errors {
  .narrow-screen();
}

.with-search {
  .container--errors {
    .narrow-screen-with-search();
  }
}

.error-guidance ul {
  margin-top: 20px;
  margin-left: 20px;
}

.error-links {
  font-family: FigtreeBold, sans-serif;
  font-size: 22px;
  font-weight: 600;
}

/***********************
      SUGGESTIONS
************************/
.container--suggestions {
  padding-top: 20px;
  padding-bottom: 20px;
}

.suggestions-search-bar-sync {
  padding-left: 160px;

  @media @xs {
    padding-left: 10px;
  }
}

.container--suggestions .hack-h-tag-h1 {
  .extra-large();
}

.container--suggestions .hack-h-tag-h4 {
  .info-title();
}

/***********************
      MY ACCOUNT
************************/
.container--account {
  .narrow-screen();
}

.container--account .hack-h-tag-h1 {
  .extra-large();
}

.container--account .hack-h-tag-h4 {
  .info-title();

  font-family: FigtreeLight, sans-serif;
}

.container--booking-form {
  .narrow-screen();
}

/***************************************
            MOBILE_VIEWS
 Initially moved from mobile-views.less
****************************************/
.xssm_filter_view .xssm-mobile_filter_view,
.xssm_filter_view .xssm-mobile_map_view,
.xssm_map_view .xssm-mobile_list_view,
.xssm_map_view .xssm-mobile_filter_view,
.xssm_list_view .xssm-mobile_filter_view,
.xssm_list_view .xssm-mobile_map_view {
  @media @xssm {
    display: none;
  }
}

.xssm_filter_view .xssm-mobile_filter_view,
.xssm_map_view .xssm-mobile_map_view,
.xssm_list_view .xssm-mobile_list_view {
  @media @xssm {
    display: block;
    width: 100%;
  }
}

.search-navigation-xssm {
  padding-top: 10px;
  text-align: right;

  @media @mdlg {
    display: none;
  }
}

.search-navigation-full-width {
  width: 100%;
}

.search-navigation-xssm .icon-action {
  display: inline-block;
}

/*************************************
             RESPONSIVE
 Initially moved from responsive.less
**************************************/
.facility-layout-flexbox {
  display: flex;

  @media @xssm {
    display: flex;
    flex-direction: column-reverse;
  }
}

.facility-layout-full-width {
  width: 100%;
}

.facility-search-container {
  @media @xssm {
    min-width: 100%;
    padding-right: 0;
    padding-left: 0;
    margin-right: 0;
    margin-left: 0;
  }
}

.facility-content-container {
  @media @xssm {
    min-width: 100%;
  }

  @media @mdlg {
    min-height: 800px;
  }
}

.facility-search-weather {
  @media @xssm {
    clear: both;
  }
}

.action-buttom__xssm-fixed {
  @media @xssm {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: @z_below_overlay_base;
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
}

.xssm-full-bleed-row,
.xssm-full-bleed {
  @media @xssm {
    padding: 0;
    margin: 0;
  }
}

/***********************
      STATUS MESSAGE
************************/
.statusmessage-inline-wrapper {
  display: table;

  > * {
    display: table-cell;
    line-height: 1.5;
  }

  > :first-child {
    float: left;
    margin-right: 10px;
  }

  > :last-child {
    vertical-align: middle;
  }
}
