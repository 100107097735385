/***********************
   SEARCH RESULT CARDS
************************/
.facility-summary-card__container,
.facility-summary-card__container1 {
  position: relative;
  display: flex;
  justify-content: flex-start;
  width: 95%;
  margin-left: 10px;
  .transition_ease();

  @media @mdlg {
    padding-left: 10px;
    border-left: 3px solid @white;
  }

  &:hover {
    @media @mdlg {
      border-left: 3px solid @orange;
    }
  }
}

.facility-summary-base {
  border-bottom: 1px solid @border;
}

.facility-summary-card {
  &:extend(.facility-summary-base);
  .transition_ease();

  @media @mdlg {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  @media @xssm {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.facility-summary-card-simple {
  &:extend(.facility-summary-card);

  border-bottom: none;

  @media @mdlg {
    padding-top: 0;
    padding-bottom: 0;
  }

  @media @xssm {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.facility-summary-card.facility-summary-card--active:hover {
  /* background-color:rgba(209,237,248,.6); */
}

.facility-summary-card--selected {
  /* background-color:rgba(209,237,248,.6); */
}

.facility-summary-card--selected .facility-summary-card__container,
.facility-summary-card--selected .facility-summary-card__container1 {
  @media @mdlg {
    border-left: 3px solid @orange;
  }
}

.facility-details__content,
.facility-summary-card__detail {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.facility-summary-card__detail {
  justify-content: space-between;
}

.facility-summary-card__image-wrapper {
  @media @xssm {
    width: 80px;
  }

  @media @mdlg {
    width: 104px;
  }
}

.facility-summary-card__image {
  @media @xssm {
    width: 80px;
    height: 80px;
  }

  @media @mdlg {
    width: 104px;
    height: 104px;
  }

  cursor: pointer;
  background-image: url('../../../../../web/owp-webclient/assets/images/ra/card_default.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border: 1px solid @border;
}

.facility-summary-card__image.nolink {
  cursor: default;
}

.facility-summary-card__detail {
  position: relative;
  padding: 0 10px;
  text-align: left;
  vertical-align: top;

  .client-logo {
    .max-height(50px);
  }

  @media @xssm {
    padding-left: 15px;
  }

  @media @mdlg {
    padding-left: 25px;
  }
}

.facility-summary-card__title {
  @media @xssm {
    font-size: @font-size-h5__mobile;
    line-height: 13px;
  }

  @media @mdlg {
    font-size: @font-size-h5;
    line-height: 16px;
  }

  font-size: @font-size-h5;
  color: @blue_dark;
  cursor: pointer;
}

.facility-summary-card__title:hover {
  color: @orange;
}

.facility-summary-card__title.nolink {
  cursor: default;
}

.facility-summary-card__title.nolink:hover {
  color: @blue_dark;
}

.search-container__panels {
  display: table;
  width: 100%;
}

.container__panels--left,
.container__panels--right {
  display: table-cell;
  vertical-align: top;
}

.container__panels__scroller {
  height: 100vh;
  overflow-y: auto;
}

.container__panels--left {
  width: 536px;
}

/*
.container__panels--left-header{
  font-size: 24px;
  font-weight: 700;
  color: @blue_dark;
} */

.container__panels--left-default-container {
  @media @xs {
    padding: 0 10px;
  }

  @media @smmdlg {
    padding: 0 20px;
  }
}

/***********************
   SEARCH RESULT CARDS
************************/

.container__panels__scroller .paging-control {
  float: none;
  padding: 10px;
  padding-right: @gutter;
  padding-left: @gutter;

  @media @xssm {
    margin: 0 auto;
    text-align: center;
  }
}

.container__panels__scroller .paging-control__directions {
  @media @xssm {
    text-align: center;
  }

  @media @mdlg {
    float: right;
  }
}

.paging-control__search-term {
  font-family: Figtree, sans-serif;
  color: @link-color;
}

/***********************
      QUICK BOOK
************************/
.quickbook-departuredate,
.quickbook-arrivaldate {
  float: left;
  width: 50%;
}

.quickbook.actionButton {
  @media @mdlg {
    margin-top: @gutter;
  }
}

/***********************
      SEARCH NEARBY
************************/
.search-nearby-section {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: @grey_light;
  border-bottom: 1px solid @border;
}

.search-nearby-text {
  font-family: Figtree, sans-serif;
  font-size: @font-size-h5;
  color: @grey;
}

.filterTab {
  display: inline-block;
  padding: 2px 10px;
  margin-right: 2px;
  margin-bottom: 2px;
  color: @orange;
  cursor: pointer;
  background-color: @white;
  border: 1px solid @orange;
  .border-radius(4px);
  .transition_ease();
}

.filterTab i {
  font-size: 16px;
}

.filterTab span {
  display: inline-block;
  margin-top: 0;
  font-size: 16px;
  color: @link-color;
}

.filterTab:hover {
  background-color: @orange_extra-light;
  .transition_ease();
}

.filterTab::after {
  display: block;
  float: right;
  width: 5px;
  height: 10px;
  padding-top: 5px;
  padding-bottom: 4px;
  margin-left: 6px;
  font-family: FontAwesome;
  font-size: 14px;
  line-height: 12px;
  color: @orange;
  content: '\f00d';
  opacity: 0.5;
}

.filterTab:hover::after {
  color: @grey;
  content: '\f00d';
  opacity: 1;
}

/***********************
        FILTERS
************************/

.search-filters.searchbar__button {
  .transition_ease();
}

.search-filters.searchbar__button.active {
  opacity: 1;
}

.search-filters.searchbar__button.inactive {
  opacity: 0;
}

.search-filter__contents {
  @media @mdlg {
    padding: 20px;
    margin-top: 50px;
  }

  @media @xssm {
    padding: 10px;
  }
}

.search-filter__contents--hidden-filter {
  @media @mdlg {
    padding: 10px 20px;
  }

  @media @xssm {
    padding: 10px;
  }
}

.search-filter__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-filter__header-date-picker {
  flex-basis: 50%;

  @media @xssm {
    flex-basis: 100%;
  }
}

.facility-search-form .search-filter__contents,
.product-search-form .product-search-form__contents {
  @media @mdlg {
    margin-top: 0;
  }

  @media @xssm {
    padding: 10px;
  }
}

.search-filter__contents .form__arrivaldate,
.search-filter__contents .form__departuredate {
  width: 100%;
}

.mobile-search-result-summary {
  margin-top: 10px;
}

.mobile-search-header {
  height: 40px;
  border-bottom: 1px solid @border;
}

.mobile-search-header .base-link {
  padding: 0 6px;
}

.mobile-search-header.hidden-lg.hidden-md {
  display: flex !important;

  @media (width >= 992px) {
    display: none !important;
  }
}

.facility-type-group {
  margin-top: @gutter;
}

.facility-summary-card__affiliation {
  font-family: FigtreeMedium, sans-serif;
  font-weight: 600;
  color: @grey;
}

.facility-summary-card__distance {
  font-family: Figtree, sans-serif;
  font-size: 11px;
  color: @green;
}

.home-navigation {
  display: inline-block;
  float: left;
  padding-left: 15px;
  cursor: pointer;
}

/***********************
   NEARBY FACILITY CARDS
************************/

// .nearby-facilities .section-main {
//   float: left;
//   clear: both;
// }

.nearby-facilities .card-container {
  @media @xssmmd {
    margin-bottom: 10px;
  }
}

.facility-nearby-card__container {
  position: relative;
  cursor: pointer;
  background-color: @white;
  border: 0.5px solid @grey_border;

  .ribbon {
    font-size: 1em;
    font-weight: 700;
    color: #fff;
  }

  .ribbon {
    --f: 0.5em;

    position: absolute;
    top: 0;
    left: 0;
    padding-inline: 1lh;
    padding-bottom: var(--f);
    clip-path: polygon(
      100% calc(100% - var(--f)),
      100% 100%,
      calc(100% - var(--f)) calc(100% - var(--f)),
      var(--f) calc(100% - var(--f)),
      0 100%,
      0 calc(100% - var(--f)),
      999px calc(100% - var(--f) - 999px),
      calc(100% - 999px) calc(100% - var(--f) - 999px)
    );
    line-height: 1.8;
    background-color: #f07818; /* the main color  */
    border-image: conic-gradient(#0008 0 0) 51% / var(--f);
    transform: translate(calc((cos(45deg) - 1) * 100%), -100%) rotate(-45deg);
    transform-origin: 100% 100%;
  }
}

.facility-nearby-card__image {
  height: 174px;
  background-image: url('../../../../../web/owp-webclient/assets/images/ra/ra-nearby-default.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.facility-nearby-card__name {
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.facility-nearby-card__container .facility-summary-card__ratings {
  margin-bottom: 5px;
}

.facility-nearby-card__details {
  height: 112px;
  padding: 10px;
}

.facility-nearby-card__clientlogo {
  float: right;
}
