/*********************************************************************************************
  Purpose:
  --------
  Contains imports to all other required less files for theme/project.

  Notes:
  ------
  Document all elements with comments.
  Do not add anything other than imports to other files here.
**********************************************************************************************/

/**********************
      EXTERNAL ONLY
***********************/
@import '../../external/main.less';

/**********************
       GENERAL
***********************/
@import './variables.less';
@import './base.less';
@import '../AAdvantage.less';

/**********************
      COMPONENTS
***********************/
@import './components/header.less';
@import './components/product-grids.less';
@import './components/forms.less';
@import './components/weather.less';
@import './components/footer-feature.less';
@import './components/marketing.less';
@import './components/cart.less';
@import './components/facility-search.less';
@import './components/seanson-feature.less';
@import './components/snapshots.less';
@import './components/product-detail.less';
@import './components/alerts-notes.less';
@import './components/facility-fees.less';
@import './components/facility-maps.less';
@import './components/search-suggestions.less';
@import './components/cta.less';
@import './components/icons.less';
@import './components/tabs.less';
@import './components/search.less';
@import './components/svg-maps.less';
@import './components/listcampground.less';
@import './components/favorites.less';
@import './components/home.less';
@import './components/containers.less';
@import './components/my-account.less';
@import './components/nss.less';
@import './components/hack-h-tag.less';
@import './components/server-data.less';
@import './components/destination-summary-card.less';
@import './components/components-multi.less';
@import './components/sensible-weather.less';
@import './components/addon-offer.less';
@import './components/facility-header.less';
@import './components/lightbox.less';
@import '../../../src/components/shared/ImageLoader/ImageLoader.less';
@import '../../../src/components/facility/camperHubOverview/ChubNumericInput/ChubNumericInput.less';
@import '../../../src/components/facility/camperHubOverview/ChubTelInput/ChubTelInput.less';
@import '../../../src/components/facility/camperHubOverview/ChubDateRange/ChubDateRange.less';
@import '../../../src/components/facility/camperHubOverview/ChubSpotGallery/ChubSpotGallery.less';
@import '../../../src/components/facility/camperHubOverview/ChubSpotItem/ChubSpotItem.less';
@import '../../../src/components/facility/camperHubOverview/ChubSpotsList/ChubSpotsList.less';
@import '../../../src/components/facility/camperHubOverview/ChubSpotsSearhForm/ChubSpotsSearchForm.less';
@import '../../../src/components/facility/camperHubOverview/ChubConfirmationData/ChubConfirmationData.less';
@import '../../../src/components/facility/camperHubOverview/ChubConfirmationDetails/ChubConfirmationDetails.less';
@import '../../../src/components/facility/camperHubOverview/ChubCheckoutSummary/ChubCheckoutSummary.less';
@import '../../../src/components/facility/camperHubOverview/ChubCheckoutForm/ChubCheckoutForm.less';
@import '../../../src/components/facility/camperHubOverview/ChubCheckout/ChubCheckout.less';
@import '../../../src/components/facility/camperHubOverview/ChubCheckoutPaymentData/ChubCheckoutPaymentData.less';
@import '../../../src/components/facility/camperHubOverview/camperhubOverviewContainer.less';
@import '../../../src/components/sharedInternal/signin/signin.less';

/**********************
   BOOTSRAP OVERRIDES
***********************/

@import './bootstrap.less';

/*************************************
   PLUG-IN OVERRIDES
   overrides for 3rd party plug-ins
*************************************/
@import './plug-in-overrides.less';
@import './main-overrides.less';

/**********************
   HACK OVERRIDES
***********************/
@import './hack.less';

/* TODO - fix build and put in component file */
// .sw-widget {
//    background: rgb(235, 238, 242);
//    border: 1px solid @grey_border;
//    font-family: 'Figtree', serif;
//    line-height: 20px;
//  }

//  .sw-widget-content {
//    background: url(../../../../web/owp-webclient/assets/images/ra/sensible-weather/sensible-background.png), rgb(235, 238, 242);
//    background-size: 25%;
//    background-position: 100% 0;
//    background-repeat: no-repeat;
//    color: #0b1419;
//    font-size: .9em;
//    padding: 20px 40px 10px;
//  }

//  .sw-content-wrapper {
//   font-size: 14px;
//  }

//  .sw-widget-content h1,
//  .sw-widget-content p {
//    width: 75%;
//  }

//  .sw-widget-content h1 {
//    color: #0c4261;
//    font-size: 2em;
//    font-weight: 400;
//    margin: 20px 0;
//  }

//  .sw-widget-content__disclaimer {
//    padding: 15px 0 20px;
//  }

//  .sw-widget-content__details {
//    margin: 10px 0 0;
//    font-size: 12px;
//  }

//  .sw-widget-content__action {
//    margin: 0;
//  }

//  .sw-widget-content__action a {
//    color: #0c4261;
//    font-family: sans-serif;
//  }

//  .sw-widget-footer {
//    background: #fff;
//    display: flex;
//    justify-content: space-between;
//    font-size: 24px;
//    padding: 20px 40px 40px;
//  }

//  .sw-widget-footer__links {
//    line-height: 1.5em;
//  }

//  .sw-widget-footer__links a {
//    color: #0c4261;
//    display: block;
//    font-size: .65em;
//  }

//  .sw-widget-footer__logo {
//    text-align: right;
//  }

//  .sw-widget-footer__logo img {
//    width: 75%;
//  }

#EnigmaVault__container {
  width: 100%;
  min-width: 316px;
  min-height: 460px;
  padding: 0;
}

.EnigmaVault__message {
  width: 100%;
  padding: 50px;
  text-align: center;
}

.fs-sticky-footer {
  background: hsl(0deg 0% 100% / 80%) !important;
}

button.base-link {
  padding: 0;
  color: @white;
  cursor: pointer;
  background: none;
  border: none;
  box-shadow: none;
}

button.base-link:hover {
  color: @orange;
}
