@import '../../../../../less/external/variables.less';

.chub-checkout-payment {
  &__summary {
    margin-bottom: 20px;
  }

  &__btn {
    font-size: 1.2em !important;

    @media @xssm {
      height: 36px;
    }

    @media @mdlg {
      height: 46px;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  &__btn--goback {
    background: #f0f0f0;
  }

  &__heading-btn {
    display: block;
    width: 100%;
    padding: 10px;
    text-align: left;
    background: transparent;
    border: none;
  }

  &__heading {
    background-color: @grey_light;
    border: 1px solid @grey_field;
  }

  &__footer-btns {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-top: 20px;
  }

  &__body {
    border: 1px solid @grey_field;
    border-top: none;
  }

  &__payment {
    align-items: center;
    justify-content: center;
    height: 320px;
    margin: 0 auto;
  }
}
