@keyframes header-fade-in {
  from {
    bottom: -45px;
    opacity: 0;
  }
  
  to {
    bottom: 45px;
    opacity: 1;
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.facility-header {
  position: relative;
  margin: 0 0 20px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  &--skeleton {
    .facility-slider__image,
    .masonry__highlight,
    .masonry-grid__item {
      position: relative;
      background: #dddbdd;

      &::before {
        display: none !important;
        cursor: default !important;
      }

      &::after {
        position: absolute;
        inset: 0;
        content: '';
        background-image: linear-gradient(
          90deg,
          rgba(#fff, 0) 0,
          rgba(#fff, 0.2) 20%,
          rgba(#fff, 0.5) 60%,
          rgba(#fff, 0)
        );
        transform: translateX(-100%);
        animation: shimmer 5s infinite;
      }
    }
  }
}

.facility-slider {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  height: 180px;
  overflow: hidden;
  user-select: none;
  background: #313741;
  background-image: url('../../../../../web/owp-webclient/assets/images/ra/no.photo.header.jpg');

  @media @sm {
    height: 335px;
  }

  @media @mdlg {
    display: none;
  }

  &__image {
    position: relative;
    display: block;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform .5s ease-in-out;
  }

  .slider-pagination {
    position: absolute;
    bottom: 5px;
    left: 50%;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    transform: translateX(-50%);

    &__indicator {
      display: block;
      width: 6px;
      height: 6px;
      margin: 0 5px;
      cursor: pointer;
      background: rgb(255 255 255 / 80%);
      border-radius: 50%;
      box-shadow: 0 3px 5px rgb(0 0 0 / 60%);
      transition: all .15s ease-out;
      
      &[data-active] {
        width: 8px;
        height: 8px;
        background: @orange;
      }
    }
  }
}

.masonry {
  display: flex;
  flex-shrink: 0;
  flex-wrap: nowrap;
  width: 100%;
  height: 400px;
  margin: 90px 0 0;

  @media @xssm {
    display: none;
  }

  @media @md {
    height: 300px;
  }

  &__highlight {
    flex-shrink: 0;
    width: 67%;
    height: 100%;
    margin: 0 0 45px;

    &--full {
      width: 100%;
    }

    // .facility-header__facility-title {
    //   position: absolute;
    //   inset: auto auto 15px;
    //   z-index: 2;
    //   font-size: 2em;
    //   color: #fff;
    //   text-shadow: 0 3px 5px rgb(0 0 0 / 60%);
    //   transition: all .35s ease-out;
    //   animation: header-fade-in .35s ease-out;
    // }

    .facility-title-affiliations {
      font-family: Figtree, sans-serif;
      font-size: 14px;
      line-height: 16px;
      color: @white;
      letter-spacing: 1px;
    }
    
    .facility-header__facility-title .facility-title-affiliations {
      margin-top: 4px;
    }
    
    .facility-header__facility-title .container {
      @media @mdlg {
        padding-left: 10px;
        margin: 0 auto;
        border-left: 3px solid @orange;
      }
    
      @media @xssm {
        width: auto;
      }
    }
    
    .facility-header__facility-title {
      position: absolute;
      bottom: 45px;
      left: 45px;
      z-index: @z_below_overlay_above_base;
      margin-bottom: 0;
      font-family: FigtreeBold, sans-serif;
      font-size: 48px;
  font-weight: 700;
      color: @white;
      transition: all .35s ease-out;
      animation: header-fade-in .35s ease-out;
    }
    
    .rating {
      color: @orange;
    }
    
    .facility-header__facility-title .rating {
      font-family: Figtree, sans-serif;
      font-size: 14px;
      color: @white;
    }
    
    .facility-header__hero-transparency-bottom {
      position: absolute;
      z-index: @z_video_overlay;
      width: 100%;
      height: 140px;
    }
    
  }

  &-grid {
    display: flex;
    flex-grow: 0;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    margin: 0;
    list-style: none;
    transform: translate(15.5px, -15px);

    &__item {
      width: calc(50% - 15px);
      height: calc(50% - 7.5px);
      margin: 15px 15px 0 0;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__highlight,
  &-grid__item {
    position: relative;
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background: #444;
    background-image: url('../../../../../web/owp-webclient/assets/images/ra/no.photo.header.jpg');
    border-radius: 6px;
    
    &:not(.masonry__highlight--empty) {
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 4;
        display: block;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        color: #fff;
        text-shadow: 0 3px 5px rgb(0 0 0 / 30%);
        visibility: hidden;
        content: 'Click to enlarge';
        background: fade(@orange, 15%);
        opacity: 0;
        transition: all .15s ease-out;
      }

      &:hover {
        cursor: pointer;
        
        &::before {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    img {
      max-width: 100%;
      object-fit: cover;
    }
  }
}