.img-loader {
  position: static;
  width: auto;
  height: auto;
  background: transparent;

  &__img {
    opacity: 1;
    transition: opacity 0.3s;
  }

  &--liquid &__img {
    display: block;
    width: 100%;
    height: 100%;
  }

  &.spinner &__img {
    opacity: 0;
  }

  &--empty {
    background-image: url('../../../../../web/owp-webclient/assets/images/ra/ra-spot-default.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
}
