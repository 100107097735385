/*********************************************************************************************
  Purpose:
  --------
  Contains styles for server generated html.

  Notes:
  ------
  Document all elements with comments.
  We don't have styles for h1-h6 in our
  stylesheet but there are server generated html that needs to be styled. These styles apply 
  only h1-h6 inside these classes.
  Do not add anything other than server related styles. 
**********************************************************************************************/
.server-htag-style() {
  margin: 10px 0;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.content-box .content,
.facility-alert .note,
.server-content {
  h1 {
    font-family: FigtreeBold, sans-serif;
    font-size: @font-size-h1;
  font-weight: 700;
    .server-htag-style();
  }

  h2 {
    font-family: Figtree, sans-serif;
    font-size: @font-size-h2;
    .server-htag-style();
  }

  h3 {
    font-family: Figtree, sans-serif;
    font-size: @font-size-h3;
    color: @blue_dark;
    .server-htag-style();
  }

  h4 {
    font-family: FigtreeBold, sans-serif;
    font-size: @font-size-h4;
  font-weight: 600;
    color: @blue_dark;
    .server-htag-style();
  }

  h5 {
    font-family: FigtreeBold, sans-serif;
    font-size: @font-size-h5;
  font-weight: 600;
    color: @blue_dark;
    .server-htag-style();
  }

  h6 {
    font-family: Figtree, sans-serif;
    font-size: @font-size-h6;
    .server-htag-style();

    letter-spacing: 1px;
  }
}
