.chub-confir {
  &__title-div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    border-bottom: 1px solid #ccc;
  }

  &__title {
    font-size: 36px;
    font-weight: 700;
  }

  &__print-info {
    position: relative;
    padding: 16px;
    margin-top: 16px;
    border: 1px solid #ccc;
    box-shadow: 0 4px 6px rgb(0 0 0 / 10%);

    .bold {
      font-weight: 700;
    }

    .icon-action {
      position: absolute;
      top: 16px;
      right: 16px;
    }
  }

  &__endButton {
    margin-top: 10px;
    font-size: 1.2em;
  }

  &__link {
    display: flex;
    align-items: end;
  }

  &__link-2 {
    margin: 0 5px;
  }

  &__message {
    margin: 10px;
  }

  &__message-to-link {
    display: flex;
    margin: 10px 0;
  }
}
