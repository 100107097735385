/**********************
         HERO
***********************/
.homehero {
  position: relative;
  background-color: @white;
  background-size: cover;

  @media @xs {
    height: 320px;
  }

  @media @sm {
    height: 400px;
  }

  @media @md {
    height: 560px;
  }

  @media @lg {
    height: 560px;
  }
}

.hero_message_width (@width; @gutter) {
  width: (@width - @gutter);
}

.hero_message_left_margin (@width; @gutter) {
  margin-left: -((@width - @gutter)/2);
}

.homehero__message {
  .hero_message_width(@searchbar-width-lg; @searchbar-gutter);
  .hero_message_left_margin(@searchbar-width-lg; @searchbar-gutter);

  position: absolute;
  top: 190px;
  left: 50%;
  z-index: @z_video_overlay;
  font-size: 16px;
  color: @white;
  // text-shadow: 1px 2px 0 rgba(0,0,0,0.3);
  text-align: center;

  @media @md {
    .hero_message_width(@searchbar-width-md; @searchbar-gutter);
    .hero_message_left_margin(@searchbar-width-md; @searchbar-gutter);

    left: 50%;
  }

  @media @xssm {
    left: 0;
    width: @searchbar-width-xssm;
    margin-left: 0;
    font-size: 14px;
  }
}

.homehero__message__main-line {
  font-family: Figtree, sans-serif;
  font-size: 46px;
  letter-spacing: 2px;

  @media @md {
    font-size: 38px;
  }

  @media @sm {
    font-size: 38px;
  }

  @media @xs {
    font-size: 24px;
  }
}

.homehero__message__tag-line-1 {
  margin-top: -8px;
  font-family: Georgia;
  font-size: 28px;

  @media @md {
    margin-top: 0;
    font-size: 24px;
  }

  @media @sm {
    margin-top: 0;
    font-size: 24px;
  }

  @media @xs {
    margin-top: 0;
    font-size: 18px;
  }
}

.homehero__search_options {
  position: absolute;
  top: 340px;
  z-index: @z_video;
  width: 100%;
}

.video-container {
  position: relative;
  width: 100%;
  height: 500px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.transparency-overlay-top {
  height: 55%;
  background: linear-gradient(0deg, rgb(0 0 0 / 0%) 0%, rgb(0 0 0 / 60%) 100%);
}

.video-player {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: @z_video;
  width: auto;
  min-width: 100%;
  height: auto;
  min-height: 100%;
  transform: translate(-50%, -50%);
}

/**********************
         MARKETING
***********************/
.home-marketing-spot {
  margin-right: -15px;
  margin-left: -15px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  @media @mdlg {
    height: 300px;
  }

  @media @sm {
    height: 300px;
  }

  @media @xs {
    height: 150px;
    margin-top: 10px;
    margin-right: -5px;
    margin-left: -5px;
  }
}

.home-marketing-spot--opacity {
  background: linear-gradient(
    180deg,
    rgb(0 0 0 / 0%) 0%,
    rgb(0 0 0 / 70%) 100%
  );

  @media @mdlg {
    height: 300px;
  }

  @media @sm {
    height: 300px;
  }

  @media @xs {
    height: 150px;
  }
}

.home-marketing-spot__description {
  position: absolute;
  bottom: 20px;
  padding-right: 25px;
  padding-left: 10px;
}

.home-marketing-spot:hover .hoverable-text,
.home-marketing-spot:focus .hoverable-text {
  border-left: 5px solid @orange;
}

.home-marketing-spot__info {
  position: absolute;
  bottom: 20px;
  padding-right: 10px;
  padding-left: 10px;
  margin-top: 20px;

  @media @xs {
    bottom: 15px;
  }
}

/**********************
    OUTDOOR GUIDE
***********************/
.home-outdoor-guide__container {
  padding: 0 35px 50px;
  margin-top: @form-group-bottom-margin;
  background-color: @white;
  box-shadow: 1px 1px 2px 0 rgb(0 0 0 / 30%);

  @media @xs {
    padding: 0;
    background: none;
    box-shadow: none;
  }
}

.home-outdoor-guide__header {
  padding-bottom: 11px;
  margin: 0 -35px 40px;
  background-color: @white;
  box-shadow: 1px 1px 2px 0 rgb(0 0 0 / 30%);

  @media @xs {
    margin: 0 0 @form-group-bottom-margin 0;
  }
}

.home-outdoor-guide__header__title {
  padding-top: 35px;
  margin-bottom: 35px;

  @media @xs {
    padding-top: @form-group-bottom-margin;
  }
}

.home-outdoor-guide__selector {
  padding-left: 35px;

  @media @xs {
    padding: 0 10px;
  }
}

.home-outdoor-guide__selector .simple-selector {
  margin-bottom: -11px;
  border: none;
}

.home-outdoor-guide__selector .simple-selector__items--margin {
  @media @xs {
    margin-right: 10px;
  }
}

.home-outdoor-guide__selector .simple-selector__item,
.home-outdoor-guide__selector .simple-selector__item--selected {
  font-size: 15px;
  letter-spacing: normal;
}

/**********************
        ARTICLES
***********************/
.home-article {
  cursor: pointer;
  background-color: @white;
  border: 0.5px solid @grey_border;
}

.home-article:hover .hoverable-text,
.home-article:focus .hoverable-text {
  border-left: 5px solid @orange;
}

.home-article__image {
  height: 200px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  @media @xs {
    height: 150px;
  }
}

.home-article__description {
  padding: 10px;

  @media @xssm {
    height: 80px;
  }

  @media @md {
    height: 80px;
  }

  @media @lg {
    height: 120px;
  }

  @media @xl {
    height: 100px;
  }
}

/**********************
  FEATURED ARTICLES
***********************/
.home-featured-articles {
  margin-top: 65px;
  background: @white;

  @media @sm {
    margin-top: 0;
  }

  @media @xs {
    padding: 15px;
    margin: 20px -15px 0;
  }
}

.home-featured-articles__title {
  padding-left: 15px;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  color: @orange;
  text-transform: uppercase;
  letter-spacing: 1px;

  @media @xs {
    padding-top: 20px;
  }
}

.home-featured-article__category {
  padding-left: 15px;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  color: @cerulean;
  text-transform: uppercase;
}

.home-featured-article__description {
  padding-right: 50px;

  @media @xs {
    padding-right: 20px;
  }
}
