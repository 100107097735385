.facility-bulletin__item {
  margin-bottom: 4px;
}

.facility-bulletin__item,
.info-message__item {
  padding: 10px;
  background-color: @bulletin;
  border: 1px solid @bulletin-border;
}

.info-message.v-space {
  margin-top: 10px;
  margin-bottom: 10px;
}

.alert-message {
  padding: 10px;
  color: @alert-color;
  background-color: @alert;
  border: 1px solid @alert-border;
}

.alert-message__item {
  margin-left: 30px;
}

.alert-message__ada-accessible::before {
  display: block;
  float: left;
  width: 20px;
  height: 30px;
  font-family: FontAwesome;
  font-size: 25px;
  color: @alert-color;
  content: '\f193';
}

.alert-message__ada-accessible {
  color: @alert-color;
}

.ada-alert__body {
  padding-top: 10px;
}

.facility-bulletin__item::before,
.info-message__item::before {
  display: block;
  float: left;
  width: 20px;
  height: 30px;
  font-family: FontAwesome;
  content: '\f05a';
}

.facility-bulletin__content,
.info-message__content {
  padding-left: 20px;
  word-wrap: break-word;
}

.facility-bulletin__title,
.info-message__title {
  padding-right: 5px;
  font-family: FigtreeBold, sans-serif;
  font-weight: 700;
  color: @blue_dark;
}

.facility-alert {
  padding-top: 0;
  padding-bottom: 20px;
}

.facility-alert .info-message__item {
  margin-bottom: 10px;
}

.facility-alert .note {
  margin-bottom: 10px;
}

.note p {
  color: @grey;
}

.note ul {
  margin-left: 15px;
}

.note {
  line-height: 160%;
}

.loop-alert__item {
  margin-top: @gutter;
  clear: both;
  line-height: 20px;
}

.note-alert-section-title {
  margin-top: @gutter;
  margin-bottom: @gutter;
  clear: both;
  font-family: FigtreeBold, sans-serif;
  font-weight: 700;
  color: @blue_dark;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.alert__item {
  margin-top: 10px;
  line-height: 18px;
}

.product-dtails__notes {
  margin-top: 10px;
}

.mobile-alerts .expandable-section__toggle {
  padding: 5px;
  padding-left: 10px;
  color: @white;
  background-color: @grey;
  border: 1px solid @bulletin-border;
}

.mobile-alerts .expand-label--open,
.mobile-alerts .expand-label--close {
  color: @white;
}

.mobile-alerts .expandable-section__toggle::before {
  display: block;
  float: left;
  width: 20px;
  font-family: FontAwesome;
  color: @white;
  content: '\f05a';
}
