@import '../../../../../less/external/variables.less';

.chub-date-range {
  .DateRangePicker {
    display: block;
  }

  .DateRangePickerInput {
    display: flex;
    align-items: center;
    overflow: hidden;
    border-color: @grey_field;
    border-radius: 2px;

    @media screen and (width >= 992px) {
      height: 44px;
    }
  }

  .DateRangePickerInput__calendar-icon {
    padding: 8px;
    margin: 0 0 0 5px;
  }

  .DateInput {
    flex-grow: 1;
    width: 0;
    line-height: 1;
    text-align: center;
  }

  .DateInput__display-text {
    padding: 6px 8px;
    text-overflow: ellipsis;
  }

  &__summary {
    margin-top: 0 !important;
  }

  &__tooltip[data-tooltip-text] {
    position: relative;
    cursor: pointer;

    &::after {
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 12px;
      height: 12px;
      margin-left: 6px;
      content: '';
      background-color: #fff;
      transition: 0.14s;
      transform: translate(-50%, 110%) scale(0) rotate(45deg);
      transform-origin: top;
    }

    &::before {
      position: absolute;
      bottom: 0;
      left: 50%;
      width: fit-content;
      height: fit-content;
      padding: 8px 12px;
      font-size: 14px;
      color: #222;
      text-align: center;
      content: attr(data-tooltip-text);
      background-color: #fff;
      border-radius: 6px;
      box-shadow:
        0 4px 14px 0 rgb(0 0 0 / 20%),
        0 0 0 1px rgb(0 0 0 / 5%);
      transition: 0.14s;
      transform: translate(-50%, 110%) scale(0);
      transform-origin: top;
    }
  }

  &__day--departure {
    color: #000;
    background: linear-gradient(
      to top left,
      rgb(0 0 0 / 0%) 0%,
      rgb(0 0 0 / 0%) calc(50% - 0.8px),
      rgb(0 0 0 / 50%) 50%,
      rgb(0 0 0 / 0%) calc(50% + 0.8px),
      rgb(0 0 0 / 0%) 100%
    );

    &:hover .chub-date-range__tooltip[data-tooltip-text]::after {
      display: block;
      transform: translate(-50%, -150%) scale(1) rotate(45deg);
    }

    &:hover .chub-date-range__tooltip[data-tooltip-text]::before {
      display: block;
      transform: translate(-50%, -55%) scale(1);
    }
  }
}
