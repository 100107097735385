.menu-items__container {
  display: table;
}

.menu-item__container {
  cursor: pointer;
}

.menu-item__container:hover .header-cart-summary__timer,
.menu-item__container .base-link:focus .header-cart-summary__timer {
  color: @orange;
}

.menu-item__container--vertical {
  display: table-cell;
  float: left;
  margin-left: 30px;
  border-bottom: 5px solid transparent;

  @media @sm {
    margin-left: 15px;
  }

  @media @xs {
    margin-left: 10px;
  }
}

.menu-item__container--vertical:hover {
  @media @smmdlg {
    border-bottom: 5px solid @orange;
  }
}

.menu-item__container--horizontal {
  width: 100%;
  margin-bottom: 20px;
}

.menu-item__icon {
  position: relative;
}

.menu-item__icon--vertical {
  margin: 0 auto;
}

.menu-item__icon--horizontal {
  margin: 0;
}

.menu-item__icon-container--vertical {
  text-align: center;
}

.menu-item__icon-container--horizontal {
  display: table-cell;
  width: 10%;
  min-width: 40px;
}

.menu-item__text-container--vertical {
  text-align: center;
}

.menu-item__text-container--vertical .menu-item__text {
  max-width: 65px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.menu-item__text-container--horizontal {
  display: table-cell;
  width: 90%;
  padding-left: 30px;
  vertical-align: bottom;
}

.menu-item__icon-badge {
  position: absolute;
  top: -5px;
  display: inline;
  padding: 3px 5px;
  font-size: 10px;
  font-weight: normal;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  background-color: #999;
  background-color: @orange;
  border-radius: 10px;
}

.menu-item__chevron {
  float: right;
  margin-left: 5px;
}

.menu-item__text {
  display: inline-block;
  font-size: 13px;
  color: @white;

  @media @xs {
    font-size: 10px;
  }
}

.menu-item__container:hover .menu-item__text,
.base-link:focus .menu-item__text {
  color: @orange;
}

.sub-menu-item__container {
  background-color: @white;
}

.menu-item__container--horizontal .sub-menu-item__options-container {
  margin-left: 15px;
}

.sub-menu-item__options {
  position: absolute;
  z-index: 1000;
  margin-top: 5px;
  font-size: 14px;
  background-color: #fff;
}

.sub-menu-item__options--left {
  margin-left: -15px;
}

.sub-menu-item__options--right {
  right: 10px;
  left: inherit;
}

.sub-menu-item__options--border {
  border: 1px solid @grey_border;
}

.sub-menu-item__options--box-shadow {
  .box-shadow(2px 2px 5px @blue_dark);
}

.sub-menu-item__options--no-overlay {
  position: relative;
  z-index: auto;
  margin-top: 0;
  margin-left: 0;
}

.sub-menu-item__options--fit-to-content {
  display: inline-block;
}

.sub-menu-item__options .sub-menu-item:last-child {
  border-bottom: none;
}

.main-menu-items .sub-menu-item__options {
  margin-top: 2px;
}

.sub-menu-item__container--closed .sub-menu-item__options {
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out 0s;
}

.sub-menu-item__container--open .sub-menu-item__options {
  visibility: visible;
  opacity: 1;
  transition: all 0.3s ease-in-out 0s;
}

.menu-item__container--horizontal .sub-menu-item__options {
  position: inherit;
  padding: 0;
  background: none;
}

.sub-menu-item {
  padding-left: 15px;
  margin-left: 0;
  list-style: none;
  border-bottom: 1px solid @grey_border;
  border-left: 5px solid transparent;
}

.sub-menu-item:hover,
.sub-menu-item--selected {
  background-color: @grey_extra-light;
  border-left: 5px solid @orange;
}

.sub-menu-item__text {
  font-size: 14px;
  line-height: 17px;
  color: @blue_dark;
  // padding: 10px 15px 10px 0px;
}

.sub-menu-item--selected .sub-menu-item__text {
  font-weight: 600;
}

.sub-menu-item__text .base-link {
  display: block;
  padding: 10px 15px 10px 0;
  color: @blue_dark;
}

.sub-menu-item__text .base-link:focus {
  padding-left: 15px;
  margin-left: -20px;
  background-color: @grey_extra-light;
  border-left: 5px solid @orange;
}

.sub-menu-item__text:hover .sub-menu-item__text:focus {
  color: @orange;
}

.sub-menu-item__options--right .sub-menu-item__text {
  text-align: right;
}

.main-header__burger
  .profile-menu-item
  .sub-menu-item__options--right
  .sub-menu-item__text {
  padding-right: 0;
  margin-left: -30px;
  text-align: left;
}

.menu-link__container {
  margin-bottom: 20px;
  cursor: pointer;
}

.menu-link__container div {
  display: inline;
}

.menu-link__container:hover .menu-link__text,
.base-link:focus .menu-link__text {
  color: @orange;
}

.menu-link__icon {
  margin-right: 5px;
}

.menu-link__text {
  font-size: 13px;
  line-height: 16px;
  color: @white;
  vertical-align: bottom;
}
