/*********************************************************************************************
  Purpose:
  --------
  Contains global overrides for react-select styles so that we can keep  to a minimum.

  Notes:
  ------
  Document all elements with comments.
**********************************************************************************************/

/**********************
   OVERRIDE REACT SELECT
***********************/
.custom-react-select {
  .select__control {
    min-height: 44px;
    border: 1px solid #a8a8a8;
    box-shadow: none;
  }

  .select__control--is-focused {
    box-shadow: none;
    border: 1px solid #80bdff;
  }

  .select__indicator {
    padding: 0 2px 0 12px;
    font-size: 16px;
    color: hsl(0, 0%, 50%);
  }
}
