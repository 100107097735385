/*********************************************************************************************
  Purpose:
  --------
  Contains common variables.

  Notes:
  ------
  Document all elements with comments.
  Do not add anything other than variable descriptions to this file.
**********************************************************************************************/

/**********************
     DEVICE SIZES
***********************/
@xs-min: 0;
@xs-max: 767px;

@sm-min: 768px;
@sm-max: 991px;

@md-min: 992px;
@md-max: 1199px;

@lg-min: 1200px;

@highdensity:
  ~'only screen and (-webkit-min-device-pixel-ratio: 1.5)',
  ~'only screen and (min--moz-device-pixel-ratio: 1.5)',
  ~'only screen and (-o-min-device-pixel-ratio: 3/2)',
  ~'only screen and (min-device-pixel-ratio: 1.5)';
@xs: ~'only screen and (max-width: 767px)';
@sm: ~'only screen and (min-width: 768px) and (max-width: 991px)';
@xssm: ~'only screen and (max-width: 991px)';
@md: ~'only screen and (min-width: 992px) and (max-width: 1199px)';
@xssmmd: ~'only screen and (max-width: 1199px)';
@mdlg: ~'only screen and (min-width: 992px)';
@lg: ~'only screen and (min-width: 1200px)';
@smmdlg: ~'only screen and (min-width: 768px)';
@xl: ~'only screen and (min-width: 1400px)';

/**********************
        LINKS
***********************/

@link-color: #0093d0;
@link-color-hover: darken(@link-color, 15%);
@link-reverse-color: #ffffff;
@link-reverse-color-hover: darken(@link-reverse-color, 5%);

/**********************
       COLOURS
***********************/

@white: #ffffff;
@mute-white: darken(@white, 20%);
@black: #000;

@orange: #ed6e17; /* bright orange */
@orange_light: lighten(@orange, 10%); /* bright orange lightened by 10% */
@orange_extra-light: lighten(@orange, 40%); /* bright orange lightened by 10% */
@orange_secondary: #f0ac4e;

@red: #da3a20;

@cerulean: #0093d0;
@cerulean_light: lighten(@cerulean, 20%); /* cerulean lightened by 10% */

@grey: #576166;
@grey-hover: darken(@grey, 20%);
@grey_light: #f2f2f2;
@grey_tab: #f0efeb;
@grey_field: #a8a8a8;
@grey_tab-light: darken(@grey_tab, 10%);
@grey_extra-light: #f5f5f5;
@grey_medium: #7d8c98;
@grey_hightlight: lighten(@grey_light, 10%);
@grey_border: #dfdfdf;
@grey_form: #929292;
@grey_disabled: #c4c4c4;

@blue_dark: #313741;
@blue_dark-hover: lighten(@blue_dark, 10%);
@blue_dark-background-ninety: rgba(31, 43, 52, 0.9);
@blue-dark-background-eighty: rgba(31, 43, 52, 0.8);
@blue_light: #0093d0;
@blue_light-hover: darken(@blue_light, 10%);
@blue_muted: #b2cff9;
@blue_extra-light: #d1edf8;
@blue_grey: rgba(49, 55, 65, 1);

@green: #4d5f43;
@green_emerald: #10a15e;
@green_cart_timer: #17c42d;
@green_light: #5a8c3e;
@bluish_green: #0fa15b;
@green_available: #58ae25;

@messagebar: #4dbde9;
@messagebar-color: #ffffff;
@yellow: #ffcd00;
@yellow-light: #ffffcc;

@twitter: #5fa9dd;
@googleplus: #b13d28;
@pinterest: #9f2227;
@facebook: #677cb1;
@twitter-hover: darken(@twitter, 10%);
@googleplus-hover: darken(@googleplus, 10%);
@pinterest-hover: darken(@pinterest, 10%);
@facebook-hover: darken(@facebook, 10%);

@border: #b3bdc1;

/**********************
   MARGINS/GUTTERS
***********************/

@right-page-margin: 15px;
@left-page-margin: @right-page-margin;

@gutter: 20px;
@gutter-mobile: 10px;
@form-group-bottom-margin: 20px;
@form-group-small-bottom-margin: 10px;

@section-main-bottom: 20px;
@section-main-top: 20px;
@section-sub-bottom: 10px;

/**********************
      FONT SIZES
***********************/
@font-size-h1: 20px;
@font-size-h2: 20px;
@font-size-h3: 17px;
@font-size-h4: 16px;
@font-size-h5: 15px;
@font-size-h5__mobile: 13px;
@font-size-h6: 13px;
@font-size-extra-small: 10px;
@font-size-small: 12px;
@font-size-body: 14px;
@font-size-large: 22px;
@font-size-extra-large: 50px;

@font-size-text-styler-1x: 10px;
@font-size-text-styler-2x: 12px;
@font-size-text-styler-3x: 14px;
@font-size-text-styler-4x: 16px;
@font-size-text-styler-5x: 20px;
@font-size-text-styler-6x: 24px;
@font-size-text-styler-7x: 30px;
@font-size-text-styler-8x: 50px;

@font-size-hoverable-text-1--large: 50px;
@font-size-hoverable-text-1--medium: 50px;
@font-size-hoverable-text-1--small: 50px;
@font-size-hoverable-text-1--xs: 40px;

@font-size-hoverable-text-2--large: 30px;
@font-size-hoverable-text-2--medium: 30px;
@font-size-hoverable-text-2--small: 30px;
@font-size-hoverable-text-2--xs: 25px;

@font-size-hoverable-text-3--large: 20px;
@font-size-hoverable-text-3--medium: 20px;
@font-size-hoverable-text-3--small: 20px;
@font-size-hoverable-text-3--xs: 16px;

@font-size-hoverable-text-4--large: 16px;
@font-size-hoverable-text-4--medium: 16px;
@font-size-hoverable-text-4--small: 16px;
@font-size-hoverable-text-4--xs: 14px;

@font-size-button-1: 14px;
@font-size-button-2: 18px;

/**********************
      LINE HEIGHTS
***********************/
@line-height-cancel: 46px;
@line-height-cancel-xs: 36px;

/**********************
      NEW BORDER
***********************/
.blueBorder() {
  padding: 9px;
  background-color: @blue_grey;
}

/**********************
     INFO, WARNING
***********************/
@status-info: #ed6e17;
@status-warning: #ed6e17;
@status-error: #ed6e17;

@bulletin-border: @grey_light;
@bulletin: @grey_light;

@alert-border: #ebccd1;
@alert: #f2dede;
@alert-color: #a94442;

/**********************
  WEATHER COLOURS
***********************/
@weather-border: #d8dddf;
@weather-current-bg: #2e3640;
@weather-current-hit: #6e7b83;
@weather-today: #fefffe;
@weather-today-bg: #758388;
@weather-forecast-bg: #feffff;
@weather-forecast-item: #728488;

/***************************
      Inline Elements Margin
****************************/
@inline-elements-margin: 20px;

/***************************
  Container Padding
****************************/
@container-pad-left: 20px;
@container-pad-right: 20px;
@container-pad-top: 10px;
@container-pad-bottom: 10px;

/***************************
  SEARCHBAR
****************************/
@searchbar-width-lg: 1000px;
@searchbar-width-md: 800px;
@searchbar-width-xssm: 100%;
@searchbar-gutter: 20px;
