/*********************************************************************************************
  Purpose:
  --------
  Contains styles for common/external container components.
  These are found in the ui components container folder.

  Notes:
  ------
  Document all elements with comments.
**********************************************************************************************/

/***************************
    HighlightContainer
****************************/
.highlight {
  padding: 10px 20px;
}

.highlight-neutral {
  color: white;
  background-color: @grey;
}

.highlight-message {
  color: white;
  background-color: @grey_medium;
}

.highlight-danger {
  background-color: @red;
}

.highlight-success {
  background-color: @green_light;
}

.highlight-warning {
  background-color: @orange_light;
}

.highlight-important {
  color: black;
  background-color: @yellow;
}

.highlight-notification {
  position: relative;
  background-color: @grey_extra-light;
}

.highlight-text-light {
  font-family: FigtreeBold, sans-serif;
  font-weight: 700;
  color: @white;
}

.highlight-text-dark {
  font-family: FigtreeBold, sans-serif;
  font-weight: 700;
  color: @blue_dark;
}

.highlight-notification .highlight__child-container {
  width: 100%;
}

.highlight__close-button {
  position: absolute;
  top: 0;
  right: 0;
}

.highlight__close-button .dialog-close-button {
  position: relative;
  top: 10px;
  right: 10px;
  border: none;
}

.highlight--box-shadow {
  box-shadow: 2px 2px 2px 0 rgb(0 0 0 / 20%);
}

/***************************
         Section
****************************/
.section-main {
  margin-bottom: @section-main-bottom;
}

.section-main-inline {
  margin-bottom: 15px;
}

.section-sub {
  margin-bottom: @section-sub-bottom;
}

.section-sub-inline {
  margin-bottom: 5px;
}

.section-main-top {
  margin-top: 20px;
}

.section-main-top-bottom {
  margin-top: @section-main-top;
  margin-bottom: @section-main-bottom;
}

.section-main-inline-top {
  margin-top: 15px;
}

.section-sub-top {
  margin-top: 10px;
}

.section-sub-inline-top {
  margin-top: 5px;
}

.section-sub-bottom-left {
  margin-left: 10px;
}

.section-sub-bottom-right {
  margin-right: 10px;
}

.section-height {
  height: 100%;
}

/***************************
       ContentBox
****************************/
.content-box .content {
  padding: 20px;
  font-size: 15px;
  background-color: @white;
}

.content-box .encapsulate {
  overflow: hidden;
}

.content-box .title {
  display: block;
  padding: 10px;
  padding-right: 20px;
  padding-left: 20px;
  line-height: 30px;
  background: #f5f5f5;
  border-bottom: none;
}

.content-box .footnote {
  display: block;
  padding: 10px;
  padding-right: 20px;
  padding-left: 20px;
  line-height: 30px;
  background: #f5f5f5;
  border-top: none;
}

.content-box__border--grey {
  border: 1px solid @grey;
}

.content-box__border--grey-light {
  border: 1px solid @grey_light;
}

.content-box__border--grey-medium {
  border: 1px solid @grey_border;
}

.content-box .content p {
  color: inherit;
}

.content-box .highlight-danger {
  .highlight-danger();
}

.content-box .highlight-important {
  .highlight-important();
}

.content-box .highlight-neutral {
  .highlight-neutral();
}

.content-box .highlight-message {
  .highlight-message();
}

.content-box .highlight-success {
  .highlight-success();
}

.content-box .highlight-warning {
  .highlight-warning();
}

.content-box .highlight-notification {
  .highlight-notification();
}

/* 
* We are leaving these h1-h6 inside the .content-box 
* because there are data coming from the server in these tags.
* We need to make sure that these headings have margins.
*
* Refer to link below for task description.
* https://app.asana.com/0/655445391941326/714987514733489
*/
.content-box .content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 10px 0;
  }
}

.content-box .content .content {
  padding: 0;
  border: none;
}

.facility-photos .content {
  padding: 0;
}

/***************************
     AExpandPanelCustom
****************************/
.expand-panel-custom {
  margin-bottom: 20px;
  border: 1px solid;
}

.expand-panel-custom .panel {
  margin-bottom: 0;
}

.expand-panel-custom__header {
  cursor: pointer;
}

.expand-panel-custom__header .fa-chevron-down,
.expand-panel-custom__header .fa-chevron-up {
  display: flex;
  align-items: center;
}

.expand-panel-custom__header .fa-chevron-down::before,
.expand-panel-custom__header .fa-chevron-up::before {
  margin: 0 5px 0 0;
}

.expand-panel-custom__footer {
  padding-left: 0;
  border-top: 1px solid;
}

.expand-panel-custom__header-label {
  font-family: Figtree, sans-serif;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.3px;
}

.expand-panel-custom__header,
.expand-panel-custom .panel-body,
.expand-panel-custom__footer {
  padding: 10px;
}

/* no border */
.expand-panel-custom-no-border {
  border: none;
}

.expand-panel-custom-no-border .expand-panel-custom__header {
  padding: 0 0 10px;
}

.expand-panel-custom-no-border .panel-body {
  padding: 10px 0;
}

.expand-panel-custom-no-border .expand-panel-custom__footer {
  padding: 10px 0 0;
}

/* no margin */
.expand-panel-custom-no-margin {
  margin: 0;
}

/* no padding */
.expand-panel-custom-no-padding .expand-panel-custom__header {
  padding: 0;
}

.expand-panel-custom-no-padding .panel-body {
  padding: 0;
}

.expand-panel-custom-no-padding .expand-panel-custom__footer {
  padding: 0;
}

/* colour themes */

/* cerulean theme */
.cerulean .expand-panel-custom__header-label {
  color: white;
}

.expand-panel-custom.cerulean {
  border: 1px solid @cerulean;
}

.cerulean .expand-panel-custom__footer {
  border-top: 1px solid @cerulean;
}

.cerulean .expand-panel-custom__toggle,
.cerulean .expand-panel-custom__toggle span {
  color: white;
  background-color: @cerulean;
}

/* yellow theme */
.yellow .expand-panel-custom__header-label {
  color: black;
}

.expand-panel-custom.yellow {
  border: 1px solid @grey_border;
}

.yellow .expand-panel-custom__footer {
  border-top: 1px solid @grey_border;
}

.yellow .expand-panel-custom__toggle,
.yellow .expand-panel-custom__toggle span {
  color: black;
  background-color: @yellow;
}

/* green theme */
.green .expand-panel-custom__header-label {
  color: white;
}

.expand-panel-custom.green {
  border: 1px solid @grey_border;
}

.green .expand-panel-custom__footer {
  border-top: 1px solid @grey_border;
}

.green .expand-panel-custom__toggle,
.green .expand-panel-custom__toggle span {
  color: white;
  background-color: @green_available;
}

/* white theme */
.white .expand-panel-custom__header-label {
  color: black;
}

.expand-panel-custom.white {
  border: 1px solid black;
}

.white .expand-panel-custom__footer {
  border-top: 1px solid black;
}

.white .expand-panel-custom__toggle,
.white .expand-panel-custom__toggle span {
  color: black;
  background-color: @white;
}

/***************************
      Cont.js classes
****************************/
.inliner > * {
  display: inline-block;
}

.inliner-padding > :first-child {
  padding-left: 0;
}

.inliner-padding > * {
  padding-left: 10px;
}

.h-align-center {
  display: table;
  margin-right: auto;
  margin-left: auto;
}

.pad-override-5 {
  margin: -5px;
}

.pad-override-10 {
  margin: -10px;
}

.pad-override-20 {
  margin: -20px;
}

/* PaymentVoucherCmp.js and PaymentGiftCardCmp.js containers */
.voucher-container,
.used-gift-card-container {
  padding: 10px;
  margin-bottom: 10px;
  background: #f5f5f5;
}

/***************************
      SimpleContainer
****************************/
.simple-container {
  padding: 10px;
  // background-color: @white;
  box-shadow: 1px 1px 2px 0 rgb(0 0 0 / 30%);
}

.simple-container-default {
  background-color: @white;
}

.simple-container-primary {
  background-color: @orange_secondary;
}

/***************************
      Generic Cntnr Overrides
****************************/
.override-pad-tb {
  padding-top: 0;
  padding-bottom: 0;
}

.override-pad-lr {
  padding-right: 0;
  padding-left: 0;
}
