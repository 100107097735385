.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  z-index: @z_below_overlay_above_base_level_2;
  display: block;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  visibility: hidden;
  background: rgb(0 0 0 / 55%);
  backdrop-filter: blur(2px);
  opacity: 0;
  transition: all .15s ease-out;

  .container {
    position: relative;
    width: auto;
    padding: 15px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 3px 5px rgb(0 0 0 / 30%);
    transform: translateY(45px);
  }

  &--visible {
    visibility: visible;
    opacity: 1;
  }

  &__viewer,
  &__images {
    width: 85vw;

    @media @mdlg {
      width: 70vw;
    }

    @media @xl {
      width: 50vw;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__viewer,
  &__images li {
    overflow: hidden;
  }
  
  &__viewer {
    position: relative;
    height: 45vh;
    border-radius: 10px;

    @media @lg {
      height: 65vh;
    }
    
    .controls {
      position: absolute;
      top: 50%;
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 0 30px;
      transform: translateY(-50%);

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        font-size: 1em;
        color: #222;
        background: rgb(255 255 255 / 45%);
        border: none;
        border-radius: 50%;
        box-shadow: 0 3px 5px rgb(0 0 0 / 30%);
        
        &:disabled {
          visibility: hidden;
        }

        &:not(:disabled):hover {
          color: #fff;
          background: @orange;
        }
      }
    }
  }

  &__images {
    display: flex;
    justify-content: center;
    margin: 15px 0 0;
    list-style: none;

    li {
      position: relative;
      width: calc(20% - 15px);
      height: 10vh;
      margin: 0;
      overflow: hidden;
      border-radius: 5px;

      @media @lg {
        height: 12.5vh;
      }

      &:not(:last-child) {
        margin: 0 15px 0 0;
      }

      &:not(.active)::before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 4;
        display: block;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        color: #fff;
        text-shadow: 0 3px 5px rgb(0 0 0 / 30%);
        visibility: hidden;
        content: 'Click to view';
        background: fade(@orange, 15%);
        opacity: 0;
        transition: all .15s ease-out;
      }

      &:not(.active):hover {
        cursor: pointer;

        &::before {
          visibility: visible;
          opacity: 1;
        }
      }

      &.active {
        border: 3px solid @orange;
      }
    }
  }

  &__actions {
    position: absolute;
    top: -15px;
    right: -15px;

    button.btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      color: #222;
      border-radius: 50%;
      box-shadow: 0 3px 5px rgb(0 0 0 / 30%);
      transition: all .15s ease-out;

      .fa-close {
        margin: 0;
        font-size: .85em;
      }

      &:hover {
        color: #fff;
        background: @orange;
      }
    }
  }
}