.snapsots-section {
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: @white;

  .snapshots {
    width: 100%;
    // width:9999px !important;
    overflow-x: hidden;
  }

  .snapshots-content {
    position: relative;
    .transition_cubic-bezier();
  }

  a {
    display: block;

    .thumbnail-Img {
      float: left;
      width: 464px;
      height: 347px;
    }
  }

  .btn-next {
    position: absolute;
    top: 150px;
    right: 20px;
    z-index: 2;
    width: 50px;
    height: 50px;
    cursor: pointer;
    background-color: rgb(0 0 0 / 50%);
    background-image: url('../../../../../web/owp-webclient/assets/images/ra/btn-next.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 18px 31px;
    .border-radius(50%);
  }

  .btn-prev {
    position: absolute;
    top: 150px;
    left: 20px;
    z-index: 2;
    width: 50px;
    height: 50px;
    cursor: pointer;
    background-color: rgb(0 0 0 / 50%);
    background-image: url('../../../../../web/owp-webclient/assets/images/ra/btn-prev.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 18px 31px;
    .border-radius(50%);
  }

  .snapshots-logo-1 {
    position: absolute;
    bottom: 0;
    left: 25px;
    z-index: 3;
    width: 477px;
    height: 65px;
    background-image: url('../../../../../web/owp-webclient/assets/images/ra/snapshots-logo-1.png');
    background-size: 100% 100%;

    @media @lg {
      left: 100px;
    }

    @media @xs {
      left: 5px;
      width: 200px;
      height: 28px;
    }
  }

  .snapshots-logo-2 {
    position: absolute;
    right: 25px;
    bottom: 15px;
    z-index: 3;
    width: 285px;
    height: 72px;
    background-image: url('../../../../../web/owp-webclient/assets/images/ra/snapshots-logo-2.png');
    background-size: 100% 100%;

    @media @lg {
      right: 100px;
    }

    @media @xs {
      right: 5px;
      bottom: 5px;
      width: 200px;
      height: 50px;
    }
  }
}
