// Icon Sizes
// -------------------------
@import "variables.less";
/* makes the font 33% larger relative to the icon container */
.fa-lg {
  font-size: (4em / 3);
  line-height: (3em / 4);
  vertical-align: -15%;
}
.fa-2x { font-size: 2em; }
.fa-3x { font-size: 3em; }
.fa-4x { font-size: 4em; }
.fa-5x { font-size: 5em; }
