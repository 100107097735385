/*********************************************************************************************
  Purpose:
  --------
  Contains basic styles for all common elements. This is basically the general style guide.

  Notes:
  ------
  Document all elements with comments.
  Component styles should never be part of this - only general styles.
**********************************************************************************************/

/**********************
      MAIN CONTENT
***********************/

/**********************

PLEASE USE BEM CONVENTIONS
see http://getbem.com/introduction/ before you commit.
descendent selectors should be kept to an absolute minimum.

***********************/

body {
  font-family: Figtree, sans-serif;
  font-size: @font-size-body;
}

p {
  line-height: 160%;
  color: @grey;
}

p.spaced {
  margin-bottom: 15px;
}

a {
  color: @link-color;
}

a:link,
a:visited,
a:active,
a:hover {
  text-decoration: none;
  .transition_colour();
}

a:link,
a:visited,
a:active {
  color: @link-color;
}

a:hover {
  color: @link-color-hover;
}

button {
  transition: all 0.3s ease 0s;
}

/**********************
      SHORTCUTS
***********************/
.hide {
  display: none;
}

.clr {
  clear: both;
}

/**********************
       BORDERS
***********************/
.border-radius(@radius) {
  border-radius: @radius;
}

.border-radius-left(@radius) {
  border-radius: @radius 0 0 @radius;
  border-top-left-radius: @radius;
  border-bottom-left-radius: @radius;
}

.border-radius-right(@radius) {
  border-radius: 0 @radius @radius 0;
  border-top-right-radius: @radius;
  border-bottom-right-radius: @radius;
}

.border-radius-top(@radius) {
  border-radius: @radius @radius 0 0;
  border-top-left-radius: @radius;
  border-top-right-radius: @radius;
}

.border-radius (@radius: 5px) {
  border-radius: @radius;
}

/**********************
       SHADOWS
***********************/
.box-shadow(@shadow) {
  box-shadow: @shadow;
}

/**********************
         SIZES
***********************/
.size(@height, @width) {
  width: @width;
  height: @height;
}
.square(@size) {
  .size(@size, @size);
}

/**********************
      ALIGNMENT
***********************/
.center-block() {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

/**********************
       OPACITY
***********************/
.opacity(@opacity) {
  filter: ~'alpha(opacity=@{opacity})';
  opacity: @opacity / 100;
}

/***********************
      MAX HEIGHT
************************/
.max-height(@height) {
  height: 100%;
  max-height: @height;
}

.selector() {
  position: relative;
  min-height: 40px;
  padding-top: 10px;
  padding-left: 30px;
  color: @white;
  background-color: transparent;
  border-bottom: 1px solid rgb(255 255 255 / 10%);
  border-left: 5px solid transparent;
}

.selector-hover() {
  color: @white;
  cursor: pointer;
  background-color: @blue-dark-background-eighty;
  border-left: 5px solid @orange;
  .transition_ease();
}

.selector-highlight() {
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  min-height: 40px;
  background-color: transparent;
  opacity: 0;
  .transition_ease();
}

.selector-highlight-hover() {
  background-position: 0 10px;
  opacity: 1;
}

.container,
.clearfix,
.layout-block {
  .clr;
}

.container-relative {
  position: relative;
}

.f-right {
  float: right;
}

.f-left {
  float: left;
}

.t-right {
  text-align: right;
}

.t-center {
  text-align: center;
}

/* text */
.bold {
  font-family: FigtreeBold, sans-serif;
  font-weight: 700;
  color: @blue_dark;
}

strong, b {
  font-family: FigtreeBold, sans-serif;
  font-weight: 700;
}



.text-size-h1 {
  font-size: @font-size-h1;
}

.text-size-h2 {
  font-size: @font-size-h2;
}

.text-size-h3 {
  font-size: @font-size-h3;
}

.text-size-h4 {
  font-size: @font-size-h4;
}

.text-size-h5 {
  font-size: @font-size-h5;
}

.text-size-h6 {
  font-size: @font-size-h6;
}

.action {
  color: @link-color;
  .transition_ease();
}

.action:hover {
  color: @link-color;
}

.selectable {
  line-height: 24px;
  color: @link-color;
  cursor: pointer;
  .transition_ease();
}

.selectable:hover {
  color: @link-color-hover;
}

hr {
  margin-top: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid @border;
}

.gutter-all {
  margin: @gutter;
}

.gutter,
.gutter-horz {
  margin-right: @gutter;
  margin-left: @gutter;
}

.gutter-vert {
  margin-top: @gutter;
  margin-bottom: @gutter;
}

.gutter-top {
  margin-top: @gutter;
}

.gutter-bottom {
  margin-bottom: @gutter;
}

.container-link {
  font-family: FigtreeBold, sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: @link-color;
  cursor: pointer;
  .transition_ease();
}

.container-link:hover {
  color: @link-color-hover;
}

.relative-container {
  position: relative;
}

/***********************
        TABLE
************************/
table thead th,
table tbody td {
  font-family: Figtree, sans-serif;
  font-size: @font-size-h6;
  color: @grey !important;
  vertical-align: middle;
}

.nav-item() {
  font-family: Figtree, sans-serif;
  letter-spacing: 3px;
  cursor: pointer;
  transition: all 0.3s ease 0s;
}

/**********************
      DISPLAY
***********************/
.block {
  display: block;
}

.inline {
  display: inline;
}

.none {
  display: none;
}

/***********************************
      GENERAL MARGIN INLINE ELEMENTS
***********************************/
.inliner-margin > :first-child {
  margin-left: 0;
}

.inliner-margin > * {
  margin-left: @inline-elements-margin;
}

/***********************************
      POSITION FROM TOP
***********************************/
.position-100 {
  bottom: 0%;
}

.position-95 {
  bottom: 5%;
}

.position-90 {
  bottom: 10%;
}

.position-85 {
  bottom: 15%;
}

.position-80 {
  bottom: 20%;
}

.position-75 {
  bottom: 25%;
}

.position-70 {
  bottom: 30%;
}

.position-65 {
  bottom: 35%;
}

.position-60 {
  bottom: 40%;
}

.footer__note {
  font-style: italic;
}

/***************************
      DropShadow
****************************/

.drop-shadow-light {
  box-shadow: 10px 10px 38px 0 rgb(0 0 0 / 30%);
}

.drop-shadow-dark {
  box-shadow: 10px 10px 38px 0 rgb(0 0 0 / 50%);
}
