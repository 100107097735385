/***********************
     General Form
************************/
.button-selctor {
  text-align: left;
}

/**********************
 FACILITY SEARCH FORM
***********************/
.product-search-form__form-title-wrapper {
  position: relative;
  height: 40px;
}

.product-search-form {
  margin-bottom: @gutter;
}

.product-search-form__form-title {
  @media @mdlg {
    .form-title();

    position: absolute;
    z-index: @z_below_overlay_above_base;
    width: 100%;
    background-color: @status-available;
    border: 1px solid @white;
    border-bottom: none;
  }

  @media @xssm {
    .product-content-title-mobile();

    padding: 15px;
    padding-top: 0;
    padding-bottom: 0;
    text-transform: uppercase;
    letter-spacing: 3px;
  }
}

.product-search-form__contents {
  padding-top: @gutter;
  padding-right: @gutter;
  padding-left: @gutter;
  border: 1px solid @grey_light;
}

.product-search-form__contents .form-group,
.search-filter__contents .form-group {
  margin-top: @gutter;
}

.search-filters__interest-grpup {
  margin-top: @gutter;
  margin-bottom: @gutter;
  clear: both;
}

.search-filter-components {
  @media @mdlg {
    margin-top: @gutter;
  }

  @media @xssm {
    margin-top: @gutter-mobile;
  }
}

.filters-group {
  .transition_cubic-bezier();

  background-color: @white;
}

.filters-group.filters-open {
  max-height: 1200px;
  visibility: visible;
  opacity: 1;
}

.filters-group.filters-closed {
  max-height: 0;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
}

.form__arrivaldate,
.form__departuredate {
  float: left;
  width: 50%;
  padding-right: 5px;
}

.form__arrivaldate input {
  .border-radius-left(4px);
}

.form__departuredate input {
  .border-radius-right(4px);
}

.product-search-form__advanced .expand-label--open::after,
.product-search-form__advanced .expand-label--close::after,
.filters-refine--inactive::after,
.filters-refine--active::after {
  display: inline-block;
  height: 10px;
  padding-top: 2px;
  margin-left: 6px;
  font-family: FontAwesome;
  font-size: 16px;
  content: '\f106';
}

.filters-refine--active,
.filters-refine--inactive {
  display: inline-block;
  font-family: FigtreeBold, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 12px;
  color: @link-color;
  cursor: pointer;
  .transition_ease();
}

.filters-refine--active:hover,
.filters-refine--inactive:hover {
  color: @link-color-hover;
}

.product-search-form__advanced-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.product-search-form__advanced {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 13px;

  & .panel-body {
    padding-top: 0;
  }
}

.product-search-form__advanced .expand-label--close::after,
.filters-refine--inactive::after {
  content: '\f107';
}

.product-search-form__toggle-link {
  float: left;
  width: 60%;
}

.product-search-form__reset-link {
  float: right;
  width: 37%;
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 14px;
  color: @link-color;
  cursor: pointer;
}

.product-search-form__reset-link div {
  margin-bottom: 10px;
  font-size: 14px;
  color: @link-color;
}

.search-filters.searchbar__button {
  padding-left: 0;

  @media @mdlg {
    padding-top: 5px;
  }

  @media @xssm {
    padding-top: 0;
  }
}

.search-filters.searchbar__button button {
  font-size: 12px;
}

.product-search-form__options {
  .clr();
}

.product-search-form__options .checkbox {
  float: left;
  min-width: 32%;
  padding-right: 8px;
}

.product-search-form__options .checkbox label {
  text-transform: none;
  letter-spacing: 0;
}

.actionButton {
  clear: both;

  @media @mdlg {
    margin-right: 15px;
    margin-left: 15px;
  }
}

.actionButton .button-left {
  width: 100%;
}

.reset-link {
  padding-bottom: 10px;
  color: @link-color;
  cursor: pointer;
}

.reset-link:hover {
  color: @link-color-hover;
}

.actionButton .button-right {
  width: 100%;
}

.quickbook.actionButton {
  @media @mdlg {
    margin-right: 0;
    margin-left: 0;
  }
}

/***********************
     Booking Form
************************/

.cart .control-label {
  margin-bottom: 8px;
}

.product-booking-form-group {
  margin-top: 10px;
}

.booking-form__stateProvince {
  @media @mdlg {
    float: left;
    width: 50%;
  }
}

.booking-form__countries,
.booking-form__country {
  @media @mdlg {
    float: left;
    width: 50%;
  }
}

.booking-form__primaryOccupantFirstName,
.booking-form__primaryOccupantLastName {
  @media @mdlg {
    float: left;
    width: 50%;
  }
}

.booking-form__primaryOccupantHomePhone,
.booking-form__primaryOccupantEmail {
  @media @mdlg {
    float: left;
    width: 50%;
  }
}

.booking-form__numberOfCampers,
.booking-form__numberOfVehicles {
  @media @mdlg {
    float: left;
    width: 50%;
  }
}

.booking-form__primaryOccupantEmail::after {
  display: block;
  clear: both;
}

.booking-form__passes {
  clear: both;
}

.booking-form__radio-group {
  margin-top: 10px;
  margin-bottom: 10px;
}

.booking-form__radio {
  display: inline-block;
  float: left;
  margin-right: 20px;
}

.datepicker__input-container input {
  cursor: pointer;
  background-repeat: no-repeat;

  @media @xssm {
    background-position: right 5px;
  }

  @media @sm {
    background-image: url('../../../../../web/owp-webclient/assets/images/ra/calendar.png');
  }

  @media @mdlg {
    background-image: url('../../../../../web/owp-webclient/assets/images/ra/calendar.png');
    background-position: right 10px;
  }
}

/***********************
     Feedback Form
************************/

.modal-header .hack-h-tag-h4 {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 22px;
  color: @orange;
  text-transform: none;
  letter-spacing: 0;
}

.feedback-footer {
  margin-top: 20px;
}

.feedback-trigger-container {
  position: fixed;
  right: 32px;
  bottom: 25%;
  z-index: @z_below_overlay_above_base_level_2;
  padding: 8px 16px;
  text-decoration: none;
  cursor: pointer;
  background-color: #313741; //#f4f4f4;
  border-radius: 5px 5px 0 0;
  box-shadow: -1px -1px 5px 0 rgb(0 0 0 / 49%);
  transition: all 0.15s ease-in-out;
  transform: rotate(-90deg);
  transform-origin: right top 0;
}

.feedback-trigger-content {
  font-family: Figtree, sans-serif;
  font-size: 18px;
  line-height: 22px;
  color: #fff;
  text-align: center;
  transition: all 0.15s ease-in-out;

  &:hover {
    color: @blue_dark;
    background-color: #fff;
  }
}

.feedback-trigger-container:hover .feedback-trigger-content {
  color: @blue_dark;
}

.feedback-content h5 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  text-transform: none;
  letter-spacing: 0;
}

.feedback-content .btn-selection-group-selected::before {
  color: @orange;
}

.btn.feedbackbtn-selection-group-form-padding {
  padding: 10px 15px;
}
