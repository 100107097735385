/***********************
       MARKETING
************************/

.home-marketing {
  padding-top: 0;
  padding-bottom: 20px;
  background-image: url('../../../../../web/owp-webclient/assets/images/ra/home-bg.png');
  background-attachment: fixed;
}

/***********************
        HORZ AD
************************/

.horizontal-ad-section {
  min-height: 120px;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;

  &.middle-ad {
    padding: 20px 0;
  }

  &.marketing-ad {
    @media @xs {
      display: block;
      height: auto;
      padding-bottom: 0;
    }
  }

  @media @xs {
    display: none;
  }
}

.horizontal-ad-section .layout-block {
  width: 720px;
  margin: 0 auto;
}

/***********************
    FEATURED ARTICLES
************************/

.mobile-ad-section__item {
  margin-top: 20px;
}

/***********************
       ARTICLES
************************/
.articles-section {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: @white;
}

.article {
  padding-bottom: 20px;
  border: 1px solid @border;
}

.article__content {
  min-height: 276px;
  padding: 15px;
  .transition_ease();
}

.article-image {
  width: 100%;
  height: 150px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.articles-selector {
  height: 450px;
  margin-bottom: 15px;
  overflow: hidden;
}

.articles-category-selector {
  display: table !important;
  width: 100%;
  padding: 0;
  margin: 0;
  margin-top: 10px;
  clear: both;
  list-style: none;
  background-color: @blue_light;
}

.articles-category-selector li {
  display: table-cell;
  height: 40px;
  padding: 15px 5px;
  font-family: FigtreeBold, sans-serif;
  font-weight: 600;
  color: @white;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  background-color: @blue_light;
  .transition_ease();
}

.article__category {
  color: @link-color;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.article__excerpt {
  margin-top: 10px;
}

.article-wrapper {
  .transition_ease();
  .transition_cubic-bezier();
}

.article-wrapper.article--active {
  min-height: 276px;
  opacity: 1;
}

.article-wrapper.article--inactive {
  display: none;
}

/***********************
    FEATURED ARTICLES
************************/

.featured-articles__title {
  height: 40px;
  padding: 12px 10px 15px;
  font-family: FigtreeBold, sans-serif;
  font-weight: 600;
  color: @white;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: @blue_dark;
}

.featured-articles__list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.featured-articles__list li {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 10px;
  margin-left: 0;
  color: @blue_dark;
  border: 1px solid @border;
}

.featured-articles__list li a:link {
  color: @blue_dark;
}

.featured-articles__list li span {
  color: @blue_dark;
}

.articles-category-selector li:hover {
  background-color: @blue_light-hover;
}

.featured-articles__item {
  .selector();

  display: inline-block;
  width: 100%;
  height: auto;
  padding-top: 5px;
  padding-left: 20px;
  color: @blue_dark;
}

.featured-articles__item:hover {
  .selector-hover();

  color: @blue_dark-hover;
}

.featured-articles__item .featured-article-selected {
  .selector-highlight();

  height: 100%;
  background-position: 0 center;
}

.featured-articles__item:hover .featured-article-selected {
  .selector-highlight-hover();

  height: 100%;
  background-position: 0 center;
}

#homepage-right-top {
  margin-bottom: 10px;
}

.article-key-1 {
  @media @xssm {
    display: none;
  }
}
