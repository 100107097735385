.checkboxRow > div,
.checkboxRow > div > div {
  display: table;
}

.checkboxRow > div > input[type='checkbox'],
.checkboxRow > div > div > input[type='checkbox'] {
  display: table-cell;
  margin-right: 5px;
}

.checkboxRow > div > input[type='checkbox'] + label,
.checkboxRow > div > div > input[type='checkbox'] + label {
  display: table-cell;
  vertical-align: middle;
}
