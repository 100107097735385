.product-detail-content {
  clear: both;

  @media @xssm {
    padding-top: @gutter;
  }
}

.product-details-calendar .site-list-results {
  border-top: 0;
}

.quickbook__datepager .date-pager {
  margin-top: 0;
}

.product-detail-content .hack-h-tag-h5 {
  .product-content-title();
}

.product-details__amount {
  font-size: @font-size-h1;
  color: @blue_dark;
}

.product-details__pricing-unit {
  margin-right: 30px;
}

.product-details__pricing {
  margin-top: 10px;
}

.amenity-section {
  line-height: 26px;
}

.amenity-value {
  padding-left: 10px;
}

.amenity-value .note {
  color: @orange;
}

.product-detail-section {
  padding-bottom: @gutter;
  margin-bottom: @gutter;
  border-bottom: 1px solid @border;
}

.row.quickbook.actionButton {
  @media @mdlg {
    padding-bottom: @gutter;
    border-bottom: 1px solid @border;
  }
}

.product-detail-section.product-pricing {
  margin: 0;

  @media @mdlg {
    padding-bottom: 0;
    border-bottom: 0;
  }
}

.product-detail-photos {
  margin-bottom: @gutter;
}

.facility-map .raSiteDetails {
  height: 300px;
}

.facility-map .raSites {
  height: 600px;
}

// GIS Map popup styles

.raSites-popup,
.raSites-popup-highlight,
.raSites-popup-hover {
  max-width: 355px !important;
  font-family: Figtree, sans-serif;
  font-size: 13px;
  //max-width: 190px !important;
  @media @xssm {
    max-width: 190px !important;
  }
}

.raSites-popup .mapboxgl-popup-content,
.raSites-popup-highlight .mapboxgl-popup-content {
  padding: 0;
}

.raSites-popup .mapboxgl-popup-content .amenity,
.raSites-popup .mapboxgl-popup-content .shape,
.raSites-popup .mapboxgl-popup-content .siteDetails {
  padding: 10px 10px 15px;
}

.raSites-popup .header-row,
.raSites-popup-highlight .header-row {
  //width: 325px;
  @media @xssm {
    width: 190px;
  }
}

.raSites-popup .imgCTA,
.raSites-popup-highlight .imgCTA {
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #b3bdc1;
}

.raSites-popup .imgCTA .image,
.raSites-popup-highlight .imgCTA .image {
  max-width: 44%;
  margin-right: 10px;

  @media @xssm {
    display: none;
  }
}

.raSites-popup .imgCTA .ctaWrapper,
.raSites-popup-highlight .imgCTA .ctaWrapper {
  width: 50%;
  min-width: 150px;
  padding-left: 10px;
  margin-left: 10px;
  text-align: center;

  @media @xssm {
    margin-top: 5px;
  }
}

.raSites-popup .imgCTA .ctaWrapper .coordinates,
.raSites-popup-highlight .imgCTA .ctaWrapper .coordinates {
  margin-top: 10px;
  text-align: left;
  text-indent: 23px;
  white-space: nowrap;
  background-image: url('../../../../../web/owp-webclient/assets/images/ra/coords.png');
  background-repeat: no-repeat;
  background-position: left center;
}

.raSites-popup .head,
.raSites-popup-highlight .head {
  padding: 10px 10px 0;
  color: #000;
  background-color: #fff;
  border-bottom: 1px solid #b3bdc1;
}

.raSites-popup .head .site_name,
.raSites-popup-highlight .head .site_name {
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 700;
  color: #63a548;
}

.raSites-popup .head button,
.raSites-popup-highlight .head button {
  padding: 5px;
  margin-top: 10px;
  clear: both;
  color: #fff;
  background-color: #63a548;
  border: 1px solid #fff;
}

.raSites-popup .head .site_link,
.raSites-popup-highlight .head .site_link {
  margin-top: 5px;
}

.raSites-popup .head .coordinates,
.raSites-popup-highlight .head .coordinates {
  margin-top: 10px;
}

.raSites-popup .site_link,
.raSites-popup-highlight .site_link {
  cursor: pointer;
}

.raSites-popup .image img,
.raSites-popup-highlight .image img {
  width: 100%;
}

.raSites-popup .section .attributes,
.raSites-popup-highlight .section .attributes,
.raSites-popup .section .nssSummary,
.raSites-popup-highlight .section .nssSummary {
  padding: 10px;
}

.raSites-popup .head .note,
.raSites-popup .section .nssSummary .note {
  color: #da3a20;
}

// GIS Map popup styles - End

.quickbook {
  @media @mdlg {
    margin-top: @gutter;
    margin-bottom: @gutter;
  }
}

.quickbook-date-controls {
  margin-bottom: @gutter;
}

.quickbook__datepager .date-pager {
  border-bottom: 0;
}

.site-footnotes {
  margin-top: @gutter;
  margin-bottom: @gutter;
}

.product-title {
  position: relative;
}

.booking-window__site-item-link {
  padding-right: 5px;
  font-size: @font-size-small;
  color: @link-color;
  cursor: pointer;
}

.booking-window__site-item-link:hover {
  color: @link-color-hover;
}

.facility-booking-window-summary .hack-h-tag-h5 {
  margin-top: 10px;
  margin-bottom: 4px;
}

.booking-window__sites {
  margin-top: 10px;
  list-style: none;
}

.product-details-container .quickbook.actionButton {
  @media @xssm {
    margin: 0;
  }
}

.product-detail-section .product-attribute-icons-row {
  display: inline-block;
}

.product-detail-section .product-attribute-icons {
  margin-top: 10px;
}

.product-detail-section .attribute-found {
  margin-right: 10px;
}

.product-detail-section .attribute-not-found {
  display: none;
}
