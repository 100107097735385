/*********************************************************************************************
  Purpose:
  --------
  Contains global overrides for bootstrap styles so that we can keep !important to a minimum.

  Notes:
  ------
  Document all elements with comments.
**********************************************************************************************/

/**********************
   OVERRIDE BOOTSTRAP
***********************/

html {
  font-size: 12px;
}

body {
  color: @grey !important;
}

.form-check-input {
  position: relative;
  margin: 0 !important;
}

.form-check {
  display: flex;
  align-items: center;
  padding: 0;
  margin-bottom: 10px;

  .form-check-input {
    margin: 0;
  }

  .form-check-label {
    margin: 0 0 0 8px;
  }

  ~ .form-help-block {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: 700;
  }
}

.error.form-check {
  color: @alert-color;
}

[validationState='error'] {
  color: @alert-color;
}

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem #0d6efd40;
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);

  box-sizing: initial;
  width: 1em;
  height: 1em;
  padding: 0.25em;
  background: #0000 var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--bs-btn-close-opacity);
}

.btn-breadcrumb .btn {
  margin-left: 0 !important;
}

.btn-breadcrumb .btn-current {
  border: 1px solid #000;
}

.btn-breadcrumb .btn-default {
  border: 1px solid #ccc;
}

.btn-breadcrumb.hidden-sm.hidden-xs {
  display: flex !important;

  @media (width <= 992px) {
    display: none !important;
  }
}

/**********************
    BUTTONS & FORMS
***********************/

.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-image: none;
  border: 1px solid #0000;
  border-radius: 4px;
}

.btn-primary,
.btn-success {
  width: 100%;
  font-size: 14px !important;
  background-color: @orange !important;
  border-color: @orange !important;

  @media @xssm {
    height: 36px;
  }

  @media @mdlg {
    height: 46px;
  }
}

.btn-primary {
  // font-size: 18px !important;
}

.btn-font-size-1 {
  font-size: @font-size-button-1 !important;
}

.btn-font-size-2 {
  font-size: @font-size-button-2 !important;
}

a.btn-primary {
  @media @mdlg {
    padding-top: 10px;
  }
  .transition_colour();
}

a.btn-primary,
a.btn-primary:active,
a.btn-primary:visited,
a.btn-primary:hover {
  color: @white !important;
}

.btn-info {
  width: 100%;
  color: @grey !important;
  background-color: @white !important;
  border-color: @grey !important;

  @media @xssm {
    height: 36px;
  }

  @media @mdlg {
    height: 46px;
  }
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-success:hover,
.btn-success:focus,
.btn-success:active {
  background-color: @orange_light !important;
  border-color: @orange_light !important;
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active {
  color: @blue_dark;
  background-color: @grey_light !important;
  border-color: @grey !important;
}

.btn.btn-selection-group,
.btn.btn-selection-group-selected {
  min-width: 31%;
  margin-right: 3px;
  margin-bottom: 5px;
  margin-left: 3px;
  color: #333;
  white-space: normal;
  background-color: @white !important;
  border-color: #ccc;
}

.btn.btn-selection-group-selected {
  color: @orange !important;
  border-color: @orange !important;
}

.bsw-secondary .btn-selection-group-selected {
  color: @white !important;
  background-color: @cerulean !important;
  border-color: @cerulean !important;
}

.btn.btn-selection-group {
  border-color: @grey !important;
}

.btn.btn-selection-group:hover {
  color: @blue_dark;
  background-color: @grey_light !important;
  border-color: @grey !important;
}

.bsw-secondary .btn-selection-group:hover {
  color: @white;
  background-color: @cerulean !important;
  border-color: @cerulean !important;
}

.btn.btn-selection-group-selected:hover {
  background-color: @grey_light !important;
}

.bsw-secondary .btn-selection-group-selected:hover {
  background-color: @cerulean_light !important;
}

.btn.btn-selection-group-width-default {
  width: auto;
  width: initial;
  min-width: 0%;
}

/* standard button widths for default padding of padding: 0px 6px; */
.btn.btn-selection-group-width1,
.btn.btn-selection-group-form-width1 {
  min-width: 100%;
  margin-right: 0;
  margin-left: 0;
}

.btn.btn-selection-group-width2 {
  min-width: 48%;
}

.btn.btn-selection-group-width3 {
  min-width: 31%;
}

.btn.btn-selection-group-width4 {
  min-width: 23%;
}

/* standard button widths for padding of padding: 10px 20px; */
.btn.btn-selection-group-form-width2 {
  min-width: 46%;
}

.btn.btn-selection-group-form-width3 {
  min-width: 29%;
}

.btn.btn-selection-group-form-width4 {
  min-width: 21%;
}

.btn.btn-selection-group-form-padding {
  height: 100%;
  padding: 10px 20px;
}

.btn.btn-selection-group-form-margin {
  margin-right: 20px;
  margin-bottom: 20px;
  margin-left: 0;
}

.btn.btn-selection-group.select {
  display: none;
}

.has-error {
  font-weight: 700;
}

.has-error label,
.has-error p {
  color: @alert-color !important;
}

.has-error input,
.has-error select,
.has-error textarea {
  border-color: @alert-color;
}

.has-error .btn-selection-group {
  color: @alert-color;
  border-color: @alert-color;
}

.form-group {
  margin-bottom: @form-group-bottom-margin;
  border: 0 !important;
}

.form-group-small {
  margin-bottom: @form-group-small-bottom-margin;
}

.form-control {
  font-size: 14px;
  border: 1px solid @grey_field;

  @media @xssm {
    height: 36px;
  }

  @media @mdlg {
    height: 44px;
  }
}

.searchbar__input .form-group,
.searchbar__button button {
  margin-bottom: 0;
  border: 0 !important;
}

.btn-highlight {
  padding: 10px 20px !important;
  color: @white;
  background-color: @black;
  border-color: @black;
  border-radius: 23px;

  &:hover,
  &:focus,
  &:active {
    color: @orange;
    background-color: @black;
    border-color: @black;
  }

  @media @xssm {
    padding: 5px 10px !important;
  }
}

.btn-highlight-light {
  padding: 10px 20px !important;
  color: @white;
  background-color: @blue_grey;
  border-color: @blue_grey;
  border-radius: 23px;

  &:hover,
  &:focus,
  &:active {
    color: @orange;
    background-color: @blue_grey;
    border-color: @blue_grey;
  }

  @media @xssm {
    padding: 5px 10px !important;
  }
}

.btn-fixed-width-size-1x {
  width: 80px;
}

.btn-fixed-width-size-2x {
  width: 140px;
}

.btn-fixed-width-size-3x {
  width: 210px;
}

.btn-hide-focus {
  outline: 0 !important;
}

/**********************
          CARD
***********************/

.card-body {
  padding: 0;
}

/**********************
          PANEL
***********************/

.panel {
  box-shadow: none;
}

.panel-default {
  border: 0;
}

.panel-default {
  border-color: #ddd;
}

.panel.panel-default {
  overflow: visible;
}

.panel-group .panel {
  margin-bottom: 0;
  overflow: hidden;
  border-radius: 4px;
}

.panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid #0000;
  border-radius: 4px;
  box-shadow: 0 1px 1px #0000000d;
}

.panel-default > .panel-heading {
  background-color: initial;
  border: 0;
}

.panel-default > .panel-heading {
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd;
}

.panel-group .panel-heading {
  border-bottom: 0;
}

.panel-heading {
  padding: 0;
}

.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid #0000;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.panel-title {
  padding: 0 14px 14px 0;
}

.panel-body h3,
.panel-title {
  height: 25px;
  font-family: FigtreeBold, sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #0093d0;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
}

.panel-heading > .dropdown .dropdown-toggle,
.panel-title {
  color: inherit;
}

/**********************
      STRUCTURE
***********************/

.full-bleed {
  position: relative;
  padding: 0;
  margin: 0;
}

/**********************
      ACCORDION
***********************/
.panel-heading h4 a {
  position: relative;
  display: block;
  text-decoration: none;
  cursor: pointer;
}

h4.panel-heading {
  @media @xssm {
    margin: 0;
  }
}

.panel-heading h4 a::after {
  color: @blue_dark;
  transition: all 0.3s ease 0s;
}

.panel-heading h4 a[aria-expanded='true']::after {
  display: block;
  font-family: FontAwesome;
  content: '\f068';
}

.panel-heading h4 a {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.panel-heading h4 a[aria-expanded='true']::before {
  position: absolute;
  left: -10px;
  display: block;
  width: 4px;
  height: 16px;
  content: '';
  background-color: @orange;
}

.panel-heading h4 a[aria-expanded='false']::after {
  display: block;
  font-family: FontAwesome;
  content: '\f067';
}

.panel.panel-default {
  overflow: visible;
}

.panel-heading h4 a:active::after {
  color: #666; /* Safari */
  transform: rotateY(90deg); /* Standard syntax */
}

.panel-heading h4 a:hover,
.panel-heading h4 a:active {
  color: @blue_dark;
}

.panel-heading h4 {
}

.panel-default {
  border: 0;
}

.panel-title,
.panel-body h3 {
  height: 25px;
  font-family: FigtreeBold, sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: @blue_light;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.panel-body h3 {
  height: auto;
  padding-top: 14px;
  padding-bottom: 0;
  margin: 0;
  color: @blue_dark;
}

.panel-title {
  padding: 0 14px 14px 0;
}

.panel {
  box-shadow: none;
}

.panel-body {
  padding-top: 0;
  padding-right: 0;
  padding-left: 0;
  margin-top: 0;
}

.panel-heading {
  padding: 0;
}

.form-control:focus,
.form-control:active,
.form-control {
  box-shadow: none !important;
}

.panel-default > .panel-heading {
  background-color: transparent;
  border: 0;
}

.facility-overview-details .panel-body {
  margin-bottom: @gutter;
  border-bottom: 1px solid @border;
}

.breadcrumb {
  padding: 15px 0;
  padding-bottom: 0;
  background-color: transparent;
}

.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
.btn-success[disabled],
fieldset[disabled] .btn-primary.active {
  color: @grey_light;
  background-color: @grey;
  border-color: @grey_light;
}

.btn {
  padding-right: 6px;
  padding-left: 6px;
}

/**********************
         TABLE
***********************/
.table > thead > tr > th {
  border-bottom: 0;
}

.table-responsive > .table > thead > tr > th,
.table-responsive > .table > tbody > tr > th,
.table-responsive > .table > tfoot > tr > th,
.table-responsive > .table > thead > tr > td,
.table-responsive > .table > tbody > tr > td,
.table-responsive > .table > tfoot > tr > td {
  @media @xssm {
    white-space: normal !important;
  }
}

.panel-title a,
.panel-title a:hover,
.panel-title a:active {
  color: @link-color;
}

.panel-title [aria-expanded='true'] {
  color: @link-color;
}

.panel-group .panel-heading + .panel-collapse .panel-body {
  border-top: 0;
}

.action-buttom__xssm-fixed .btn[disabled] {
  opacity: 1;
}

// ctane - commented this out, because it looks like the only place where it was used (ADatePicker) is not used anymore.
// .form-control[readonly] {
//   background-color:@white;
//   cursor:pointer;
// }

option:disabled {
  color: @grey_disabled;
}

.alert {
  padding: 8px;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid @red;
  border-radius: 0;
}

.alert-secondary {
  background: transparent;
}

.search-help-error,
.search-help-hint {
  padding: 8px;
}

.alert-danger,
.search-help-error,
.search-help-hint {
  font-family: FigtreeBold, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: @white;
  background-color: @red;
  border-color: @red;
}

.search-help-hint {
  background-color: @messagebar;
  border-color: @messagebar;
}

.alert.alert-danger::before,
.search-help-error::before,
.search-help-hint::before {
  display: inline-block;
  width: 30px;
  margin-left: 10px;
  font-family: FontAwesome;
  font-size: 20px;
  color: @white;
  content: '\f06a';
}

.search-help-hint::before {
  content: '\f05a';
}

.alert.alert-danger::before {
  float: left;
  margin-top: -4px;
}

.alert.alert-danger div {
  display: block;
  margin-left: 20px;
}

.error-inline-text {
  display: inline;
  font-family: FigtreeBold, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: @red;
}

.error-inline-text .fa {
  width: 25px;
  font-size: 20px;
}

.error-inline-wrapper {
  margin-bottom: 10px;
}

.error-inline-wrapper div {
  display: inline;
}

.error-inline-text + .base-link {
  margin-left: 10px;
}

.form-group .error-inline-wrapper:nth-last-child(1) {
  margin-bottom: 0;
}

/*
.datepicker__tether-element, .datepicker__tether-out-of-bounds-right {
  @media @xssm {
    width:90%;
    translateX(0px) !important;
  }
}

.datepicker {
    @media @xssm {
      width:100%;
    }
}

.datepicker__day {
  @media @xssm {
    color: #000;
    display: inline-block;
    width: 12%;
    line-height: 24px;
    text-align: center;
    margin: 2px;
    cursor: pointer;
  }
}
*/

.modal-header {
  border-bottom: 0;
}

.modal-body {
  padding-top: 0;
}

.modal-header {
  padding-bottom: 0;
}

ul {
  margin-bottom: 10px;
}

ul,
li,
ol {
  margin-left: 15px;
}

.container-fluid {
  margin-right: 15px;
  margin-left: 15px;
}

.extra-large-width {
  @media @xl {
    width: 1370px;
    max-width: 1370px;
  }
}

.bs-button-right(@radius) {
  border-radius: 0 @radius @radius 0 !important;
  border-top-right-radius: @radius !important;
  border-bottom-right-radius: @radius !important;
}

.progress-bar-success {
  background-color: @orange;
}

blockquote p {
  font-size: 16px;
}

.hidden-xs {
  display: block !important;
}

table.hidden-xs {
  display: table;
}

tr.hidden-xs {
  display: table-row !important;
}

th.hidden-xs,
td.hidden-xs {
  display: table-cell !important;
}

/* Normal breakpoints */

@media (width <= 767px) {
  .hidden-xs,
  tr.hidden-xs,
  th.hidden-xs,
  td.hidden-xs {
    display: none !important;
  }
}

@media (width >= 768px) and (width <= 991px) {
  .hidden-xs.hidden-sm,
  tr.hidden-xs.hidden-sm,
  th.hidden-xs.hidden-sm,
  td.hidden-xs.hidden-sm {
    display: none !important;
  }
}

@media (width >= 992px) and (width <= 1199px) {
  .hidden-xs.hidden-md,
  tr.hidden-xs.hidden-md,
  th.hidden-xs.hidden-md,
  td.hidden-xs.hidden-md {
    display: none !important;
  }
}

@media (width >= 1200px) {
  .hidden-xs.hidden-lg,
  tr.hidden-xs.hidden-lg,
  th.hidden-xs.hidden-lg,
  td.hidden-xs.hidden-lg {
    display: none !important;
  }
}

/* Breakpoints for pixel densities */

@media (width <= 767px) and (min-device-pixel-ratio: 2),
  (width <= 767px) and (resolution >= 192dpi) {
  .hidden-xs,
  tr.hidden-xs,
  th.hidden-xs,
  td.hidden-xs {
    display: none !important;
  }
}

@media (width >= 768px) and (width <= 991px) and (min-device-pixel-ratio: 2),
  (width >= 768px) and (width <= 991px) and (resolution >= 192dpi) {
  .hidden-xs.hidden-sm,
  tr.hidden-xs.hidden-sm,
  th.hidden-xs.hidden-sm,
  td.hidden-xs.hidden-sm {
    display: none !important;
  }
}

@media (width >= 992px) and (width <= 1199px) and (min-device-pixel-ratio: 2),
  (width >= 992px) and (width <= 1199px) and (resolution >= 192dpi) {
  .hidden-xs.hidden-md,
  tr.hidden-xs.hidden-md,
  th.hidden-xs.hidden-md,
  td.hidden-xs.hidden-md {
    display: none !important;
  }
}

@media (width >= 1200px) and (min-device-pixel-ratio: 2),
  (width >= 1200px) and (resolution >= 192dpi) {
  .hidden-xs.hidden-lg,
  tr.hidden-xs.hidden-lg,
  th.hidden-xs.hidden-lg,
  td.hidden-xs.hidden-lg {
    display: none !important;
  }
}

.hidden-sm {
  display: block !important;
}

table.hidden-sm {
  display: table;
}

tr.hidden-sm {
  display: table-row !important;
}

th.hidden-sm,
td.hidden-sm {
  display: table-cell !important;
}

@media (width <= 767px) {
  .hidden-sm.hidden-xs,
  tr.hidden-sm.hidden-xs,
  th.hidden-sm.hidden-xs,
  td.hidden-sm.hidden-xs {
    display: none !important;
  }
}

@media (width >= 768px) and (width <= 991px) {
  .hidden-sm,
  tr.hidden-sm,
  th.hidden-sm,
  td.hidden-sm {
    display: none !important;
  }
}

@media (width >= 992px) and (width <= 1199px) {
  .hidden-sm.hidden-md,
  tr.hidden-sm.hidden-md,
  th.hidden-sm.hidden-md,
  td.hidden-sm.hidden-md {
    display: none !important;
  }
}

@media (width >= 1200px) {
  .hidden-sm.hidden-lg,
  tr.hidden-sm.hidden-lg,
  th.hidden-sm.hidden-lg,
  td.hidden-sm.hidden-lg {
    display: none !important;
  }
}

/* Breakpoints for pixel densities */

@media (width <= 767px) and (min-device-pixel-ratio: 2),
  (width <= 767px) and (resolution >= 192dpi) {
  .hidden-sm.hidden-xs,
  tr.hidden-sm.hidden-xs,
  th.hidden-sm.hidden-xs,
  td.hidden-sm.hidden-xs {
    display: none !important;
  }
}

@media (width >= 768px) and (width <= 991px) and (min-device-pixel-ratio: 2),
  (width >= 768px) and (width <= 991px) and (resolution >= 192dpi) {
  .hidden-sm,
  tr.hidden-sm,
  th.hidden-sm,
  td.hidden-sm {
    display: none !important;
  }
}

@media (width >= 992px) and (width <= 1199px) and (min-device-pixel-ratio: 2),
  (width >= 992px) and (width <= 1199px) and (resolution >= 192dpi) {
  .hidden-sm.hidden-md,
  tr.hidden-sm.hidden-md,
  th.hidden-sm.hidden-md,
  td.hidden-sm.hidden-md {
    display: none !important;
  }
}

@media (width >= 1200px) and (min-device-pixel-ratio: 2),
  (width >= 1200px) and (resolution >= 192dpi) {
  .hidden-sm.hidden-lg,
  tr.hidden-sm.hidden-lg,
  th.hidden-sm.hidden-lg,
  td.hidden-sm.hidden-lg {
    display: none !important;
  }
}

.hidden-md {
  display: block !important;
}

table.hidden-md {
  display: table;
}

tr.hidden-md {
  display: table-row !important;
}

th.hidden-md,
td.hidden-md {
  display: table-cell !important;
}

@media (width <= 767px) {
  .hidden-md.hidden-xs,
  tr.hidden-md.hidden-xs,
  th.hidden-md.hidden-xs,
  td.hidden-md.hidden-xs {
    display: none !important;
  }
}

@media (width >= 768px) and (width <= 991px) {
  .hidden-md.hidden-sm,
  tr.hidden-md.hidden-sm,
  th.hidden-md.hidden-sm,
  td.hidden-md.hidden-sm {
    display: none !important;
  }
}

@media (width >= 992px) and (width <= 1199px) {
  .hidden-md,
  tr.hidden-md,
  th.hidden-md,
  td.hidden-md {
    display: none !important;
  }
}

@media (width >= 1200px) {
  .hidden-md.hidden-lg,
  tr.hidden-md.hidden-lg,
  th.hidden-md.hidden-lg,
  td.hidden-md.hidden-lg {
    display: none !important;
  }
}

/* Breakpoints for pixel densities */

@media (width <= 767px) and (min-device-pixel-ratio: 2),
  (width <= 767px) and (resolution >= 192dpi) {
  .hidden-md.hidden-xs,
  tr.hidden-md.hidden-xs,
  th.hidden-md.hidden-xs,
  td.hidden-md.hidden-xs {
    display: none !important;
  }
}

@media (width >= 768px) and (width <= 991px) and (min-device-pixel-ratio: 2),
  (width >= 768px) and (width <= 991px) and (resolution >= 192dpi) {
  .hidden-md.hidden-sm,
  tr.hidden-md.hidden-sm,
  th.hidden-md.hidden-sm,
  td.hidden-md.hidden-sm {
    display: none !important;
  }
}

@media (width >= 992px) and (width <= 1199px) and (min-device-pixel-ratio: 2),
  (width >= 992px) and (width <= 1199px) and (resolution >= 192dpi) {
  .hidden-md,
  tr.hidden-md,
  th.hidden-md,
  td.hidden-md {
    display: none !important;
  }
}

@media (width >= 1200px) and (min-device-pixel-ratio: 2),
  (width >= 1200px) and (resolution >= 192dpi) {
  .hidden-md.hidden-lg,
  tr.hidden-md.hidden-lg,
  th.hidden-md.hidden-lg,
  td.hidden-md.hidden-lg {
    display: none !important;
  }
}

.hidden-lg {
  display: block !important;
}

table.hidden-lg {
  display: table;
}

tr.hidden-lg {
  display: table-row !important;
}

th.hidden-lg,
td.hidden-lg {
  display: table-cell !important;
}

@media (width <= 767px) {
  .hidden-lg.hidden-xs,
  tr.hidden-lg.hidden-xs,
  th.hidden-lg.hidden-xs,
  td.hidden-lg.hidden-xs {
    display: none !important;
  }
}

@media (width >= 768px) and (width <= 991px) {
  .hidden-lg.hidden-sm,
  tr.hidden-lg.hidden-sm,
  th.hidden-lg.hidden-sm,
  td.hidden-lg.hidden-sm {
    display: none !important;
  }
}

@media (width >= 992px) and (width <= 1199px) {
  .hidden-lg.hidden-md,
  tr.hidden-lg.hidden-md,
  th.hidden-lg.hidden-md,
  td.hidden-lg.hidden-md {
    display: none !important;
  }
}

@media (width >= 1200px) {
  .hidden-lg,
  tr.hidden-lg,
  th.hidden-lg,
  td.hidden-lg {
    display: none !important;
  }
}

/* Breakpoints for pixel densities */

@media (width <= 767px) and (min-device-pixel-ratio: 2),
  (width <= 767px) and (resolution >= 192dpi) {
  .hidden-lg.hidden-xs,
  tr.hidden-lg.hidden-xs,
  th.hidden-lg.hidden-xs,
  td.hidden-lg.hidden-xs {
    display: none !important;
  }
}

@media (width >= 768px) and (width <= 991px) and (min-device-pixel-ratio: 2),
  (width >= 768px) and (width <= 991px) and (resolution >= 192dpi) {
  .hidden-lg.hidden-sm,
  tr.hidden-lg.hidden-sm,
  th.hidden-lg.hidden-sm,
  td.hidden-lg.hidden-sm {
    display: none !important;
  }
}

@media (width >= 992px) and (width <= 1199px) and (min-device-pixel-ratio: 2),
  (width >= 992px) and (width <= 1199px) and (resolution >= 192dpi) {
  .hidden-lg.hidden-md,
  tr.hidden-lg.hidden-md,
  th.hidden-lg.hidden-md,
  td.hidden-lg.hidden-md {
    display: none !important;
  }
}

@media (width >= 1200px) and (min-device-pixel-ratio: 2),
  (width >= 1200px) and (resolution >= 192dpi) {
  .hidden-lg,
  tr.hidden-lg,
  th.hidden-lg,
  td.hidden-lg {
    display: none !important;
  }
}

.visible-print,
tr.visible-print,
th.visible-print,
td.visible-print {
  display: none !important;
}

@media print {
  .visible-print {
    display: block !important;
  }

  table.visible-print {
    display: table;
  }

  tr.visible-print {
    display: table-row !important;
  }

  th.visible-print,
  td.visible-print {
    display: table-cell !important;
  }

  .hidden-print,
  tr.hidden-print,
  th.hidden-print,
  td.hidden-print {
    display: none !important;
  }
}
