/***************************
DestinationSummaryCard
****************************/

.destination-summary-card {
  .transition_ease();

  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
}

.destination-summary-card__container {
  position: relative;
  display: table;
  .transition_ease();
}

.destination-summary-card__image-wrapper {
  display: table-cell;

  @media @xssm {
    width: 80px;
  }

  @media @mdlg {
    width: 104px;
  }
}

.destination-summary-card__image {
  @media @xssm {
    width: 80px;
    height: 80px;
  }

  @media @mdlg {
    width: 104px;
    height: 104px;
  }

  background-image: url('../../../../../web/owp-webclient/assets/images/ra/card_default.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border: 1px solid @border;
}

.destination-summary-card__detail {
  position: relative;
  display: table-cell;
  padding: 0 10px;
  text-align: left;
  vertical-align: top;
}

.destination-summary-card__footer {
  margin-top: auto;
}
