@import '../../../../../less/external/variables.less';

.chub-checkout {
  &__summary {
    margin-bottom: 20px;
  }

  &__btn {
    font-size: 1.2em;
  }

  &__heading-btn {
    display: block;
    width: 100%;
    padding: 10px;
    text-align: left;
    background: transparent;
    border: none;
  }

  &__heading {
    background-color: @grey_light;
    border: 1px solid @grey_field;
  }

  &__form-content {
    position: relative;
  }

  &__spinner {
    position: absolute;
  }

  &__form-body,
  &__footer-btns {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  &__form-body {
    padding: 10px;
    border: 1px solid @grey_field;
    border-top: none;
  }

  &__footer-btns {
    margin-top: 20px;
  }
}
