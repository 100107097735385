/******************
   ADS WATERMARK
*******************/

.ra-advert-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

div[id^='google_ads_iframe'] {
  position: relative;
  overflow: hidden;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 10px;
    color: #bcbcbc;
    text-align: center;
    text-transform: uppercase;
    pointer-events: none;
    content: var(--ads-watermark-label, 'Advertisement');
  }
}
