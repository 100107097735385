.add-favorite,
.remove-favorite {
  display: inline;
  cursor: pointer;
}

.facility-detail__add-favorite img,
.facility-detail__remove-favorite img {
  width: 16px;
  height: 14px;
  margin-bottom: 4px;
}

.facility-summary-card-favorite-icon {
  display: inline;
}

@keyframes heartbeat {
  0% {
    transform: scale(0.75);
    //  content: url('../../../../../web/owp-webclient/assets/images/ra/favorite-full.png');
  }

  20% {
    transform: scale(1);
    //  content: url('../../../../../web/owp-webclient/assets/images/ra/favorite-full.png');
  }

  40% {
    transform: scale(0.75);
    //content: url('../../../../../web/owp-webclient/assets/images/ra/favorite-full.png');
  }

  60% {
    transform: scale(1);
    //content: url('../../../../../web/owp-webclient/assets/images/ra/favorite-full.png');
  }

  80% {
    transform: scale(0.75);
    //content: url('../../../../../web/owp-webclient/assets/images/ra/favorite-full.png');
  }

  100% {
    transform: scale(0.75);
    //content: url('../../../../../web/owp-webclient/assets/images/ra/favorite-full.png');
  }
}

.favorites-animated-icon {
  animation: heartbeat 1s 2;
}

.list-summary-card__container {
  @media @xs {
    margin: 0 10px;
  }

  @media @smmdlg {
    margin: 0 20px;
  }

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.list-summary-card {
  width: 100%;

  @media @smmdlg {
    height: 150px;
  }

  @media @xs {
    height: 120px;
  }

  padding: 10px;
  cursor: pointer;
  background-color: rgb(0 0 0 / 40%);
}

.list-summary-card__title {
  @media @smmdlg {
    font-size: 30px;
  }

  @media @xs {
    font-size: 20px;
  }

  color: white;
}

.list-summary-card__sub-title {
  @media @smmdlg {
    font-size: 16px;
  }

  @media @xs {
    font-size: 14px;
  }

  color: white;
}

.favorites-facility-card__add-to-list {
  font-family: FigtreeBold, sans-serif;
  font-weight: 700;
  text-align: right;
}

.favorites-facility-card__add-to-list .icon-action i {
  font-size: 14px;
}

.favorites-facility-card__routing-arrow-container {
  padding: 10px 20px;
  margin: 10px;
  border-top: 1px dotted @grey;
  border-bottom: 1px dotted @grey;
}

.favorites-facility-card__routing-arrow-container .icon-action {
  display: inline-block;
}

.facility-summary-card--routing {
  padding-bottom: 0;
  border-bottom: none;
}

.waypoint-summary-card .facility-summary-card__image,
.waypoint-summary-card .facility-summary-card__title {
  color: @blue_dark;
  cursor: default;
}

.list-title {
  font-size: 30px;
  line-height: 35px;
  color: @blue_dark;
}

.list-title div[class^='col'],
.favorites__footer div[class^='col'] {
  @media @xs {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.list-title .editable-text-icon {
  color: @grey;
}

.add-favorite-to-list-header .facility-summary-card {
  border-bottom: none;
}

.add-favorite-to-multiple-lists__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}

.add-favorite-to-list__container {
  display: table;
  width: 100%;
  cursor: pointer;

  @media @mdlg {
    padding-left: 5px;
    border-left: 3px solid @white;
  }
}

.add-favorite-to-list__container:hover {
  @media @mdlg {
    border-left: 3px solid @orange;
  }
}

.add-favorite-to-list__container--selected {
  @media @mdlg {
    border-left: 3px solid @orange;
  }
}

.add-favorite-to-list__input-new-list {
  width: 100%;
  padding-bottom: 10px;
  margin: 0 25px 0 0;
}

.add-favorite-to-list__list-image-wrapper {
  display: table-cell;
  width: 10%;
}

.add-favorite-to-list__list-image {
  @media @smmdlg {
    width: 60px;
    height: 50px;
  }

  @media @xs {
    width: 45px;
    height: 38px;
  }
}

.add-favorite-to-list__list-title {
  display: table-cell;
  width: 85%;
  padding-left: 10px;
  font-family: Figtree, sans-serif;
  font-size: 15px;
  font-weight: 700;
  color: @blue_dark;
  vertical-align: middle;
}

.add-favorite-to-list__checkbox {
  margin: 0;
}

.favorites__footer .row,
.container__panels--left-default-container .row {
  @media @xs {
    margin-right: -10px;
    margin-left: -10px;
  }
}

.favorites__list-details-header {
  padding-bottom: 5px;
  border-bottom: 2px solid @grey_border;
}

.favorites__list-details-header .text-editable--truncate {
  @media @xs {
    max-width: 230px;
  }

  @media @mdlg {
    max-width: 420px;
  }
}

.favorites__list-details-header .icon-action {
  display: inline;
}

.favorites__list-details-header .icon-action i {
  padding-bottom: 2px;
  vertical-align: middle;
}

.favorites__main-menu .simple-selector__item--selected,
.favorites__main-menu .simple-selector__item {
  @media @xssm {
    font-size: 14px;
  }
}

.favorites__main-menu .simple-selector__items {
  padding: 20px 0;
  text-align: center;

  @media @xssm {
    padding: 10px 0;
  }
}

.favorites__sub-menu {
  padding: 5px 0;
  border-bottom: 2px solid @grey_border;
}

.favorites__sub-menu div[class^='col'] {
  padding-left: 0;
}

.favorites__sub-menu .favorites__sub-menu__action-bar {
  padding: 5px 0 5px 10px;
}

.favorites__sub-menu .simple-selector {
  padding: 5px;
  border-bottom: none;
}

.favorites__sub-menu .simple-selector .icon-action {
  display: inline;
  float: none;
  margin-left: 10px;
  font-size: 14px;
}

.favorites__sub-menu .simple-selector__items--options-2 {
  display: inline;
  padding: 5px;
  text-align: right;
}

.favorites__sub-menu .simple-selector__item--selected {
  padding-bottom: 0;
  color: @blue_dark;
  border-bottom: none;
}

.favorites__sub-menu .simple-selector__item--selected a,
.favorites__sub-menu .simple-selector__item--selected .icon-action {
  color: @blue_dark;
}

.favorites__sub-menu .simple-selector__item,
.favorites__sub-menu .simple-selector__item .icon-action {
  color: @link-color;
}

.favorites__sub-menu .simple-selector__item--selected,
.favorites__sub-menu .simple-selector__item {
  font-size: 14px;
  font-weight: normal;
}

.favorites__sub-menu .row {
  margin-right: 0;
  margin-left: 0;
}

.favorites__footer {
  padding: 10px 20px;

  @media @xs {
    padding: 10px;
  }
}

.favorite-summary-card__container {
  margin-bottom: 20px;

  @media @xs {
    margin-bottom: 10px;
  }
}
