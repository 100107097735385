.chub-num-input {
  .help-block {
    position: absolute;
    margin: 0;
  }

  label {
    white-space: nowrap;
  }
}
