#popup-wrapper-AAM {
  width: 100%;
  font-size: 10pt;

  @media @xs {
    display: none;
  }
}

.popup-wrapper-AAM #clicker_open {
  @media @xs {
    display: none;
  }

  pointer-events: none;
  cursor: default;
}

.popup-wrapper-AAM {
  padding: 0 20px;
  margin-top: 10px;

  @media @xs {
    padding: 0 10px;
  }
}

.product-details-container .popup-wrapper-AAM {
  padding: 0;
  margin-top: 20px;
}

.popup-wrapper-AAM .panel-body {
  @media @xs {
    padding: 0;
  }
}

.popup-wrapper-AAM img {
  display: inline;
}

.popup-wrapper-AAM-header img {
  margin: -5px 10px 0 0;
}

.popup-wrapper-AAM-header {
  font-size: 11px;
}

#popup_header {
  padding: 9px;
  background-color: #3c3c3c;
}

#popup-wrapper-AAM h3,
#popup-wrapper-AAM h4 {
  font-family: FigtreeBold, sans-serif;
  font-size: 100%;
  font-weight: 600;
  font-weight: 700;
  color: @grey !important;
  text-transform: none;
  letter-spacing: 0;
}

#popup-wrapper-AAM h3 {
  margin: 5px 0;
}

#popup-wrapper-AAM h4 {
  margin: 10px 0;
}

#popup-wrapper-AAM li {
  padding: 0 0 8px;
  margin-left: 0;
}

#popup_close {
  display: none;
}

#popup_leftcol {
  float: left;
  width: 60%;
  padding: 15px;
  text-indent: 0;
}

#popup_rightcol {
  float: right;
  width: 40%;
  padding: 15px 20px;
  text-indent: 0;
  background-color: #f1f1f1;
}
