/*********************************************************************************************
  Purpose:
  --------
  Contains overrides for third party components.

  Notes:
  ------
  Document all elements with comments.
  Do not add anything other than overrides for third party components here.
**********************************************************************************************/

/***************************
         alerts
****************************/
.Toastify__toast-container {
  z-index: @z_above_overlay_level_2;
}

/***************************
         datepicker
****************************/
.DateRangePicker__picker,
.SingleDatePicker__picker {
  z-index: @z_above_overlay_level_2;
}
