/*********************************************************************************************
  Purpose:
  --------
  Contains styles for common/external ui components.
  These are found in the ui components ui folder.

  Notes:
  ------
  Document all elements with comments.
**********************************************************************************************/

/***************************
         Divider
****************************/
.main-divider {
  border: 1px solid @grey_border;
}

.main-divider--dotted {
  border: none;
  border-bottom: 1px dotted @border;
}

.main-divider--top-margin {
  margin-top: 20px;
}

.main-divider--bottom-margin {
  margin-bottom: 20px;
}

.main-divider--bottom-margin-small {
  margin-bottom: 10px;
}

/***********************
      ACloseButton
************************/
.dialog-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 21px;
  height: 21px;
  padding-left: 5px;
  font-size: 12px;
  color: @grey;
  cursor: pointer;
  border: 1px solid @grey_light;
  border-radius: 2px;
}
