.test-text {
  color: @status-card-unavailable;
}

.signup-form {
  margin: 25px;
  color: #fff;

  label {
    font-family: FigtreeBold, sans-serif;
    font-size: 16px;
  font-weight: 600;
    font-weight: normal;
  }

  .text {
    margin-bottom: 25px;
    font-size: 16px;
  }

  input {
    height: 30px;

    @media @xs {
      margin-bottom: 19px;
    }
  }

  .btn {
    width: 100%;
    font-size: 18px;
  }

  .row {
    @media @sm, @md, @lg {
      margin-bottom: 19px;
    }
  }

  .content {
    padding: 0;
    border: none;
  }

  .checkbox label {
    font-family: Figtree, sans-serif;
  }

  .checkbox input {
    margin-right: 12px;
    margin-bottom: 12px;
  }
}

.lc-header-bg {
  @media @lg, @md {
    height: 640px;
    background-image: url('../../../../../web/owp-webclient/assets/images/ra/listCampgrounds/lc-bg.png');
  }

  @media @sm {
    height: 603px;
    background-image: url('../../../../../web/owp-webclient/assets/images/ra/listCampgrounds/lc-bg-768.png');
  }

  @media @xs {
    height: 603px;
    background-image: url('../../../../../web/owp-webclient/assets/images/ra/listCampgrounds/lc-bg-mobi.png');
  }
}

.lc-header-box-content {
  margin-left: 150px;

  @media @xs {
    margin-left: 16px;
  }

  @media @sm {
    margin-left: 125px;
  }
}

.lc-ss-header-bg {
  @media @lg, @md {
    height: 640px;
    background-image: url('../../../../../web/owp-webclient/assets/images/ra/listCampgrounds/lc-ss.png');
  }

  @media @sm {
    height: 603px;
    background-image: url('../../../../../web/owp-webclient/assets/images/ra/listCampgrounds/lc-ss-768.png');
  }

  @media @xs {
    height: 603px;
    background-image: url('../../../../../web/owp-webclient/assets/images/ra/listCampgrounds/lc-ss-mobi.png');
  }

  .lc-header-box-content {
    @media @sm {
      margin-left: 100px;
    }
  }
}

.lc-signup-bg {
  @media @lg, @md {
    height: 768px;
    background-image: url('../../../../../web/owp-webclient/assets/images/ra/listCampgrounds/signup-bg.png');
  }

  @media @sm {
    height: 942px;
    background-image: url('../../../../../web/owp-webclient/assets/images/ra/listCampgrounds/signup-bg-768.png');
  }

  @media @xs {
    height: 1268px;
    background-image: url('../../../../../web/owp-webclient/assets/images/ra/listCampgrounds/signup-bg-mobi.png');
  }
}

.lc-benefits-bg {
  @media @xs {
    height: 969px;
  }

  @media @sm {
    height: 839px;
  }

  @media @lg, @md {
    height: 768px;
  }
}

.lc-header-bg,
.lc-ss-header-bg,
.lc-signup-bg {
  position: relative;
}

.lc-header-box-bg {
  position: absolute;
  top: 58%;
  left: 50%;
  width: 845px;
  height: 387px;
  padding-top: 57px;
  transform: translate(-50%, -50%);

  @media @xs {
    top: 45%;
    width: 100%;
    max-width: 320px;
    height: 445px;
    padding-top: 37px;
  }

  @media @sm {
    top: 50%;
    max-width: 700px;
  }
}

.listcampground-reasons {
  padding-top: 70px;
  padding-bottom: 70px;

  .left {
    @media @xs {
      width: 100%;
      margin-left: 0;
    }
  }

  @media @xs {
    padding-bottom: 5px;
  }
}

.listcampground-reasons.section {
  background-color: #fff;
}

.listcampground-d {
  padding-top: 80px;
  padding-bottom: 50px;
  background-color: @blue_dark !important;
}

.lc-header-box-bg .title,
.listcampground-d .title {
  color: #fff;
}

.lc-header-box-bg .title {
  margin-bottom: 18px !important;
  margin-left: 0 !important;
}

.listcampground-benefits .left,
.listcampground-benefits .right {
  @media @sm {
    width: auto !important;
    margin-left: 50px;
  }
}

.lc-reasons-left-row {
  margin-bottom: 53px;

  @media @xs {
    width: 100%;
    margin-bottom: 30px;
    text-align: center;
  }
}

.lc-reasons-left-row .text {
  margin-left: 30px;
  font-weight: 600;
  color: @blue_dark !important;

  @media @xs {
    display: block;
    margin-top: 10px;
    margin-left: 0;
  }
}

.lc-reasons-right-row,
.lc-fullserve-left-row {
  margin-bottom: 45px;
  color: @blue_dark !important;
}

.lc-selfserve-left-row,
.lc-header-box-bg .text {
  margin-bottom: 50px;
  color: #fff !important;
}

.lc-header-box-bg .text {
  margin-right: 130px;

  @media @xs {
    margin-right: 5px;
  }

  @media @sm {
    margin-right: 95px;
  }
}

.listcampground-d .right {
  margin-left: 100px;

  @media @md {
    margin-left: 20px;

    img {
      width: 398px;
    }
  }
}

.listcampground-w .right {
  @media @md {
    margin-left: 20px;

    img {
      width: 398px;
    }
  }
}

.lc-header-box-bg .btn {
  width: 261px;
  margin-right: 15px;

  @media @xs {
    margin-bottom: 20px;
    margin-left: 15px;
  }

  @media @sm {
    width: 218px;
    height: 50px;
  }
}

.mobi-center {
  text-align: center;
}

.listcampground-d .logo {
  width: 100%;
}

.listcampground-w {
  padding-top: 80px;
  padding-bottom: 50px;
  background-color: #fff !important;

  .left {
    @media @xs {
      margin-top: 20px;
    }
  }
}

.lc-benefits-item {
  margin-bottom: 70px;
  color: #fff !important;

  @media @xs {
    width: 290px;
    margin-bottom: 45px;
  }

  .text {
    overflow: hidden;
  }

  img {
    float: left;
    margin-right: 15px;
  }
}

.listcampground-benefits.selfserve,
.listcampground-benefits.selfserve .lc-benefits-item {
  color: @blue_dark !important;
}

.listcampground-benefits.selfserve {
  padding-top: 80px;
  padding-bottom: 50px;
}

.listcampground-benefits {
  background-color: #fff;

  @media @xs {
    padding-top: 0 !important;
  }

  .left {
    @media @xs {
      margin-top: 45px;
    }
  }

  .right {
    @media @xs {
      margin-top: 0;
    }
  }

  .btn {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }

  .logo img {
    display: block;
    width: auto;
    padding-top: 130px;
    padding-bottom: 100px;
    margin-right: auto;
    margin-left: auto;

    @media @xs {
      display: none;
    }

    @media @sm {
      padding-top: 70px;
      padding-bottom: 70px;
    }
  }
}

.listcampground-home,
.listcampground-ss {
  padding-top: 0;
  padding-bottom: 20px;
  background-image: url('../../../../../web/owp-webclient/assets/images/ra/home-bg.png');
  background-attachment: fixed;

  .title {
    padding-left: 15px;
    margin-bottom: 80px;
    margin-left: 179px;
    font-family: Georgia;
    font-size: 50px;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    color: @blue_dark;
    border-left: 6px solid #ed6e17;

    @media @xs {
      margin-bottom: 24px;
      margin-left: 16px;
    }

    @media @sm, @md {
      margin-left: 50px;
    }
  }

  .text {
    font-family: Figtree, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.1 !important;
    letter-spacing: 1px;
  }

  .bg {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
  }

  .section {
    margin-top: 50px;
    overflow: auto;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%);

    @media @xs {
      padding-top: 21px;
    }
  }

  .left {
    float: left;
    width: 40%;
    margin-left: 179px;

    @media @xs {
      width: auto;
      margin-left: 16px;
    }

    @media @sm, @md {
      width: 50%;
      margin-left: 50px;
    }
  }

  .right {
    float: left;
    width: 40%;

    @media @xs {
      width: auto;
      margin-top: 24px;
      margin-left: 16px;
    }
  }

  .mobi-text {
    width: auto;
    margin-right: 50px;
    margin-left: 50px;
  }

  .btn {
    width: 220px;
  }

  .logo img {
    width: 80%;
  }

  .sbold {
    font-family: FigtreeBold, sans-serif;
  font-weight: 600;
  }
}

.listcampground-d .mobi-center .btn {
  @media @xs {
    margin-left: -16px;
  }
}

.listcampground-signup {
  margin-top: 50px;
  margin-bottom: 30px;

  .bg {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
  }

  .title {
    margin-top: 59px;
    margin-bottom: 59px;
    color: #fff;

    @media @xs {
      margin-top: 38px;
      margin-bottom: 38px;
      line-height: 1.1;
    }

    @media @sm {
      float: left;
      width: 60%;
      margin-top: 50px;
      margin-bottom: 50px;
      line-height: 1.1;
    }
  }

  .mobi-text {
    width: auto;
    margin-right: 50px;
    margin-left: 50px;
  }

  .logo {
    margin-left: 179px;

    @media @sm {
      float: left;
      width: 30%;
      margin-top: 73px;
      margin-left: 0;
    }

    @media @md {
      margin-left: 70px;
    }
  }

  .logo img {
    width: 228px;
  }

  .left {
    margin-left: 0;

    @media @xs {
      width: auto;
      margin-left: 16px;
    }

    @media @md {
      width: 50%;
    }

    @media @sm {
      width: auto;
    }
  }

  .rightPanel {
    position: absolute;
    left: 60%;
    z-index: 1;

    @media @xs {
      top: 230px;
      left: 0%;
    }

    @media @sm {
      top: 170px;
      left: 18%;
    }

    @media @md {
      left: 50%;
    }
  }

  .right {
    width: 498px;
    margin-top: 58px;
    background-image: url('../../../../../web/owp-webclient/assets/images/ra/listCampgrounds/lc-boxbg-signup.png');

    @media @xs {
      width: auto;
      margin-top: 0;
      margin-left: 0;
      background-image: url('../../../../../web/owp-webclient/assets/images/ra/listCampgrounds/lc-boxbg-signup-mobi.png');
    }
  }
}
