/*********************************************************************************************
  Purpose:
  --------
  Contains all styles for HackHTag component.

  Notes:
  ------
  This less file is only for styling the HackHTag component. Add only new styles
  that are related to HackHTag component and nothing else. There are HackHTag styles in other
  less files because we were previousely using the h1-h6 in those places and we replaced
  them with HackHTag.

  Refer to HackHTag definition for the purpose of the component.
**********************************************************************************************/
.hack-h-tag-h1,
.hack-h-tag-h2,
.hack-h-tag-h3,
.hack-h-tag-h4,
.hack-h-tag-h5,
.hack-h-tag-h6 {
  font-family: FigtreeBold, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.hack-h-tag-h1 {
  font-family: FigtreeBold, sans-serif;
  font-size: @font-size-h1;
  font-weight: 700;
}

.hack-h-tag-h2 {
  font-family: Figtree, sans-serif;
  font-size: @font-size-h2;
}

.hack-h-tag-h3 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: Figtree, sans-serif;
  font-size: @font-size-h3;
  color: @blue_dark;
}

.hack-h-tag-h4 {
  font-family: FigtreeBold, sans-serif;
  font-size: @font-size-h4;
  font-weight: 600;
  color: @blue_dark;
}

.hack-h-tag-h5 {
  font-family: FigtreeBold, sans-serif;
  font-size: @font-size-h5;
  font-weight: 600;
  color: @blue_dark;
}

.hack-h-tag-h6 {
  font-family: Figtree, sans-serif;
  font-size: @font-size-h6;
  letter-spacing: 1px;
}

.hack-h-tag-h6.bold {
  font-family: FigtreeBold, sans-serif;
  font-size: @font-size-h6;
  font-weight: 700;
}

/* 
* We are leaving these hack-h-tags here because this was originally part of
* .content-box .content previousely, but the selector was coded incorrectly. 
* All headings from h2-h6 (which become hack-h-tags) has a margin.
*
* Refer to link below for task description.
* https://app.asana.com/0/655445391941326/714987514733489
*/
.hack-h-tag-h2,
.hack-h-tag-h3,
.hack-h-tag-h4,
.hack-h-tag-h5,
.hack-h-tag-h6 {
  margin: 10px 0;
}

.panel-body .hack-h-tag-h3 {
  height: auto;
  padding-top: 14px;
  padding-bottom: 0;
  margin: 0;
  font-family: FigtreeBold, sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: @blue_dark;
  text-transform: uppercase;
  letter-spacing: 3px;
}
