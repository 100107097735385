/***********************
      NSS Message
************************/
.nss-message {
  .nss-icon {
    margin-right: 5px;
  }
}

.nss-header {
  i {
    float: left;
    margin-top: 10px;
    margin-right: 5px;
    margin-bottom: 30px;
  }
}
