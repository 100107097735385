/* login Page Header */
#pageContainer .main-header--overlay {
  background-color: rgb(31 43 52 / 80%);
}

#pageContainer .site-navigation__brand {
  width: 200px;
  height: 50px;
  margin-top: 8px;
  background-image: url('../../../../../web/owp-webclient/assets/images/ra/ra-logo.svg');
  background-repeat: no-repeat;
}

/* Regular Header */
.main-header-wrapper {
  width: 100%;
}

.main-header__brand,
.main-header__search {
  float: left;
}

.main-header__links {
  float: right;
}

.home-return-link {
  float: left;
  padding-left: 10px;
  font-size: 14px;
  color: @white;
  cursor: pointer;
  .transition_ease();
}

.home-return-link:hover {
  color: @orange;
}

/* DO NOT DELETE - This has been changed to accomodate menu add ons
specifically for the Rent Gear icon - we may need to put this back
and it is very hard to figure out the correct settings - so leave 
this as is please. Thanks.
*/
// .main-header__search, .searchbar--inline-search {
//   margin-top:5px;
//   min-width:250px;
//   @media @xs {
//     width:100%;
//   }
//   @media @sm {
//     width:35%;
//   }
//   @media @mdlg {
//     width:40%;
//   }
// }

.main-header__search,
.searchbar--inline-search {
  min-width: 214px;
  margin-top: 5px;

  @media @xs {
    width: 100%;
  }

  @media @sm {
    width: 28%;
  }

  @media @mdlg {
    width: 34%;
  }
}

.main-header__search.home-page {
  display: none;
}

.main-header__search .searchbar {
  position: relative;
  background: transparent;
}

.main-header__brand {
  margin-right: 20px;
  margin-left: 10px;
  cursor: pointer;

  @media @xs {
    width: 75px;
    padding-top: 10px;
    margin-right: 0;
    margin-left: 5px;
  }
}

.main-header--overlay {
  position: fixed;
  top: 0;
  z-index: @z_above_overlay_level_1;
  width: 100%;
  height: @header_height_total;
  margin-bottom: 20px;
  .transition_ease-fast();

  @media @xs {
    height: @header_height_mobile_total;
  }
}

.main-header--overlay.main-header--detached-search {
  @media @xs {
    height: @header_height_mobile_row_1;
  }
}

.main-header--overlay.main-header--detached-search.broadcast-message {
  @media @xs {
    height: @header_height_mobile_row_1 + @header_height_broadcast_message;
  }
}

.header-right-links div {
  display: inline;
}

.topbar-navigation__row-1 {
  position: relative;
  z-index: @z_topbar-navigation_row-1;
  background-color: @blue_dark-background-ninety;
  .transition_ease();

  @media @smmdlg {
    height: @header_height_row_1;
    padding: 8px @right-page-margin 7px 0;
    font-size: 12px;
  }

  @media @xs {
    height: @header_height_mobile_row_1;
    padding: 5px;
    font-size: 10px;
  }
}

.topbar-navigation__row-2 {
  .transition_ease();

  z-index: @z_topbar-navigation_row-2;
  padding: 5px;
  background-color: @blue-dark-background-eighty;

  @media @smmdlg {
    height: @header_height_row_2;
    font-size: 12px;
  }

  @media @xs {
    height: @header_height_mobile_row_2;
    padding: 0 15px;
    font-size: 10px;
  }
}

.topbar-navigation a,
.topbar-navigation a:visited {
  color: @link-reverse-color;
  text-decoration: none;
}

.topbar-navigation span:focus,
.topbar-navigation a:focus {
  color: @orange;
}

.topbar-navigation a:hover {
  color: @orange;
  text-decoration: none;
}

.header-v-separator {
  display: inline;
  width: 1px;
  height: 10px;
  margin-top: 4px;
  margin-right: 10px;
  margin-left: 10px;
  color: @grey_light;
  border-right: 1px solid #fff;
}

.active-advantage {
  height: 20px;
  margin-right: 5px;
}

.main-header {
  .transition_easeSlow();
}

/**********************
    HEADER BURGER
***********************/
.site-navigation-menu .home-return-link {
  display: inline-block;
  float: right;
  margin-right: 5px;
  margin-bottom: 20px;
  clear: both;
  font-size: 15px;
  color: @link-color;

  @media @lg {
    display: none;
  }
}

.site-navigation-menu .home-return-link:hover {
  color: @link-color-hover;
}

.site-navigation-menu__button {
  position: absolute;
  top: 0;
  right: 10px;
  z-index: @z_above_overlay_level_2;
  width: 40px;
  padding: 0;
  margin: 0;
  font-size: 36px;
  color: @white;
  background-color: transparent;
  border: 0;
}

.site-navigation-menu__button {
  cursor: pointer;
}

.site-navigation-menu--active .site-navigation-menu__button {
  color: @white;
}

.site-navigation-menu__button:hover,
.site-navigation-menu__button:focus {
  color: @orange;
}

.site-navigation-menu--active .site-navigation__links a,
.site-navigation-menu--inactive .site-navigation__links a {
  color: @white;
}

.site-navigation-menu--active .site-navigation__links a:hover,
.site-navigation-menu--inactive .site-navigation__links a:hover {
  height: 20px;
  border-bottom: 4px solid @orange;
}

.site-navigation-menu--active .site-navigation__links,
.site-navigation-menu--inactive .site-navigation__links {
  padding-top: 0;
  padding-bottom: 20px;
}

.site-navigation-menu--active .site-navigation__link,
.site-navigation-menu--inactive .site-navigation__link {
  float: right;
  width: 200px;
  height: 40px;
  margin-top: 20px;
  clear: both;
  font-family: FigtreeBold, sans-serif;
  font-weight: 700;
  text-align: right;
  border-bottom: 1px solid @blue_dark;
}

.site-navigation-menu--active .site-navigation__link.first,
.site-navigation-menu--inactive .site-navigation__link.first {
  height: 60px;
  padding-top: 20px;
  border-top: 1px solid @blue_dark;
}

.site-navigation-menu--active .site-navigation__links a,
.site-navigation-menu--inactive .site-navigation__links a {
  display: inline;
}

.site-navigation-menu.site-navigation-menu--inactive
  .site-navigation-menu__main-menu {
  right: 0;
  width: 0;
  overflow: hidden;
  opacity: 0;
}

.site-navigation-menu.site-navigation-menu--active
  .site-navigation-menu__main-menu {
  right: 0;
  width: 100%;
  padding: 50px 15px 15px;
  overflow: hidden;
  color: @white;
  background-color: rgb(31 43 52 / 90%);
  opacity: 1;
}

.site-navigation-menu__main-menu .header-cart-summary {
  float: none;
  padding-right: 5px;
  clear: both;
}

.site-navigation-menu .site-navigation-menu__main-menu {
  position: absolute;
  top: 0;
  z-index: @z_above_overlay_level_1;
  background-color: @white;
  .transition_cubic-bezier();
}

.site-navigation-menu--inactive .site-navigation-menu__main-menu {
  display: none;
}

/**********************
    HEADER MESSAGE
***********************/
.header-message {
  position: relative;
  height: 0;
}

.header-message.header-message--active {
  height: auto;
  padding: 10px;
  color: @black;
  background-color: @yellow;
}

.header-message__notification {
  @media @xssm {
    padding-right: 4px;
    padding-left: 4px;
    font-size: 12px;
    line-height: 13px;
  }

  @media @lg {
    max-width: 960px;
  }

  @media @md {
    max-width: 768px;
  }

  position: relative;
  padding: 2px 0;
  margin: 0 auto;
  line-height: 16px;
  color: @black;
}

.header-message-toggle--active,
.header-message-toggle {
  position: absolute;
  z-index: @z_below_overlay_base;
  width: 20px;
  height: 20px;
  padding-top: 2px;
  font-size: 12px;
  color: @black;
  text-align: center;
  background-color: @yellow;

  @media @lg {
    right: 0;
  }

  @media @md {
    left: 0;
  }

  @media @sm {
    left: 0;
  }

  @media @xs {
    display: none;
  }
}

.header-message-toggle.header-message-toggle--active {
  top: auto;
  bottom: -20px;
}

.header-message-toggle {
  top: 0;
  bottom: auto;
}

/**********************
     HEADER MESSAGE BAR SECONDARY
***********************/
.header-message-bar-secondary-pos {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: @z_above_overlay_level_1;
  width: 100%;
}

.header-message-bar-secondary-txt {
  @media @lg {
    max-width: 960px;
  }

  @media @md {
    max-width: 768px;
  }

  @media @mdlg {
    font-size: 15px;
    line-height: 17px;
  }

  @media @sm {
    font-size: 14px;
    line-height: 16px;
  }

  @media @xs {
    font-size: 12px;
    line-height: 14px;
  }

  position: relative;
  padding: 2px 0;
  margin: 0 auto;
}

/**********************
     SEARCH HEADER
***********************/
.main-header--overlay .searchbar input,
.main-header--overlay .searchbar input:focus,
.main-header--overlay .searchbar input:active {
  outline: none !important;
}

.main-header--overlay .searchbar__results.searchbar__results--active,
.searchbar--inline-search .searchbar__results.searchbar__results--active {
  margin-left: 0;
  border: 1px solid @blue_dark;
}

.main-header--overlay .searchbar__controls,
.searchbar--inline-search .searchbar__controls {
  background: none;
}

.main-header--overlay .searchbar__input,
.searchbar--inline-search .searchbar__input {
  width: 100%;
  height: 35px;
  padding-top: 0;
}

.main-header--overlay .searchbar__button,
.searchbar--inline-search .searchbar__button {
  display: table-cell;
  padding: 0;
}

.main-header--overlay .searchbar__button button,
.searchbar--inline-search .searchbar__button button {
  float: left;
  width: 40px;
  height: 40px;
  font-size: 12px;
  color: rgb(255 255 255);
  background-color: rgb(237 110 23);
  border: 0;
  border-radius: 2px;
  border-radius: 0 5px 5px 0 !important;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  outline: none;
  .square(35px);
}

.main-header--overlay .searchbar,
.searchbar--inline-search .searchbar {
  left: 0;
  width: 100%;
  padding: 0;
  margin: 0;
}

.searchbar--inline-search .searchbar {
  background: transparent;

  @media @xs {
    padding: 0 15px;
  }
}

.searchbar--inline-search {
  position: fixed;
  top: 40px;
  left: 160px;
  z-index: @z_topbar-navigation_row-2;
  margin-top: 0;

  @media @xs {
    top: 50px;
    left: 0;
    z-index: @z_above_overlay;
    height: @header_height_mobile_row_2;
    padding-top: 5px;
    background-color: @blue-dark-background-eighty;
  }
}

.main-header__search .searchbar__input input,
.searchbar--inline-search .searchbar__input input {
  height: 35px;
}

.searchbar--inline-search .searchbar__results {
  position: relative;
}

.main-header--overlay .searchbar__input,
.searchbar--inline-search .main-header--overlay .searchbar__input {
  display: table-cell;
  float: left;
  height: 35px;
  color: @blue_dark;
  background-color: @white;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  outline: none;
}

.main-header--overlay .searchbar__input .form-group input,
.searchbar--inline-search .searchbar__input .form-group input {
  .transition_cubic-bezier();

  height: 35px;
  outline: none;
}

.main-header--overlay .searchbar__input .form-group input:focus,
.searchbar--inline-search .searchbar__input .form-group input:focus {
  color: @white;
  outline: none;
}

.main-header--overlay .searchbar__input .form-group input.form-control,
.searchbar--inline-search .searchbar__input .form-group input.form-control {
  font-family: Georgia;
  color: @mute-white;
  color: @blue_dark;
}

.main-header--overlay .searchbar__input .form-group input.form-control:focus {
  color: @blue_dark;
}

.main-header--overlay .searchbar__input .form-group input {
  width: 100%;
  outline: none;
}

.main-header--overlay .searchbar__input .form-group input:focus {
  outline: none;
}

.main-header--overlay.main-header--inline-search {
  position: relative;
  margin-bottom: 0;
}
