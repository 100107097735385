.reservation-list .reservation-summary:last-child .main-divider {
  border-bottom: none;
}

.reservation-list {
  &__spinner {
    left: 0;
  }
}

.reservation-details__facility-image-container,
.reservation-summary__facility-image-container,
.rate-reservation__facility-image-container {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  @media @xs {
    height: 120px;
    margin-bottom: 20px;
  }

  @media @sm {
    height: 80px;
  }

  @media @mdlg {
    height: 120px;
  }
}
