@import (less)
  '../../../../../node_modules/intl-tel-input/build/css/intlTelInput.css';

.chub-tel-input {
  .iti {
    width: 100%;
  }

  .iti__flag {
    background-image: url('https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/18.2.1/img/flags.png');
  }

  @media (resolution >= 2dppx), (resolution >= 192dpi) {
    .iti__flag {
      background-image: url('https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/18.2.1/img/flags@2x.png');
    }
  }
}
