@import '../variables.less';

/***********************
    SITE GRID FILTERS
************************/

.site-filters-list {
  padding-top: 10px;
  padding-bottom: 10px;
}

.filter-text--all,
.filter-text {
  display: inline-block;
  float: left;
  padding: 4px 10px;
  margin: 4px 10px 4px 0;
  font-size: 13px;
  color: @blue_dark;
  cursor: pointer;
  border: 1px solid @blue_dark;
  .border-radius(4px);
}

.filter-qty,
.filter-qty--all {
  display: inline-block;
  height: 20px;
  padding-left: 4px;
  font-size: 13px;
  color: @blue_dark;
  text-align: center;

  @media @xssm {
    float: right;
    margin-right: 0;
  }

  @media @mdlg {
    /* margin-right:20px; */
  }
}

.filter-text--all.active,
.filter-text.active {
  color: @orange;
  border: 1px solid @orange;
}

.filter-text.active .filter-qty,
.filter-text--all.active .filter-qty--all {
  color: @orange;
}

/***********************
    CALENDAR LEGEND
************************/

.calendar-legend {
  margin-top: 20px;
  margin-bottom: @gutter;
  clear: both;
}

.calendar-legend__item {
  float: left;
  width: 80px;
  text-align: center;

  @media @xssm {
    width: 65px;
  }

  @media @mdlg {
    width: 80px;
  }
}

.calendar-legend-block {
  margin: 0 auto;
  color: @status-foreground;
  cursor: pointer;
  background-color: blue;

  .transition_ease();

  @media @xssm {
    width: 30px;
    height: 30px;
    padding-top: 6px;
  }

  @media @mdlg {
    width: 40px;
    height: 40px;
    padding-top: 12px;
  }
}

.calendar-legend__item-label {
  margin-top: 6px;
  margin-bottom: 10px;
  font-family: Figtree, sans-serif;

  @media @xssm {
    font-size: @font-size-extra-small;
  }
}

.calendar-legend-block--selected {
  background-color: @status-selected;
}

.calendar-legend-block--selected:hover {
  background-color: @status-selected-hover;
}

.calendar-legend-block--available {
  background-color: @status-available;
}

.calendar-legend-block--available:hover {
  background-color: @status-available-hover;
}

.calendar-legend-block--walkup {
  color: @status-foreground-reversed;
  background-color: @status-walkin;
}

.calendar-legend-block--walkup:hover {
  background-color: @status-walkin-hover;
}

.calendar-legend-block--call-the-park {
  color: @status-foreground-reversed;
  background-color: @status-walkin;
}

.calendar-legend-block--call-the-park:hover {
  background-color: @status-walkin-hover;
}

.calendar-legend-block--reserved {
  color: @status-foreground-reversed;
  background-color: @status-reserved;
}

.calendar-legend-block--reserved:hover {
  background-color: @status-reserved-hover;
}

.calendar-legend-block--not-available {
  color: @status-foreground-reversed;
  background-color: @status-unavailable;
}

.calendar-legend-block--not-available:hover {
  background-color: @status-unavailable-hover;
}

/***********************
      SITE GRID
************************/

.grid-cell,
.grid-cell--bold {
  font-family: Figtree, sans-serif;
  font-size: @font-size-h6;
}

.grid-cell--bold {
  color: @blue_dark;
}

.table > tbody > tr > td,
.table > thead > tr > th {
  padding: 0;
}

.table > thead > tr > th {
  padding-top: 8px;
  padding-bottom: 8px;
}

.product-details-calendar.hourly tbody td {
  border: 1px solid #c4c4c4;
}

.site-list-results .table > thead > tr > th,
.site-list-results .table > tbody > tr > th,
.site-list-results .table > tfoot > tr > th,
.site-list-results .table > thead > tr > td,
.site-list-results .table > tbody > tr > td,
.site-list-results .table > tfoot > tr > td {
  vertical-align: middle;
}

.table-responsive {
  border: 0;
}

.availability-cell {
  position: relative;
  height: 60px;
  font-family: Figtree, sans-serif;
  text-align: center;
  .transition_easeSlow();

  @media @xssm {
    min-width: 40px;
  }

  & a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}

div.availability-cell .bg-available,
div.availability-cell .bg-not-available,
div.availability-cell .bg-walkup,
div.availability-cell .bg-call-the-park,
div.availability-cell .bg-reserved,
div.availability-cell .bg-selected,
div.availability-cell .bg-selected-disable {
  padding: 5px;
  margin-top: 50%;
  margin-right: 2px;
  margin-left: 2px;
  border: 1px solid #eee;
  border-radius: 4px;
  transition:
    all ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  transition:
    all ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  transition:
    all ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
}

.availability-cell__hover-trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 60px;
  padding-top: 50%;
}

.weekend-bg,
.weekday-bg,
.availability-cell {
  width: 40px;
}

.product-details-calendar .availability-cell__hover-trigger {
  padding-top: 40%;
}

.product-details-calendar.hourly {
  overflow-x: auto;
}

.product-details-calendar.hourly table {
  border-spacing: 2px;
}

.create-avail-notif .table > tbody > tr > td {
  padding: 6px;
}

.availability-cell.bg-not-available {
  color: @status-foreground-reversed !important;
  cursor: pointer;
  background-color: @status-unavailable;
}

.availability-cell.bg-not-available:hover {
  color: @status-foreground-reversed !important;
  background-color: @status-unavailable-hover;
}

.availability-cell.bg-not-available .base-link {
  color: @status-foreground-reversed !important;
}

.availability-cell.bg-not-available:hover .base-link {
  color: @status-foreground-reversed !important;
}

.availability-cell.bg-available {
  color: @status-foreground !important;
  cursor: pointer;
  background-color: @status-available;
}

.availability-cell.bg-available:hover {
  color: @white !important;
  background-color: @status-available-hover;
}

.availability-cell.bg-selected {
  color: @white !important;
  background-color: @status-selected;
}

.availability-cell.bg-walkup {
  color: @status-foreground-reversed;
  background-color: @status-walkin;
}

.availability-cell.bg-walkup:hover {
  background-color: @status-walkin-hover;
}

.availability-cell.bg-call-the-park {
  color: @status-foreground-reversed;
  background-color: @status-walkin;
}

.availability-cell.bg-call-the-park:hover {
  background-color: @status-walkin-hover;
}

.availability-cell.bg-reserved {
  color: @status-foreground-reversed;
  cursor: not-allowed;
  background-color: @status-reserved;
}

.availability-cell.bg-reserved:hover {
  background-color: @status-reserved-hover;
}

.availability-cell.bg-selected-disable {
  background-color: #f0ad4d;
}

.availability-cell .available,
.availability-cell .not-available,
.availability-cell .walkup,
.availability-cell .call-the-park,
.availability-cell .reserved {
  font-family: FigtreeLight, sans-serif;
  font-size: 14px;
  color: @status-foreground;
  text-align: center;
}

.availability-cell .walkup,
.availability-cell .call-the-park,
.availability-cell .reserved {
  color: @status-foreground-reversed;
}

.availability-cell .not-available {
  color: @status-foreground;
}

div.availability-cell .available {
  color: #fff;
}

.product-details-calendar.hourly .availability-cell {
  vertical-align: middle;
}

.avail-cell-check::before {
  color: @white;
}

.table colgroup col.weekend-bg,
.weekend-cell {
  border-right: 1px solid @border;
  border-left: 1px solid @border;
}

.table colgroup col.weekday-bg,
.weekday-cell {
  border-left: 1px solid #f2f2f2;
}

.calendar__day-of-week {
  font-family: FigtreeBold, sans-serif;
  font-weight: 600;
  color: @blue_dark;
}

.table-head--center,
.table-cell--center {
  text-align: center;
}

.days-header {
  text-align: center;
  background-color: #f3f3f3;
  border-right: 1px solid #fff;
}

.days-header.weekend-bg,
.weekend-bg {
  color: @white;
  background-color: #b3bdc1;
}

.grid-hour {
  width: 65px;
  padding-right: 26px !important;
  font-weight: 700;
}

.site-list-results .table {
  border-top: 1px solid @border;
}

.weekend-bg,
.weekday-bg {
  padding-top: 10px;
  padding-bottom: 10px;
}

.weekday-bg {
  text-align: center;
  background-color: #fff;
}

.weekend-bg {
  text-align: center;
}

.product-icon-cell {
  margin-right: 10px;

  /* text-align:center; */
  cursor: pointer;
}

.product-link,
.product-link:active,
.product-link:visited {
  color: @link-color;
  cursor: pointer;
}

.product-link:hover {
  color: @link-color-hover;
}

.product-icon {
  width: 28px;
  height: 32px;
  margin: 8px auto 0;
  text-align: left;
}

.product-icon span.label {
  position: absolute;
  bottom: -1px;
  left: 0;
  font-size: 7pt;
}

.product-number {
  font-family: Figtree, sans-serif;
  font-size: 12px;
  color: @link-color;
  text-align: center;
}

a.sitemarker {
  position: absolute;
  position: relative;
  display: block;
  width: 28px;
  font-size: 9px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: @link-color;
  background-repeat: no-repeat;
  border-radius: 4px;
}

a.sitemarker.unavailable {
  background-color: @map-icon-not-available;
}

/***********************
       SITE LIST
************************/
.calendar-list-header {
  position: relative;
}

.date-pager {
  height: 50px;
  margin-top: 24px;
  text-align: center;
  border-bottom: 1px solid @border;
}

.calendar-list-header .date-pager {
  @media @xssm {
    display: none;
  }
}

.calendar-list-selector {
  /* position: absolute;
  right: 0;
  top: 8px; */
}

.calendar-list-selector .simple-selector {
  margin-top: 0;
}

.facility-mobile-datepager .date-pager {
  margin-top: 0;
  border-bottom: none;
}

.date-pager__back,
.date-pager__forward {
  font-size: @font-size-h1;
  color: @blue_light;
  cursor: pointer;
}

.date-pager__back {
  padding-right: 30px;
}

.date-pager__forward {
  padding-left: 30px;
}

.date-pager__back-disabled {
  color: @grey_light;
}

.date-pager__back:hover,
.date-pager__forward:hover {
  color: @blue_light-hover;
}

.date-pager__date {
  font-family: FigtreeBold, sans-serif;
  font-weight: 600;

  @media @xssm {
    font-size: @font-size-h5;
  }

  @media @mdlg {
    font-size: @font-size-h1;
  }
}

.paging-control {
  float: right;
  margin-bottom: 10px;
}

.paging-control__directions {
  display: inline-block;
}

.paging-control__records {
  display: inline-block;
  margin-right: 70px;

  @media @xssm {
    display: none;
  }
}

.paging-control__direction-button {
  opacity: 0.6;
}

.paging-control__direction-button--active {
  color: @link-color;
  .transition_ease();

  cursor: pointer;
  opacity: 1;
}

.paging-control__direction-button--active:hover {
  color: @link-color-hover;
}

.product-title {
  margin-bottom: 20px;

  @media @xssm {
    font-size: @font-size-h5;
  }

  @media @mdlg {
    font-size: @font-size-h4;
  }
}

.product-title__group {
  font-size: @font-size-h6;
}

.product-hover-image {
  width: 200px;
  height: 138px;
  margin-bottom: 10px;
  background-position: center center;
  background-size: cover;
}

.table-data__occupant-info .ada-accessible-icon {
  margin: 0 auto;
}
