@import '../../../../../less/external/variables.less';

.chub-summary {
  padding: 20px 5px;
  color: @blue_dark;
  text-align: center;
  border: 1px solid @grey_border;
  border-radius: 2px;

  &__heading {
    margin-bottom: 30px;
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  &__park-name {
    padding-left: 30px;
    margin-bottom: 10px;
    font-size: 18px;
    text-align: left;
  }

  &__products {
    padding: 5px 30px;
    margin: 0;
    text-align: left;
    list-style: none;
  }

  &__product {
    display: flex;
    flex-wrap: wrap;
    gap: 0 5px;
    justify-content: space-between;
    margin: 0;
    margin-bottom: 10px;
    line-height: 1.1;
  }

  &__product-heading {
    flex-basis: 50%;
    flex-grow: 1;
  }

  &__product-info {
    flex-basis: 50%;
    flex-grow: 1;
    font-size: 12px;
    color: @grey;
  }

  &__product-tax {
    font-size: 12px;
    font-style: italic;
  }

  &__divider {
    margin: 10px 0;
    border-bottom: 1px dotted @grey_border;
  }

  &__totals-table {
    box-sizing: border-box;
    width: 100%;
    max-width: 360px;
    padding: 0 30px;
    margin-left: auto;

    td {
      width: 50%;
      text-align: right;
    }
  }

  &__total-row td {
    font-family: Figtree, sans-serif;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__due-price {
    padding: 10px;
    margin: 10px 30px;
    font-size: 18px;
    color: @white;
    text-align: center;
    text-transform: uppercase;
    background-color: @blue_dark;
    border-radius: 2px;
  }

  &__fee-price {
    margin-top: 15px;
    font-size: 12px;
  }

  &__back-link {
    display: block;
    margin: 15px auto 0;
    font-size: 12px;
    color: @grey;

    &:hover {
      text-decoration: underline;
    }

    &:link,
    &:visited,
    &:active {
      color: @grey;
    }
  }

  & .accordion {
    padding: 14px 30px 0;
    text-align: start;
  }
}
