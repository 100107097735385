.expandable-search-panel .inline-searchbar-logo.active {
  @media @xs {
    display: none;
  }
}

.inline-searchbar-trigger.active {
  width: 0;
}

.inline-searchbar-trigger.active .searchbar__button,
.inline-searchbar-trigger.active .searchbar__button button {
  display: none;
}

.inline-searchbar-trigger.inactive {
  width: 40px;
}

.expandable-search-panel.active {
  position: absolute;
  z-index: @z_below_overlay_above_base_level_2;
  display: table;

  @media @xs {
    margin-left: 15px;
  }
}

.inline-searchbar-trigger,
.inline-searchbar-logo,
.iinline-searchbar__expand-content,
.inline-searchbar-close-trigger {
  display: table-cell;
}

.inline-searchbar__expand-content.inactive {
  width: 0;
  opacity: 0;
}

.inline-searchbar__expand-content.active {
  opacity: 1;

  @media @xssm {
    width: 180px;
  }

  @media @md {
    width: 400px;
  }

  @media @lg {
    width: 660px;
  }
}

.inline-searchbar-close-trigger.inactive {
  opacity: 0;
}

.inline-searchbar-close-trigger.active {
  display: table-cell;
  opacity: 1;
}

.inline-searchbar-trigger.inactive {
  opacity: 1;
}

.inline-searchbar-trigger.active {
  display: table-cell;
  opacity: 0;
}

.inline-searchbar__expand-content {
  .transition_ease();
}

.inline-searchbar__expand-content.active .searchbar {
  position: relative;
  width: 100%;
  margin-left: 0;
}

.inline-searchbar__expand-content.inactive .searchbar {
  width: 0;
}

.inline-searchbar-close-trigger.inactive {
  display: none;
}

//CMP EXTERNALIZATION - start

/* searchBarCmp.js */
.searchbar {
  .blueBorder();

  position: absolute;
  z-index: @z_below_overlay_base;
  display: table;
  margin: 250px auto;
  vertical-align: middle;
  transition: width 0.3s ease 0s;
  .clr;

  @media @xssm {
    width: 100%;
    padding-right: @gutter;
    padding-left: @gutter;
  }

  @media @md {
    left: 50%;
    width: 800px;
    margin-left: -400px;
  }

  @media @lg {
    left: 50%;
    width: 868px;
    margin-left: -434px;
  }
}

.searchbar__input,
.searchbar__button {
  display: table-cell;
}

.searchbar__button {
  padding: 10px;
}

.searchbar__button,
.searchbar__button button {
  .square(34px);
  .border-radius(3px);

  vertical-align: top;
}

.searchbar__button button {
  padding: 0;
}

.searchbar__input input {
  width: 100%;
  height: 54px;
  font-family: Georgia;
  font-size: 14px;
  color: @blue_dark;
  vertical-align: top;
  border: 0;
  border-left: 5px solid @orange;
  border-radius: 0;

  @media @xs {
    font-size: 12px;
  }
}

.searchbar__controls {
  position: relative;
  display: table;
  width: 100%;
  background-color: @white;
}

.searchbar__results {
  position: absolute;
  display: none;
  width: 100%;
  .transition_opacity();

  @media @xssm {
    margin-left: -20px;
  }

  @media @mdlg {
    margin-left: -9px;
  }
}

.searchbar__results.searchbar__results--active {
  display: block;
  background-color: @blue-dark-background-eighty;
  border: 9px solid @blue_dark;
}

.searchbar__results .searchResults,
.searchbar__results .searchResults.active {
  max-height: 290px;
  padding: 8px;
  padding-left: 0;
  overflow: hidden;
  overflow-y: scroll;
  font-size: 14px;
}

.searchbar__results .searchResult {
  .selector();
}

.searchbar__results .searchResult:hover,
.searchbar__results .searchResult.active {
  .selector-hover();
}

.search-result-highlight {
  .selector-highlight();
}

.searchbar__results .searchResult:hover .search-result-highlight,
.searchbar__results .searchResult.active .search-result-highlight {
  .selector-highlight-hover();
}

.search-result-section--web.google {
  display: block;
  width: 100%;
  height: 24px;
  margin-top: 5px;
  content: '';
  background-image: url('../../../../../web/owp-webclient/assets/external/google_power.png');
  background-repeat: no-repeat;
  background-position: 10px 0;
}

.search-result-section--web.mapbox {
  height: 0;
  margin-top: 0;
  background-image: none;
}

.search-result-attrib {
  padding-left: 20px;
}

.search-suggestions.search-result-section--web {
  background-position: 0 0;
}

.searchResult::before {
  position: absolute;
  top: 10px;
  left: 14px;
  width: 36px;
  height: 24px;
  font-family: FontAwesome;
  font-size: 13px;
  content: '\f041';
  background: transparent;
}

.searchResult.web::before {
  color: @white;
  content: '\f041';
}

.searchResult.internal::before {
  color: @white;
  content: '\f1bb';
}

/* contract is a state */
.searchResult.contract::before {
  color: @white;
}

.searchbar__input .form-control:focus {
  border-color: @orange;
}
//CMP EXTERNALIZATION - end
