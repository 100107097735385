/***********************************
      CHARTS
***********************************/
.Charts {
  display: flex;
  align-items: flex-end;
  margin: 0 auto;

  &.horizontal {
    display: block;
    margin-bottom: 0;
    overflow: hidden;
  }

  &.horizontal &--serie {
    display: block;
    padding-top: 30px;
    margin-top: 10px;
    margin-right: 0;
    margin-bottom: 0;
    border: 0;
    animation: none;

    .Charts--inner {
      height: auto;
    }

    label {
      padding-left: 110px;
      text-align: left;

      @media @xs {
        padding-left: 70px;
      }
    }

    .Charts--wrapper {
      display: flex;
      margin-bottom: 10px;
    }

    .Charts--background {
      width: 100%;
      border-radius: 2px;
    }

    .Charts--item {
      flex-grow: 1;
      padding: 0;
      margin: 0;
      border-radius: 2px;
      transform-origin: left;
      animation: 1s slideIn ease-out;
      animation-delay: 0.1s;
    }

    .Charts--label {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      min-width: 100px;
      margin-right: 10px;

      @media @xs {
        min-width: 60px;
      }

      span {
        text-align: right;
      }
    }

    .Charts--value {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      min-width: 100px;
      margin-left: 10px;

      @media @xs {
        min-width: 60px;
      }

      span {
        text-align: left;
      }
    }
  }

  &.horizontal .size--1x {
    height: 1em;
  }

  &.horizontal .size--2x {
    height: 2em;
  }

  &.horizontal .size--3x {
    height: 3em;
  }

  &.horizontal .size--4x {
    height: 4em;
  }

  &--serie {
    position: relative;
    display: inline-block;
    display: flex;
    flex: 1;
    align-items: flex-end;
    height: 100%;
    margin-right: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid @grey_border;
    transform-origin: 0 100%;
    animation: slideUp 1s ease-out;

    &.vertical {
      padding-top: 30px;
      animation: none;

      .Charts--inner {
        display: flex;
        flex: 1;
        height: 100%;
      }

      .Charts--wrapper {
        position: relative;
        display: flex;
        flex: 1;
        flex-direction: column;
        height: 100%;
        margin-right: 10px;
      }

      .Charts--background {
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 2px 2px 0 0;
      }

      .Charts--item {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        border-radius: 2px 2px 0 0;
        transform-origin: bottom;
        animation: slideUp 1s ease-out;
      }

      .Charts--label {
        position: absolute;
        bottom: -20px;
        width: 100%;
        text-align: center;
      }

      .Charts--value {
        position: relative;
        top: -20px;
        display: block;
        text-align: center;
      }
    }

    &.stacked {
      display: block;
      padding-top: 30px;
      margin-bottom: 0;

      .Charts--inner {
        display: flex;
        align-items: center;
        justify-content: center;

        .Charts--label {
          display: none;
        }
      }
    }

    &.layered {
      margin-top: 30px;
      animation: none;

      .default-header {
        label {
          top: -20px;
        }
      }

      .Charts--inner {
        position: absolute;
        right: 5%;
        bottom: 0;
        left: 5%;
        display: block;
        margin: 0;
        transform-origin: bottom;
        animation: slideUp 1s ease-out;

        div:first-child {
          height: 100%;
        }

        .Charts--value {
          position: absolute;
          top: -20px;
          right: 10px;

          @media @xs {
            right: -5px;
          }

          span {
            display: block;

            @media @xs {
              width: 50px;
              text-align: center;
            }
          }
        }

        .Charts--label {
          position: absolute;
          right: 10px;
          bottom: -20px;

          @media @xs {
            right: 0;
          }

          span {
            text-align: center;

            @media @xs {
              width: 50px;
              margin-top: 5px;
            }
          }
        }
      }
    }

    label {
      position: absolute;
      top: 0;
      right: -30px;
      left: 0;
      font-family: Helvetica, sans-serif;
      font-size: 14px;
      color: #000;
      text-align: center;
    }
  }

  &--item {
    flex: 1;
    transition:
      height 1s ease-out,
      width 1s ease-out;
  }

  .Charts--value {
    line-height: 1;

    span {
      display: block;
    }
  }

  .Charts--label {
    line-height: 1;

    span {
      display: block;
    }
  }
}

.Legend {
  &--color {
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: middle;
    border-radius: 2px;
  }

  &--label {
    display: inline-block;
    margin: 0 0 0 5px;
    font-family: Helvetica, sans-serif;
    font-size: 12px;
    vertical-align: middle;
  }
}

@keyframes slideUp {
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
  }
}

@keyframes slideIn {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}
