.chub-spot-gallery {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  &__backdrop {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 30%);
  }

  &__img-wrapper {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    max-width: 800px;
    height: 100%;
    max-height: 600px;
  }

  &__loader {
    position: absolute;
    width: 100%;
    text-align: center;
    visibility: hidden;
    opacity: 0;

    &--prev {
      transform: translateX(-20%);
    }

    &--next {
      transform: translateX(20%);
    }

    &--active {
      position: static;
      visibility: visible;
      opacity: 1;
      transition-duration: 0.2s;
      transition-property: opacity, transform;
      transform: translateX(0);
    }
  }

  &__image {
    object-fit: contain;
    max-width: 100%;
    height: auto;
    max-height: 100%;
  }

  &__close-btn,
  &__prev-btn,
  &__next-btn {
    z-index: 2;
    font-size: 2em;
    opacity: 0.5;
  }

  &__close-btn {
    right: 0;
    bottom: 100%;
    padding: 0;
    margin-top: -40px;
    margin-bottom: 5px;
    color: white;
    background-color: transparent;

    &:hover {
      color: white;
      opacity: 1;
    }

    &::before {
      content: '\f00d';
    }
  }

  &__prev-btn {
    left: 0;
    padding-left: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &__next-btn {
    right: 0;
    padding-right: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &__prev-btn,
  &__next-btn {
    position: absolute;
    top: 50%;
    padding: 15px 10px;
    transform: translateY(-50%);

    &:hover {
      opacity: 1;
    }

    &:focus {
      outline: none;
    }
  }
}
