/***********************
        SeansonFeature
************************/
.seasonfeature-content {
  margin: 20px 0;
  overflow-x: hidden;

  .seasonfeature-bg {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;

    @media @lg {
      height: 640px;
    }

    @media @md {
      height: 460px;
    }

    @media @sm {
      height: 400px;
    }

    @media @xs {
      background-image: none !important;
    }
  }

  .seasonfeature-container {
    position: relative;
    width: 100%;
    max-width: 1315px;
    padding: 0 15px;
    margin: 0 auto;
    overflow: hidden;

    .btn-next {
      position: absolute;
      top: 219px;
      right: 20px;
      z-index: 2;
      width: 50px;
      height: 50px;
      cursor: pointer;
      background-color: rgb(0 0 0 / 50%);
      background-image: url('../../../../../web/owp-webclient/assets/images/ra/btn-next.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 18px 31px;
      .border-radius(50%);
    }

    .btn-prev {
      position: absolute;
      top: 219px;
      left: 20px;
      z-index: 2;
      width: 50px;
      height: 50px;
      cursor: pointer;
      background-color: rgb(0 0 0 / 50%);
      background-image: url('../../../../../web/owp-webclient/assets/images/ra/btn-prev.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 18px 31px;
      .border-radius(50%);
    }

    @media @lg {
      margin-top: -155px;
    }

    @media @md {
      margin-top: 0;
    }

    @media @sm {
      margin-top: -100px;
    }

    @media @xs {
      padding: 0;
      margin-top: 0;

      .btn-prev {
        display: none !important;
      }

      .btn-next {
        display: none !important;
      }
    }
  }

  .seasonfeature-container.notShowSilde {
    @media @lg {
      .btn-next {
        display: none;
      }

      .btn-prev {
        display: none;
      }
    }

    @media @md {
      padding: 0;

      .seasonfeature-article-wrapper {
        width: 100% !important;
        overflow: hidden;

        .seasonfeature-article {
          width: 50%;
          margin-right: 0;

          &:first-child {
            .seasonfeature-article-link {
              border-right: 1px solid #aab3b8;
            }
          }
        }
      }

      .btn-next {
        display: none;
      }

      .btn-prev {
        display: none;
      }
    }

    @media @sm {
      .seasonfeature-article-wrapper {
        .seasonfeature-article:nth-child(2) {
          margin-right: 0;
        }
      }
    }
  }
}

.seasonfeature-article-wrapper {
  position: relative;
  height: 495px;
  overflow: hidden;
  .transition_ease();

  @media @xs {
    width: 100% !important;
    height: 990px;
  }
}

.seasonfeature-article {
  float: left;
  width: 415px;
  height: 495px;
  margin-right: 20px;

  @media @xs {
    width: 100%;
    margin-right: 0;
  }

  a {
    display: block;
  }

  &:last-child {
    margin-right: 0;
  }

  .seasonfeature-article-link {
    padding: 20px;
    background-color: @white;
    .box-shadow(3px 3px 3px #ccc);
  }

  .seasonfeature-article-image {
    width: 100%;
    height: 351px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  .seasonfeature-article-content {
    margin-top: 40px;
    margin-bottom: 20px;
    text-align: center;

    h3 {
      margin: 0;
    }
  }

  .seasonfeature-article-category {
    margin-top: 5px;
    font-weight: 700;
    color: #aab4b8;
    text-transform: uppercase;
    letter-spacing: 4px;
  }
}

.season-feature-sub-title {
  font-family: Figtree, sans-serif;
  font-size: 20px;
  text-transform: none;
  letter-spacing: 1px;
}

.season-feature-title {
  position: absolute;
  top: 56px;
  right: 60px;
  font-family: FigtreeBold, sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 40px;
  color: @white;
  text-transform: uppercase;
  letter-spacing: 4px;
}
