/***********************
    PAGE CONTENT
************************/
.container.page-content {
  @media print {
    padding-top: 0;
  }

  @media @smmdlg {
    padding-top: @header_height_total + 40;
  }

  @media @xs {
    padding-top: @header_height_mobile_total + 20;
  }

  &.no-header {
    @media @smmdlg {
      padding-top: 40px;
    }

    @media @xs {
      padding-top: 20px;
    }
  }
}

/***********************
    PAGE SPACER
************************/
.page-spacer {
  @media print {
    padding-top: 0;
  }

  @media @smmdlg {
    padding-top: @header_height_total;
  }

  @media @xs {
    padding-top: @header_height_mobile_total;
  }
}

/***********************
    PAGE BASE
************************/
.page-base-bg {
  background-image: url('../../../../../web/owp-webclient/assets/images/ra/home-bg.png');
  background-attachment: fixed;
}

/***********************
    MY ACCOUNT
************************/
.favorites-cmp__icon {
  background-image: url('../../../../../web/owp-webclient/assets/images/ra/heart-orange.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.favorites-cmp__text {
  margin-left: -10px;
  text-align: center;
}

.favorites-cmp__icon__no {
  position: relative;
  top: 50%;
  margin-left: -10px;
  text-align: center;
  transform: translateY(-50%);
}

.favorites-cmp__icon--size-1x {
  height: 50px;
}

.favorites-cmp__icon--size-2x {
  height: 80px;
}

/***********************
     CONTACT US
************************/
.contact-us-trigger-container {
  position: relative;
  z-index: @z_below_overlay_above_base;
}

/***********************
     ACTIVE ADVANTAGE
************************/
.active-advantage-iframe-wrapper {
  margin: 20px auto;
  overflow-x: auto;
  border: 1px solid @grey;

  /**
 * The -webkit-overflow-scrolling is a non-standard feature. 
 * We are using this feature because we need to support the iframe scrolling in IOS devices.
 * https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-overflow-scrolling
 */
  -webkit-overflow-scrolling: touch;
}
