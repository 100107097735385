/*********************************************************************************************
  Purpose:
  --------
  Contains print stylesheet definitions.

  Notes:
  ------
  Document all elements with comments.
  Do not add anything other than print related definitions to this file.
**********************************************************************************************/
.no-print {
  @media print {
    display: none !important;
  }
}

.no-screen {
  @media screen {
    display: none;
  }
}

@media print {
  div {
    float: none !important;
  }

  div[class^='print-col'] {
    display: inline-block;
  }

  .print-col-6 {
    width: 50%;
  }

  .print-page-break {
    page-break-after: always;
  }

  .print-page-break:last-child {
    page-break-after: auto;
  }
}
