@import '../../../../less/external/variables.less';

.signin_form {
  &__signin-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid @grey_border;
  }

  &__logo-container {
    max-width: 200px;
    margin-bottom: 2rem;
    background-color: @grey;
  }

  &__signin-statement {
    margin: 30px;

    font-family:
      Helvetica Neue,
      Helvetica,
      Arial,
      sans-serif;
  }

  &__signin-form {
    width: 100%;
    max-width: 320px;
  }

  &__form-group {
    position: relative;
    margin-bottom: 1rem;
  }

  &__form-control {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
  }

  &__password-group {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .checkbox {
      margin-right: 0.5rem;
    }

    .password-toggle {
      position: absolute;
      top: 50%;
      right: 10px;
      cursor: pointer;
      transform: translateY(-50%);
    }
  }

  &__btn-signin,
  &__btn-create-account {
    font-size: 18px !important;
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 0.5rem;
    border: none;
    border-radius: 4px;
  }

  &__btn-signin {
    color: white;
    background-color: #ff6f00 !important;
  }

  &__btn-create-account {
    font-size: 18px !important;
    color: #ff6f00;
    background-color: transparent !important;
    border: 1px solid #ff6f00;
  }

  &__link-forgot-password {
    display: block;
    margin-bottom: 1rem;
    color: #ff6f00;
    text-align: center;
    text-decoration: none;
  }

  &__checkbox-container {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 1rem;
  }

  &__checkbox {
    cursor: pointer;
  }

  &__checkbox-label {
    font-family:
      Helvetica Neue,
      Helvetica,
      Arial,
      sans-serif;
    font-weight: 500;
    font-size: 1.05rem;

    display: flex;
    align-items: center;
    margin-left: 5px;
    cursor: pointer;
  }

  /* Responsive adjustments */
  @media (width <= 768px) {
    &__logo-container .logo {
      max-width: 150px;
    }

    &__signin-form {
      max-width: 90%;
    }
  }
}
