/*********************************************************************************************
  Purpose:
  --------
  Contains overrides for third party components.

  Notes:
  ------
  Document all elements with comments.
  Do not add anything other than overrides for third party components here.
**********************************************************************************************/

/***************************
         alerts
****************************/

.Toastify__toast-container .Toastify__toast--info {
  background-color: #00a2d3;
}

.Toastify__toast-container .Toastify__toast--success {
  background-color: #27ae60;
}

.Toastify__toast-container .Toastify__toast--warning {
  background-color: #ed6e17;
}

.Toastify__toast-container .Toastify__toast--error {
  background-color: #e74c3c;
}

/***************************
         datepicker
****************************/
.DayPicker__week-header ul,
.DayPicker__week-header li,
.DayPicker__week-header ol {
  margin-left: 0;
}

.CalendarDay--blocked-out-of-range {
  color: #cacccd !important;
}

.DateInput {
  width: auto;
  padding: 4px;
  font-family: Figtree, sans-serif;
  font-size: @font-size-body;
  color: @blue_dark;
}

.CalendarDay--selected-span {
  color: @white !important;
  background: @orange_light;
  border: 1px double @orange;
}

.CalendarDay--selected-span.CalendarDay--hovered,
.CalendarDay--selected-span:active {
  color: @orange !important;
  background: @orange_extra-light;
  border: 1px double @orange_light;
}

.CalendarDay--selected-span.CalendarDay--last-in-range {
  border-right: @orange;
}

.CalendarDay--hovered-span,
.CalendarDay--after-hovered-start {
  color: @orange !important;
  background: @orange_extra-light;
  border: 1px double @orange_light;
}

.CalendarDay--hovered-span:active,
.CalendarDay--after-hovered-start:active {
  background: @orange_extra-light;
}

.CalendarDay--selected-start,
.CalendarDay--selected-end,
.CalendarDay--selected {
  color: @white !important;
  background: @orange;
  border: 1px double @orange_extra-light;
}

.CalendarDay--selected-start:active,
.CalendarDay--selected-end:active,
.CalendarDay--selected:active {
  background: @orange;
}

.DayPickerKeyboardShortcuts__show--bottom-right {
  border-right: 33px solid @orange;
}

.DayPickerKeyboardShortcuts__show--bottom-right:hover {
  border-right: 33px solid @orange_light;
}

.DateInput__display-text--focused {
  color: @white;
  background: @orange_light;
  border-color: @orange_extra-light;
}

.DateInput__input {
  cursor: pointer;
}

.DateRangePicker__picker {
  top: 42px;
}

/***************************
         recaptcha V3
****************************/
.grecaptcha-badge {
  visibility: hidden;
}
