.facility-detail-tabset .simple-selector {
  display: table;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  border-bottom: none;
}

.facility-detail-tabset .simple-selector__items {
  display: table-cell;
  width: 50%;
  padding-top: 0;
  text-align: center;
}

.facility-detail-tabset .simple-selector__items .v-selector {
  display: none;
}

.facility-detail-tabset .simple-selector__item,
.facility-detail-tabset .simple-selector__item--selected {
  width: 100%;
  font-size: 12px;
  font-weight: normal;
  color: @grey;
  text-align: center;
  letter-spacing: 3px;
}

.facility-detail-tabset .simple-selector__item--selected,
.facility-detail-tabset .simple-selector__item {
  display: block;
  padding: 8px;
  padding-top: 15px;
  padding-bottom: 10px;
  color: @blue_dark;
  border: 1px solid @border;
  border-bottom: 1px solid #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.facility-detail-tabset .simple-selector__item {
  background-color: @grey_tab;
  border: 1px solid @white;
  border-bottom: 1px solid @border;
}

.facility-detail-tabset .simple-selector__item:hover {
  color: @blue_dark;
  background-color: @grey_tab-light;
}

.site-view-selector {
  display: table;
  width: 100%;
}

.site-view-selector {
  display: table;
}

.site-view-selector__children,
.site-view-selector--selected,
.site-view-selector__item {
  display: table-cell;
  padding: 2px;
  text-align: center;
  border-bottom: 1px solid @border;
}

.site-view-selector--selected,
.site-view-selector__item {
  width: 120px;
  border-top: 1px solid @border;
  border-right: 1px solid @border;
  border-left: 1px solid @border;
}

.site-view-selector--selected .icon-action,
.site-view-selector__item .icon-action {
  margin-top: 6px;
  margin-bottom: 6px;
}

.site-view-selector__item {
  color: @grey;
  cursor: pointer;
  background-color: @grey_tab;
  .transition_ease();
}

.site-view-selector__item a {
  color: @grey;
}

.site-view-selector__item:hover {
  background-color: @grey_tab-light;
}

.site-view-selector--selected .icon-action i,
.site-view-selector__item .icon-action i {
  margin-right: 0;
}

.site-view-selector--selected .icon-action span,
.site-view-selector__item .icon-action span {
  padding-left: 10px;
  font-size: 14px;
}

.site-view-selector--selected {
  color: @blue_dark;
  border-bottom: 1px solid @white;
}

.site-view-selector--selected a {
  color: @blue_dark;
}

.site-view-selector__children .date-pager {
  height: auto;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
  border-bottom: 0;
}
