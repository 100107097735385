/*********************************************************************************************
  Purpose:
  --------
  Contains styles for multiple common/external components.
  Styles for individual components may be moved to separate files later.

  Notes:
  ------
  Document all elements with comments - at some point styles for components may be moved
  to separate files. So documenting is important.
**********************************************************************************************/

/***************************
      typeaheadcmp.js
****************************/
.custom-searchbar .searchbar {
  .blueBorder();

  position: relative;
  left: 0;
  display: block;
  width: 100%;
  height: 44px;
  padding: 0;
  margin: 0;
  background-color: transparent;
}

.custom-searchbar .searchbar__input input {
  height: 44px;
  text-indent: 0;
  background-image: none;
  border: 1px solid @grey_border;
}

.custom-searchbar .searchbar__input .form-control:focus {
  border-color: @grey_border;
}

.custom-searchbar .searchbar__button,
.custom-searchbar .searchbar__button button {
  width: 44px;
  height: 44px;
  padding: 0;
}

.custom-searchbar .searchbar__results.searchbar__results--active {
  display: block;
  background-color: rgb(255 255 255 / 100%);
  border: 1px solid @grey_border;
}

.custom-searchbar .searchbar__results {
  margin-left: 0;
}

.custom-searchbar .searchbar__results .searchResult {
  color: @grey;
}

.custom-searchbar .searchResult::before {
  color: @grey;
}

.custom-searchbar .searchbar__results .searchResult:hover,
.custom-searchbar .searchbar__results .searchResult.active {
  font-weight: 700;
  color: @blue_dark;
  background-color: @white;
}

/***************************
      texteditablecmp.js
****************************/
.text-editable--truncate {
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-editable__edit-icon {
  float: left;
  margin-left: 10px;
}

.editable-text-icon {
  display: inline-block;
  margin-left: 10px;
}

.editable-text-icon:first-child {
  display: inline-block;
  margin-left: 0;
}

.editable-text-icon-edit-mode {
  margin-top: 5px;
}

.editable-text-icon .fa-2x {
  font-size: 2em;
}

.editable-text-icon .fa-3x {
  font-size: 3em;
}

.editable-text-icon .fa-4x {
  font-size: 4em;
}

.editable-text-icon .fa-5x {
  font-size: 5em;
}

.editable-text-icons .base-link + .base-link {
  margin-left: 15px;
}

/***************************
      TextHoverable.js
****************************/
.hoverable-text {
  padding-left: 10px;
  cursor: pointer;
  border-left: 5px solid transparent;
}

.hoverable-text--default-cursor {
  cursor: default;
}

.hoverable-text--align-text {
  margin-left: -15px;
}

.hoverable-text:hover,
.hoverable-text:focus,
.base-link:focus .hoverable-text,
.hoverable-text--selected {
  border-left: 5px solid @orange;
}

.hoverable-bgcolor-none {
  background-color: none;
}

.hoverable-bgcolor-bluelight {
  background-color: @blue_light;
}

.hoverable-bgcolor-bluemuted {
  background-color: @blue_muted;
}

.hoverable-bgcolor-blue80 {
  background-color: @blue-dark-background-eighty;
}

.hoverable-bgcolor-blue90 {
  background-color: @blue_dark-background-ninety;
}

.hoverable-bgcolor-bluedark {
  background-color: @blue_dark;
}

.hoverable-bgstyle {
  display: block;
  padding: 10px;
}

.hoverable-text--font-size-1 {
  @media @lg {
    font-size: @font-size-hoverable-text-1--large;
    line-height: @font-size-hoverable-text-1--large*1.2;
  }

  @media @md {
    font-size: @font-size-hoverable-text-1--medium;
    line-height: @font-size-hoverable-text-1--medium*1.2;
  }

  @media @sm {
    font-size: @font-size-hoverable-text-1--small;
    line-height: @font-size-hoverable-text-1--small*1.2;
  }

  @media @xs {
    font-size: @font-size-hoverable-text-1--xs;
    line-height: @font-size-hoverable-text-1--xs*1.2;
  }
}

.hoverable-text--font-size-2 {
  @media @lg {
    font-size: @font-size-hoverable-text-2--large;
    line-height: @font-size-hoverable-text-2--large*1.2;
  }

  @media @md {
    font-size: @font-size-hoverable-text-2--medium;
    line-height: @font-size-hoverable-text-2--medium*1.2;
  }

  @media @sm {
    font-size: @font-size-hoverable-text-2--small;
    line-height: @font-size-hoverable-text-2--small*1.2;
  }

  @media @xs {
    font-size: @font-size-hoverable-text-2--xs;
    line-height: @font-size-hoverable-text-2--xs*1.2;
  }
}

.hoverable-text--font-size-3 {
  @media @lg {
    font-size: @font-size-hoverable-text-3--large;
    line-height: @font-size-hoverable-text-3--large*1.2;
  }

  @media @md {
    font-size: @font-size-hoverable-text-3--medium;
    line-height: @font-size-hoverable-text-3--medium*1.2;
  }

  @media @sm {
    font-size: @font-size-hoverable-text-3--small;
    line-height: @font-size-hoverable-text-3--small*1.2;
  }

  @media @xs {
    font-size: @font-size-hoverable-text-3--xs;
    line-height: @font-size-hoverable-text-3--xs*1.2;
  }
}

.hoverable-text--font-size-4 {
  @media @lg {
    font-size: @font-size-hoverable-text-4--large;
    line-height: @font-size-hoverable-text-4--large*1.2;
  }

  @media @md {
    font-size: @font-size-hoverable-text-4--medium;
    line-height: @font-size-hoverable-text-4--medium*1.2;
  }

  @media @sm {
    font-size: @font-size-hoverable-text-4--small;
    line-height: @font-size-hoverable-text-4--small*1.2;
  }

  @media @xs {
    font-size: @font-size-hoverable-text-4--xs;
    line-height: @font-size-hoverable-text-4--xs*1.2;
  }
}

.hoverable-text--ellipsis {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.hoverable-text--line-clamp-1 {
  -webkit-line-clamp: 1;
}

.hoverable-text--line-clamp-2 {
  -webkit-line-clamp: 2;
}

/***************************
      AIconAction.js
****************************/
.action-link-line-height-cancel {
  @media @xssm {
    line-height: @line-height-cancel-xs;
  }

  @media @mdlg {
    line-height: @line-height-cancel;
  }
}

.icon-action i,
.icon-action .icon {
  font-size: 18px;
  cursor: pointer;
}

.icon-action.right i,
.icon-action .icon {
  margin-left: 5px;
}

.icon-action.left i,
.icon-action.left .icon {
  margin-right: 5px;
}

.base-link .icon-action {
  color: @grey;
}

.base-link .action-link {
  color: @link-color;
  cursor: pointer;
}

.icon-action.white-text {
  color: @white;
}

/***************************
      SimpleSelector.js
****************************/
.simple-selector {
  display: table;
  width: 100%;
  // border-bottom: 2px solid @grey_border;
}

.simple-selector--border {
  border-bottom: 2px solid @grey_border;
}

.simple-selector--margin {
  //display: inline;
  padding-bottom: 11px;
}

.simple-selector__items {
  display: table-cell;
  padding: 10px 0;
}

.simple-selector__items--margin {
  display: inline;
  margin-right: 25px;

  @media @xs {
    margin-right: 15px;
  }
}

.simple-selector__item--selected,
.simple-selector__item {
  .nav-item();

  font-family: FigtreeBold, sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: normal;
}

.simple-selector__item--uppercase {
  text-transform: uppercase;
  letter-spacing: 1px;
}

.simple-selector__item--selected,
.simple-selector__items:focus {
  padding-bottom: 10px;
  color: @orange;
  border-bottom: 4px solid @orange;
}

.simple-selector__item {
  color: @blue_dark;
}

.simple-selector__items--options-2 {
  width: 50%;
}

.simple-selector__items--options-3 {
  width: 33%;
}

.simple-selector__items--options-4 {
  width: 25%;
}

.simple-selector__item:hover {
  color: @orange;
}

.simple-selector .icon-action {
  float: left;
  margin-left: 20px;
  font-size: 20px;
  color: @link-color;
}

.simple-selector__item--selected .icon-action {
  color: @blue_dark;
}

/***************************
         AIcon.js
****************************/
.a-icon-size-01x {
  font-size: 10px;
}

.a-icon-size-02x {
  font-size: 12px;
}

.a-icon-size-03x {
  font-size: 14px;
}

.a-icon-size-04x {
  font-size: 16px;
}

.a-icon-size-1x {
  font-size: 18px;
}

.a-icon-size-2x {
  font-size: 22px;
}

.a-icon-size-3x {
  font-size: 26px;
}

.a-icon-size-4x {
  font-size: 30px;
}

.a-icon-pad {
  padding-right: 5px;
  padding-left: 5px;
}

/***************************
    ARatingSelector.js
****************************/
.a-rate {
  text-align: center;
  cursor: pointer;
}

.a-rate--disabled {
  cursor: auto;
}

.a-rate-inliner > * {
  display: inline-block;
  padding-left: 10px;
}

.a-rate-inliner > :first-child {
  padding-left: 0;
}

//option icon styling
.a-rate-option {
  font-family: FontAwesome;
  font-size: 22px;

  @media @xs {
    font-size: 16px;
  }
}

.a-rate-option::before {
  content: '\f005';
}

.a-rate-option-selected {
  color: @orange;
}

// test 1
.a-rate-test {
  font-family: FontAwesome;
  font-size: 22px;

  @media @xs {
    font-size: 16px;
  }
}

.a-rate-test::before {
  content: '\f004';
}

.a-rate-test-selected {
  color: @red;
}

// test 2
.a-rate-test-pre-1 {
  font-family: FontAwesome;
  font-size: 22px;
}

.a-rate-test-pre-1::before {
  content: '\f25a';
}

.a-rate-test-pre-2 {
  font-family: FontAwesome;
  font-size: 22px;
}

.a-rate-test-pre-2::before {
  content: '\f25b';
}

.a-rate-test-pre-3 {
  font-family: FontAwesome;
  font-size: 22px;
}

.a-rate-test-pre-3::before {
  content: '\f259';
}

.a-rate-test-pre-4 {
  font-family: FontAwesome;
  font-size: 22px;
}

.a-rate-test-pre-4::before {
  content: '\f257';
}

.a-rate-test-pre-5 {
  font-family: FontAwesome;
  font-size: 22px;
}

.a-rate-test-pre-5::before {
  content: '\f256';
}

.a-rate-test-pre-6 {
  font-family: FontAwesome;
  font-size: 22px;
}

.a-rate-test-pre-6::before {
  content: '\f255';
}

.a-rate-test-pre-7 {
  font-family: FontAwesome;
  font-size: 22px;
}

.a-rate-test-pre-7::before {
  content: '\f256';
}

.a-rate-test-pre-8 {
  font-family: FontAwesome;
  font-size: 22px;
}

.a-rate-test-pre-8::before {
  content: '\f258';
}

.a-rate-test-pre-9 {
  font-family: FontAwesome;
  font-size: 22px;
}

.a-rate-test-pre-9::before {
  content: '\f256';
}

.a-rate-test-pre-10 {
  font-family: FontAwesome;
  font-size: 22px;
}

.a-rate-test-pre-10::before {
  content: '\f257';
}

.a-rate-test-pre-selected {
  color: @orange;
}

/* MainMenuItems.js */

/* DO NOT DELETE - This has been changed to accomodate menu add ons
specifically for the Rent Gear icon - we may need to put this back
and it is very hard to figure out the correct settings - so leave 
this as is please. Thanks.
*/
// .main-menu-items{
//   width: 365px;
//   margin-right: 15px;
//   @media @sm{
//     width: 305px;
//   }
//   @media @xs{
//     margin-right: 55px;
//     margin-top: 5px;
//     width: 130px;
//   }
// }

.main-menu-items {
  width: 448px;
  margin-right: 15px;

  @media @sm {
    width: 364px;
  }

  @media @xs {
    width: 170px;
    margin-top: 5px;
    margin-right: 55px;
  }
}

.main-menu-items .menu-item__chevron {
  @media @xs {
    position: relative;
    top: -10px;
    left: 5px;
  }
}

.main-menu-items .menu-item__container--vertical {
  float: right;
  height: 45px;

  @media @xs {
    min-width: 30px;
    height: 35px;
    margin-right: 10px;
    margin-left: 0;
  }
}

.main-menu-items .menu-item__text {
  @media @xs {
    display: none;
  }
}

.main-menu-items__cart-timer .menu-item__text {
  @media @xs {
    display: inline;
  }
}

.main-menu-items__cart-timer .menu-item__container--vertical {
  min-width: 36px;
}

.main-menu-items__desktop {
  padding-top: 5px;
}

/***************************
       BurgerMenu.js
****************************/
.site-navigation-menu__menu-items .menu-items__container {
  width: 100%;
}

.site-navigation-menu__menu-items .main-menu-items {
  width: 100%;
  margin-top: 0;
  margin-left: 0;
}

.site-navigation-menu__menu-items .menu-item__chevron {
  position: inherit;
}

.site-navigation-menu__menu-items .menu-item__icon {
  line-height: 25px;
}

.site-navigation-menu__menu-items .menu-item__text {
  display: inline;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
}

.site-navigation-menu__menu-items .menu-item__text div {
  display: inline;
}

.site-navigation-menu__menu-items .panel {
  background-color: rgb(31 43 52 / 0%);
}

.site-navigation-menu__menu-items .panel-body {
  padding: 0;
}

.main-menu-items__burger .sub-menu-item__container {
  background: none;
}

.site-navigation-menu__menu-items .sub-menu-item {
  margin-top: 15px;
  list-style: none;
  border: none;
}

.site-navigation-menu__menu-items .sub-menu-item:hover {
  background: none;
  border: none;
}

.site-navigation-menu__menu-items .sub-menu-item__text {
  padding: 0;
  padding-left: 65px;
  font-size: 12px;
  line-height: 14px;
  color: @white;
  border: none;
}

.site-navigation-menu__menu-items .sub-menu-item__text .base-link {
  padding: 5px 0 0;
  color: @white;
}

.site-navigation-menu__menu-items .sub-menu-item__text .base-link:hover,
.site-navigation-menu__menu-items .sub-menu-item__text .base-link:focus {
  padding-left: 0;
  margin-left: 0;
  color: @orange;
  background: none;
  border: none;
}

.site-navigation-menu__menu-items .main-menu-items__desktop {
  display: none;
}

.site-navigation-menu__menu-links {
  margin-top: 80px;
}

.main-menu-items__burger {
  display: none;
}

.site-navigation-menu__menu-items .main-menu-items__burger {
  display: inline;
}

.main-menu-items__burger .menu-item__icon-container--horizontal {
  width: 40px;
}

.main-menu-items__burger .menu-item__text-container--horizontal {
  width: 680px;
}

.main-menu-items__burger .header-cart-summary__description {
  display: inline;
}

.main-menu-items__burger .header-cart-summary__timer--hide {
  display: none;
}

/***********************
      BREADCRUMBS
************************/
.breadcrumb {
  @media @xssm {
    padding-top: 25px;
    margin-top: 105px;
    margin-bottom: 10px;
    margin-left: 15px;
  }

  @media @lg-min {
    display: none;
  }
}

.breadcrumb__item {
  color: @grey;
  cursor: default;
}

.breadcrumb__item-current {
  font-weight: 700;
  color: @black;
  cursor: default;
}

.breadcrumb__item.clickable,
.breadcrumb__item-current.clickable {
  cursor: pointer;
}

.breadcrumb__separator {
  display: inline-block;
  margin-right: 8px;
  margin-left: 8px;
  color: @grey;
}

/***********************
      User challenge
************************/

/* Google Recaptcha - Hack */
@media screen and (height <= 575px) {
  .user-challenge {
    margin-bottom: -12px;
    transform: scale(0.77);
    transform-origin: 0 0;
  }
}

.user-challenge {
  margin-top: 20px;
}

/***********************
      AFileSelector.js
************************/
.a-file-selector .progress {
  margin-bottom: 0;
}

.a-file-selector.has-error .help-block {
  margin-top: -10px;
}

.a-file-selector__facility-details input[type='file'] {
  width: 100%;

  @media @md {
    max-width: 250px;
  }

  @media @xssm {
    max-width: 240px;
  }
}

/***********************
      Forms
************************/
:not(.lt-ie9) {
  /* HTML5 Boilerplate accessible hidden styles */
  [type='radio'] {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    border: 0;
  }

  [type='radio'] + span {
    display: block;
  }

  /* the basic, unchecked style */
  [type='radio'] + label::before {
    display: inline-block;
    width: 1em;
    height: 1em;
    margin-right: 0.75em;
    vertical-align: -0.25em;
    content: '';
    border: 0.2em solid #fff;
    border-radius: 1em;
    box-shadow: 0 0 0 0.15em @grey_form;
    transition: 0.5s ease all;
  }

  /* the checked style using the :checked pseudo class */
  [type='radio']:checked + label::before {
    background: @grey_form;
    box-shadow: 0 0 0 0.2em @grey_form;
  }
}

.control-label {
  margin-bottom: 4px;
}

.l0 {
  display: inline-block;
  font-weight: 700;
  line-height: normal;
}

.radio,
.checkbox {
  margin-top: 0;
  margin-bottom: 10px;
  line-height: 30px;
}

.checkbox span {
  display: inline;
  margin-right: 10px;
  line-height: 30px;
}

.inliner-padding .button-selector-checkbox {
  padding: 0 10px 0 0;
}

/***********************
      AInputHolder.js
************************/
.input-holder-notes {
  margin-top: 0;
}

// .input-holder-main .input-holder-notes {
//   margin-top: -15px;
// }

.btn-selection-wrapper .input-holder-notes,
.btn-selection-wrapper .help-block {
  margin-top: -15px;
}

.input-holder-label .input-holder-notes {
  margin-top: 0;
}

.input-holder-label {
  margin-bottom: 10px;
}

.input-holder-highlight {
  padding: @container-pad-top @container-pad-right 1px @container-pad-left;
  margin-bottom: @section-main-bottom;
}

.input-holder-highlight-bg-important {
  color: black;
  background-color: @yellow;
}

.input-holder-highlight-bg-important-legacy {
  color: black;
  background-color: @yellow-light;
}

.input-holder-highlight-bg-danger {
  color: @white;
  background-color: @red;
}

.wrapper-style-important {
  .input-holder-highlight;
  .input-holder-highlight-bg-important;
}

.wrapper-style-important-legacy {
  .input-holder-highlight;
  .input-holder-highlight-bg-important-legacy;
}

.wrapper-style-danger {
  .input-holder-highlight;
  .input-holder-highlight-bg-danger;
}

/***********************
      AStepper.js
************************/
.stepper {
  // margin-top:@gutter;
}

.btn.stepper__decremmentor {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn.stepper__incrementor {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.stepper__controls {
  display: table;
  width: 140px;
  border: 1px solid @border;
  .border-radius(4px);
}

.stepper__value {
  display: table-cell;
  width: 58px;
  height: 36px;
  font-family: FigtreeBold, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 12px;
  text-align: center;
  border: 0;
}

.stepper button {
  display: table-cell;
  width: 40px;
  height: 40px;
}

.stepper label {
  clear: both;
}

.stepper input {
  border-radius: 0;
}

.stepper .form-group {
  margin: 0;
}

/***********************
      AToolTip.js
************************/
.owp-tooltip-trigger:hover .owp-tool-tip {
  display: block;
}

.owp-tool-tip {
  position: absolute;
  left: 50%;
  z-index: 1010;
  display: none;
  max-width: 276px;
  padding: 1px;
  text-align: left;
  white-space: normal;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgb(0 0 0 / 20%);
  border-radius: 6px;
  box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
}

.owp-tool-tip::after,
.owp-tool-tip::before {
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  pointer-events: none;
  content: ' ';
  border: solid transparent;
}

.owp-tool-tip::after {
  margin-left: -6px;
  border-color: rgb(136 183 213 / 0%);
  border-width: 6px;
  border-top-color: #fff;
}

.owp-tool-tip::before {
  margin-left: -6px;
  border-color: rgb(194 225 245 / 0%);
  border-width: 6px;
  border-top-color: #fff;
}

.owp-tool-tip__content {
  padding: 9px 14px;
  color: @grey;
  text-align: center;
}

/***********************
      Spinner.js
************************/
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  position: fixed;
  top: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 120px;
  height: 100%;
  min-height: 120px;
  visibility: visible;
  background: #fff;
}

.spinner--inline {
  position: relative;
  justify-content: center;
}

.spinner::before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-top: -25px;
  margin-left: -25px;
  content: 'Loading';
}

.spinner:not(:required)::before {
  content: '';
  border: 4px solid transparent;
  border-top-color: #03ade0;
  border-bottom-color: #03ade0;
  border-radius: 50%;
  animation: spinner 0.8s ease infinite;
}

.spinner::after {
  position: absolute;
  top: calc(50% + 50px);
  left: calc(50% - 100px);
  max-width: 200px;
  height: 50px;
  font-size: 20px;
  text-align: center;
  visibility: hidden;
  content: attr(data-label);
}

.spinner--labelled::after {
  visibility: visible;
}

.no-spinner {
  position: fixed;
  top: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 120px;
  height: 100%;
  min-height: 120px;
  visibility: hidden;
  background: rgb(0 0 0 / 0%);
  opacity: 0;
  transition: all 0.5s linear;
}

/***************************
      PagingControl.js
****************************/
.paging-control--top-border {
  padding-top: 10px;
  border-top: 1px solid @grey_border;
}

.paging-control--full-width {
  width: 100%;
}

.paging-control--full-width .paging-control__records {
  float: left;
  width: 60%;
  margin-right: 0;
}

.paging-control--full-width .paging-control__directions {
  float: right;
  width: 35%;
  text-align: right;
}

/***************************
      NotificationBubble.js
****************************/
.notification-bubble {
  display: inline-block;
  padding: 3px 6px;
  line-height: 10px;
  text-align: center;
  white-space: nowrap;
  vertical-align: bottom;
  border-radius: 10px;
}

.notification-bubble--position-top-right {
  position: relative;
  top: -10px;
  margin-left: 4px;
}

.notification-bubble--position-normal {
}

.notification-bubble--bg-color-orange {
  background-color: @orange;
}

.notification-bubble--bg-color-green {
  background-color: @green_cart_timer;
}

.notification-bubble--bg-color-grey {
  background-color: @grey;
}

.notification-bubble--bg-color-red {
  background-color: @red;
}

.notification-bubble--bg-color-yellow {
  background-color: @yellow;
}

/***************************
      ABaseLink.js
****************************/
a.base-link--orange-hover:hover,
.base-link--orange-hover span:hover {
  color: @orange;
}

.base-link--right-margin {
  margin-right: 10px;
}

.base-link--left-margin {
  margin-left: 10px;
}

.base-link--btn {
  display: inline-block;
  padding: 8px 12px;
  background: @orange;
  border-radius: 5px;
  transition: all 0.15s ease-out;

  .action-link {
    color: @white;
  }

  &:hover {
    background: #f18c46 !important;
  }
}

/***************************
      ChildSeparator.js
****************************/
.child-separator-row {
  overflow: hidden;
}

.child-separator-row:not(:first-child) {
  margin-top: 20px;
}

.child-separator-row .child-separator-col {
  box-sizing: border-box;
  padding-bottom: 99999px;
  margin-bottom: -99999px;
  border-right: 2px solid @grey_border;
}

.child-separator-row .child-separator-col:last-child {
  border-right: none;
}

.child-separator-col.child-separator-col--bottom-border {
  @media @xs {
    padding-bottom: 20px;
    margin-bottom: 0;
    border-right: none;
    border-bottom: 2px solid @grey_border;
  }
}

.child-separator-col.child-separator-col--bottom-border:not(:first-child) {
  @media @xs {
    padding-top: 20px;
  }
}

/***************************
      ADateRangePicker.js
****************************/

.datepicker-help {
  padding-right: 21px;
  padding-bottom: 10px;
  padding-left: 21px;
  color: @blue_dark;
}

/***************************
      RolloutMenu
****************************/

.rollout-menu-container {
  position: fixed;
  right: 4px;
  bottom: 60px;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  // height: 0px;
  @media @xssm {
    bottom: 80px;
  }
}

.rollout-menu-content {
  display: flex;
  flex-direction: column-reverse;
}

.rollout-menu-item {
  &-init {
    position: relative;
    right: -200px;
  }

  &-in {
    animation: rollout-menu-item-in 0.3s ease-in-out 1;
    animation-fill-mode: both;
  }

  &-out {
    animation: rollout-menu-item-out 0.3s ease-in-out 1;
    animation-fill-mode: both;
  }
}

@keyframes rollout-menu-item-in {
  0% {
    transform: translateX(200px);
  }

  80% {
    transform: translateX(-4px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes rollout-menu-item-out {
  0% {
    transform: translateX(0);
  }

  20% {
    transform: translateX(-4px);
  }

  100% {
    transform: translateX(200px);
  }
}

.rollout-menu-item-0 {
  animation-delay: 0s;
}

.rollout-menu-item-1 {
  animation-delay: calc(0.1s * 1);
}

.rollout-menu-item-2 {
  animation-delay: calc(0.1s * 2);
}

.rollout-menu-item-3 {
  animation-delay: calc(0.1s * 3);
}

.rollout-menu-item-4 {
  animation-delay: calc(0.1s * 4);
}

.rollout-menu-item-5 {
  animation-delay: calc(0.1s * 5);
}

.rollout-menu-item-6 {
  animation-delay: calc(0.1s * 6);
}

.rollout-menu-item-7 {
  animation-delay: calc(0.1s * 7);
}

.rollout-menu-item-8 {
  animation-delay: calc(0.1s * 8);
}

.rollout-menu-item-9 {
  animation-delay: calc(0.1s * 9);
}

/***************************
      TextBlock
****************************/

.default-html-style p {
  padding-bottom: 10px;
}

/***************************
      DynamicContent
****************************/

.dc-content-head {
  height: auto;
  // padding-top: 14px;
  // padding-bottom:0;
  margin: 0;
  font-family: FigtreeBold, sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: @blue_dark;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.dc-head {
  font-family: FigtreeBold, sans-serif;
  font-size: @font-size-h3;
  font-size: 15px;
  font-weight: 600;
  color: @blue_dark;
  text-transform: uppercase;
  letter-spacing: 3px;
  // margin-top:20px;
  // margin-bottom:20px;
}

.dc-infohead {
  padding: 10px;
  padding-right: 5px;
  font-family: FigtreeBold, sans-serif;
  font-weight: 700;
  color: @blue_dark;
  background-color: @bulletin;
  border: 1px solid @bulletin-border;
}

.dc-infohead::before {
  display: block;
  float: left;
  width: 20px;
  height: 30px;
  font-family: FontAwesome;
  content: '\f05a';
}

.dynamic-content .panel-body {
  margin-bottom: 20px;
  border-bottom: 1px solid @grey;
}
