/***************************
      AlignMultiCols.js
****************************/

.row.align-multi-cols {
  margin-right: -20px;
  margin-left: -20px;
}

.row.align-multi-cols > [class*='col-'] {
  padding-right: 20px;
  padding-left: 20px;

  @media @xssm {
    width: 100%;
    min-width: 100%;
  }
}

.row.align-multi-cols > [class*='col-']:not(:first-child) {
  border-left: 2px solid @grey_border;

  @media @xssm {
    border-left: none;
  }
}

.align-multi-cols-column > div,
.align-multi-cols-column > div > div {
  height: 100%;
  min-height: 100%;
}

.align-multi-cols-column:not(:first-child) {
  @media @xssm {
    padding-top: 20px;
    border-top: 2px solid @grey_border;
  }
}

.align-multi-cols-column:not(:last-child) {
  @media @xssm {
    padding-bottom: 20px;
  }
}
