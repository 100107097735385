.@{wi-css-prefix}-wsymbol_0001:before { content: @day-sunny; };
.@{wi-css-prefix}-wsymbol_0002:before { content: @day-cloudy; };
.@{wi-css-prefix}-wsymbol_0003:before { content: @cloud; };
.@{wi-css-prefix}-wsymbol_0004:before { content: @cloudy; };
.@{wi-css-prefix}-wsymbol_0006:before { content: @day-fog ; };
.@{wi-css-prefix}-wsymbol_0007:before { content: @fog; };
.@{wi-css-prefix}-wsymbol_0008:before { content: @night-cloudy; };
.@{wi-css-prefix}-wsymbol_0009:before { content: @rain; };
.@{wi-css-prefix}-wsymbol_0010:before { content: @rain-mix; };
.@{wi-css-prefix}-wsymbol_0011:before { content: @snow; };
.@{wi-css-prefix}-wsymbol_0012:before { content: @snowflake-cold; };
.@{wi-css-prefix}-wsymbol_0013:before { content: @sleet; };
.@{wi-css-prefix}-wsymbol_0016:before { content: @thunderstorm; };
.@{wi-css-prefix}-wsymbol_0017:before { content: @day-rain; };
.@{wi-css-prefix}-wsymbol_0018:before { content: @day-rain-mix; };
.@{wi-css-prefix}-wsymbol_0019:before { content: @day-snow; };
.@{wi-css-prefix}-wsymbol_0020:before { content: @snow; };
.@{wi-css-prefix}-wsymbol_0021:before { content: @day-sleet; };
.@{wi-css-prefix}-wsymbol_0024:before { content: @thunderstorm; };
.@{wi-css-prefix}-wsymbol_0025:before { content: @night-alt-rain; };
.@{wi-css-prefix}-wsymbol_0026:before { content: @night-alt-rain-mix; };
.@{wi-css-prefix}-wsymbol_0027:before { content: @night-snow; };
.@{wi-css-prefix}-wsymbol_0028:before { content: @night-alt-snow; };
.@{wi-css-prefix}-wsymbol_0029:before { content: @night-sleet; };
.@{wi-css-prefix}-wsymbol_0032:before { content: @night-thunderstorm; };
.@{wi-css-prefix}-wsymbol_0033:before { content: @night-rain; };
.@{wi-css-prefix}-wsymbol_0034:before { content: @night-rain-mix; };
.@{wi-css-prefix}-wsymbol_0035:before { content: @night-snow; };
.@{wi-css-prefix}-wsymbol_0036:before { content: @snowflake-cold; };
.@{wi-css-prefix}-wsymbol_0037:before { content: @night-sleet; };
.@{wi-css-prefix}-wsymbol_0040:before { content: @night-thunderstorm; };
