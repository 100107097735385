/***********************
    CART CART CART
************************/
.cart-contract-header__fees {
  font-size: @font-size-h3;
  color: @blue_dark;
}

.money {
  text-align: right;
}

.cart-contract-summary__item,
.cart-contract-summary__item--total {
  display: table;
  width: 100%;
}

.cart-order-totals {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid @border;
}

.cart-contract-summary__item--total {
  margin-top: 10px;
  font-family: FigtreeBold, sans-serif;
  font-weight: 700;
  border-top: 1px solid @border;
}

.cart-contract-summary__amount {
  text-align: right;
}

.cart-contract-summary__label,
.cart-contract-summary__amount {
  display: table-cell;
  width: 50%;
  color: @blue_dark;
}

.cart-contract-header,
.cart-contract-summary__header {
  font-size: @font-size-h3;
}

.cart-contract-summary__label {
  font-size: @font-size-h3;
}

.order-item__fees {
  text-align: right;
}

.container.cart {
  @media print {
    padding-top: 0;
  }

  @media @smmdlg {
    padding-top: 105px;
  }

  @media @xs {
    padding-top: 70px;
  }
}

.item-cost {
  font-size: 24px;
  color: @blue_dark;
}

.cart-order-actions {
  margin-top: 10px;
  margin-bottom: 10px;
}

/***********************
    CART SUMMARY
************************/

.header-cart-summary {
  position: relative;
  margin-top: 20px;
  font-size: @font-size-h5;
  text-align: right;
}

.header-cart-summary i {
  margin-right: 10px;
}

/***********************
        TIMER
************************/

.header-cart-summary__timer {
  font-family: FigtreeBold, sans-serif;
  font-weight: 600;
}

.header-cart-summary__timer--green {
  color: @green_cart_timer;
}

.header-cart-summary__timer--yellow {
  color: @yellow;
}

.header-cart-summary__timer--red {
  color: @red;
}

.header-cart-summary__timer--white {
  color: @white;
}

.header-cart-summary__description {
  display: none;
}

.cart-timer-min,
.cart-timer-sec {
  @media @xs {
    display: none;
  }

  padding-right: 3px;
  padding-left: 3px;
}

.hcenter {
  width: 100%;
  height: 100%;
  text-align: center;
}

.cart-fallback-buttons-container {
  display: flex;
  gap: 8px;
  justify-content: space-between;
}

.cart-fallback-buttons-container > button {
  margin: 0 !important;
  margin-bottom: 20px !important;
}

.cart-main-title {
  font-family: FigtreeBold, sans-serif;
  font-size: 18px;
  font-weight: 700;
  font-weight: 700;
  color: black;
  text-transform: uppercase;
  letter-spacing: 0.3px;
}

.cart-main-title--with-adornments {
  display: flex;
  align-items: center;
}

.cart-main-title-num-items {
  display: inline;
  font-family: Figtree, sans-serif;
  font-size: 18px;
  font-weight: normal;
  color: @grey;
  text-transform: none;
  letter-spacing: 0.3px;
}

.cart-title {
  margin-top: 20px;
  font-family: FigtreeBold, sans-serif;
  font-size: @font-size-h2;
  font-weight: 700;
  color: @black;
  text-transform: uppercase;
  letter-spacing: 0.3px;
}

.cart-msg-danger {
  color: @red;
}

.card-icon__wrapper {
  display: flex;
  align-items: center;
  margin-left: 8px;
}

.card-icon {
  width: 42px;
  height: 30px;
  margin-left: 6px;

  & > svg {
    border: 1px solid #a8a8a8;
  }
}

.amount-statement {
  display: flex;
  justify-content: space-between;
}

.amount-statement .amount-text {
  float: left;
}

.amount-statement .amount-value {
  float: right;
}

.cart-statement {
  margin-bottom: 10px;
}

.site-security-logos span {
  padding-right: 10px;
}

.cart-summary-header {
  padding: 10px;
  margin-top: -10px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: @cerulean;
}

.cart-summary-header-amount .amount-text {
  float: left;
  font-size: 18px;
  color: white;
}

.cart-summary-header-amount .amount-value {
  float: right;
  font-size: 18px;
  color: white;
}

.gift-card__apply-button {
  margin-top: 25px;
}

.loyalty-info-container {
  display: table;
  width: 100%;
  padding: 20px;
  margin-bottom: 20px;
  background-image: url('../../../../../web/owp-webclient/assets/images/ra/loyalty-info-bg.png');
}

/*
.loyalty-info-image {
  background: url('../../../../../web/owp-webclient/assets/images/ra/loyalty-info-logo.png') no-repeat;
  display: table-cell;
  width: 44px;
  height: 44px;
}
*/

.loyalty-info-text {
  display: table-cell;
  float: left;
  width: 100%;
  //margin-left: 20px;
  height: 100%;
  color: white;
}

.cart-success {
  font-weight: 700;
  color: @bluish_green;
}

.cart-error {
  font-weight: 700;
  color: @red;
}

.cart-highlight {
  font-weight: 700;
  color: @cerulean;
}

.hide-xssm {
  @media @xssm {
    display: none;
  }
}

.hide-xs {
  @media @xs {
    display: none;
  }
}

.hide-smmdlg {
  @media @smmdlg {
    display: none;
  }
}

.hide-mdlg {
  @media @mdlg {
    display: none;
  }
}

.cart-main-total-line {
  margin-bottom: 5px;
}

.cart-sub-total-line {
  margin-bottom: 5px;
  font-size: 12px;
  font-style: italic;
}
