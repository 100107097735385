.facility-availability-text {
  font-family: FigtreeBold, sans-serif;
  font-weight: 700;
  text-align: right;
}

.facility-availability-cta {
  /* position: absolute;
  bottom:0; */
}

.facility-availability-text.not_available {
  color: @status-card-unavailable;
}

.facility-availability-text.web_available {
  color: @status-card-availability;
}

.facility-availability-text.call_available {
  color: @status-card-possible-availability;
}

.facility-availability-text.field_available {
  color: @status-card-possible-availability;
}

.facility-availability-text.in_lottery {
  color: @status-card-possible-availability;
}

.facility-availability-cta {
  color: @blue_dark;
}

.facility-availability-cta:hover {
  color: @blue_dark-hover;
}
