/*********************************************************************************************
  Purpose:
  --------
  All common/external transitions should be defined here.

  Notes:
  ------
  Document all elements with comments.
**********************************************************************************************/

/*******************************
      REACT CMP TRANSITIONS
********************************/

/** opacity **/
.opacity-enter {
  opacity: 0.01;
}

.opacity-enter.opacity-enter-active {
  opacity: 1;
  transition: none; //opacity 300ms.  Turning off for IE/others
  transition: opacity 300ms ease-in; //chrome/safari
  transition: opacity 300ms ease-in; //firefox
  transition: opacity 300ms ease-in; // opera
}

.opacity-leave {
  opacity: 1;
}

.opacity-leave.opacity-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
  transition: opacity 300ms ease-in;
  transition: opacity 300ms ease-in;
  transition: none;
}

.opacity-appear {
  opacity: 0.01;
}

.opacity-appear.opacity-appear-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
  transition: opacity 300ms ease-in;
  transition: opacity 300ms ease-in;
  transition: none;
}

/** expand **/
.expand-enter {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s;
  transition: max-height 0.3s;
  transition: max-height 0.3s;
}

.expand-enter.expand-enter-active {
  max-height: 500px;
}

.expand-leave {
  max-height: 500px;
}

.expand-leave.expand-leave-active {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s;
  transition: max-height 0.3s;
  transition: max-height 0.3s;
}

.expand-appear {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s;
  transition: max-height 0.3s;
  transition: max-height 0.3s;
}

.expand-appear.expand-appear-active {
  max-height: 500px;
}

/** slide **/
.slide-appear {
  opacity: 0;
  transform: translate(-250px, 0);
  transform: translate3d(-250px, 0, 0);
}

.slide-appear.slide-appear-active {
  opacity: 1;
  transition: opacity 300ms ease;
  transition-timing-function: cubic-bezier(0.175, 0.665, 0.32, 1), linear;
  transition-duration: 300ms;
  transition-property: transform, opacity;
  transform: translate(0, 0);
  transform: translate3d(0, 0, 0);
}

.slide-enter {
  opacity: 0;
  transform: translate(-250px, 0);
  transform: translate3d(-250px, 0, 0);
}

.slide-enter.slide-enter-active {
  opacity: 1;
  transition: opacity 300ms ease;
  transition-timing-function: cubic-bezier(0.175, 0.665, 0.32, 1), linear;
  transition-duration: 300ms;
  transition-property: transform, opacity;
  transform: translate(0, 0);
  transform: translate3d(0, 0, 0);
}

.slide-leave {
  opacity: 1;
  transition-timing-function: cubic-bezier(0.175, 0.665, 0.32, 1), linear;
  transition-duration: 300ms;
  transition-property: transform, opacity;
  transform: translate(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slide-leave.slide-leave-active {
  opacity: 0;
  transform: translate(250px, 0);
  transform: translate3d(250px, 0, 0);
}

/****************************
      OTHER TRANSITIONS
*****************************/
.transition_cubic-bezier() {
  transition: all 0.25s cubic-bezier(1, 0, 0, 1);
  transition: all 0.25s cubic-bezier(1, 0, 0, 1);
  transition: all 0.25s cubic-bezier(1, 0, 0, 1);
  transition: all 0.25s cubic-bezier(1, 0, 0, 1);
}

.transition_ease() {
  transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.transition_ease-fast() {
  transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.transition_opacity() {
  transition: opacity 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out;
}

.transition_colour() {
  transition: color 0.09s ease-in-out;
  transition: color 0.09s ease-in-out;
  transition: color 0.09s ease-in-out;
  transition: color 0.09s ease-in-out;
}

.transition_easeSlow() {
  transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
  transition: all 0.45s ease-in-out;
}
