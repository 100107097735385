/*********************************************************************************************
  Purpose:
  --------
  Contains common fonts.

  Notes:
  ------
  Document all elements with comments.
  Do not add anything other than font descriptions to this file.
**********************************************************************************************/

/* Georgia */

/* @base-url: "http://ra.dev.reserveamerica.com:9000../newra/assets/fonts"; */
@base-url: '../../../web/owp-webclient/assets/fonts';

@font-face {
  font-family: Georgia;
  src: url('@{base-url}/Georgia/Georgia.otf') format('embedded-opentype');
  src: url('@{base-url}/Georgia/Georgia.ttf') format('truetype');
}

/* Figtree */

@font-face {
  font-family: Figtree;
  font-style: normal;
  font-weight: 400;
  src: url('@{base-url}/Figtree/Figtree-Regular.ttf') format('truetype');
}


@font-face {
  font-family: Figtree;
  font-style: normal;
  font-weight: 300;
  src: url('@{base-url}/Figtree/Figtree-Light.ttf') format('truetype');
}

@font-face {
  font-family: Figtree;
  font-style: normal;
  font-weight: 600;
  src: url('@{base-url}/Figtree/Figtree-Medium.ttf') format('truetype');
}

@font-face {
  font-family: Figtree;
  font-style: normal;
  font-weight: bold;
  src: url('@{base-url}/Figtree/Figtree-Bold.ttf') format('truetype');
}

@font-face {
  font-family: Figtree;
  font-style: normal;
  font-weight: 700;
  src: url('@{base-url}/Figtree/Figtree-Bold.ttf') format('truetype');
}

@font-face {
  font-family: FigtreeBold;
  font-style: normal;
  font-weight: 700;
  src: url('@{base-url}/Figtree/Figtree-Bold.ttf') format('truetype');
}

@font-face {
  font-family: FigtreeLight;
  font-style: normal;
  font-weight: 300;
  src: url('@{base-url}/Figtree/Figtree-Light.ttf') format('truetype');
}

@font-face {
  font-family: FigtreeMedium;
  font-style: normal;
  font-weight: 600;
  src: url('@{base-url}/Figtree/Figtree-Medium.ttf') format('truetype');
}
