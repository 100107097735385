/*********************************************************************************************
  Purpose:
  --------
  Contains overrides speicific to the Sales Force web chat.

  Notes:
  ------
  Document all elements with comments.
  Do not add anything other than Sales Force web chat style overrides.
**********************************************************************************************/

.embeddedServiceHelpButton .helpButton .uiButton.helpButtonDisabled {
  display: none;
}
// .embeddedServiceHelpButton .helpButton .uiButton.helpButtonEnabled { display: none; }

/* .embeddedServiceSidebarMinimizedDefaultUI {{
    position: fixed;
    left: auto;
    bottom: 76px;
    right: -68px;
    margin: 0;
    height: 46px;
    width: 90px;
    max-height: 100%;
    border-radius: 23px 23px 23px 23px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);
    pointer-events: all;
    overflow: hidden;
    background-color: #000;
    border-color: #000;
    font-size: 16px;
    
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
} */

@media all and (width <= 991px) {
  .embeddedServiceSidebarMinimizedDefaultUI.sidebarHeader {
    /* bottom: 56px; */
    right: 4px;
    bottom: 50px;
    height: 31px;
    padding: 0;
    text-decoration: none;
    background-color: #58ae25;
    border-radius: 23px;
    // box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);
    box-shadow: none;
  }

  .embeddedServiceSidebar.modalContainer {
    z-index: 1400;
  }

  .embeddedServiceHelpButton .helpButton {
    right: 4px;
    bottom: 50px !important;
    height: 31px !important;
  }

  .embeddedServiceHelpButton .helpButton .uiButton {
    width: 110px;
    min-width: 20px;
    height: 31px;
    padding-right: 12px;
    padding-left: 12px;
    font-size: 12px;
    border-radius: 23px;
    // box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);
    box-shadow: none;
  }

  .embeddedServiceHelpButton .uiButton {
    /* min-width: 4em; */
  }
}

@media (width >=992px) {
  .embeddedServiceSidebarMinimizedDefaultUI.sidebarHeader {
    position: fixed;

    /* bottom: 154px; */
    right: 4px;
    bottom: 20px;
    left: auto;
    display: inline-block;
    width: 90px;
    height: 41px;
    max-height: 100%;
    margin: 0;
    overflow: hidden;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    pointer-events: all;
    background-color: #58ae25;
    border-color: #58ae25;
    border-radius: 23px;
    // box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);
    box-shadow: none;
  }

  .embeddedServiceSidebar.modalContainer {
    z-index: 1400;
  }

  .embeddedServiceHelpButton .helpButton {
    right: 4px;
    bottom: 20px;
    height: 41px !important;
  }

  .embeddedServiceHelpButton .helpButton .uiButton {
    width: 130px;
    min-width: 20px;
    height: 41px;
    padding-right: 20px;
    padding-left: 20px;
    font-size: 14px;
    border-radius: 23px;
    // box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);
    box-shadow: none;
  }

  .embeddedServiceHelpButton .uiButton {
    /* min-width: 4em; */
  }
}

.embeddedServiceSidebarMinimizedDefaultUI.minimizedContainer:focus {
  color: rgb(255 255 255);
  text-decoration: none;
  background-color: #58ae25;
  outline: 0;
}

.embeddedServiceSidebarMinimizedDefaultUI.helpButton {
  background-color: #58ae25;
  border-color: #58ae25;
}

.embeddedServiceSidebarMinimizedDefaultUI {
  background-color: #58ae25;
  border-color: #58ae25;
}

.embeddedServiceSidebarMinimizedDefaultUI.minimizedContainer:hover {
  color: rgb(255 255 255);
  background-color: #58ae25;
}

.embeddedServiceHelpButton .helpButton .uiButton:hover::before,
.embeddedServiceHelpButton .helpButton .uiButton:focus::before {
  border-radius: 23px;
}

.helpButtonEnabled:focus .helpButtonLabel {
  text-decoration: none;
}

.embeddedServiceHelpButton .helpButton .uiButton {
  &:hover {
    color: #ed6e17;
  }
}

.embeddedServiceSidebarMinimizedDefaultUI.helpButton .messageContent {
  justify-content: center;
}

// Service Now Styles

.ecEmbedWrapper {
  z-index: 1223 !important;
}

button#ecLauncherFloatingBubble {
  right: 4px !important;
  width: 68px !important;
  height: 41px !important;
  background-color: #000 !important;
  border-radius: 23px !important;
}

@media all and (width <= 991px) {
  button#ecLauncherFloatingBubble {
    bottom: 50px !important;
    width: 48px !important;
    height: 31px !important;
  }
}
