/*********************************************************************************************
  Purpose:
  --------
  Contains styles for TwoColFlex component.
  The style used here is the style for the right column. In large resolution screen, both the 
  container and content will have the same dimension because we dont set the width of the 
  container, thus the content provides the dimension for the container. In the medium screen 
  resolution and below, we are setting the width of the container depending on what we see as 
  the perfect width for that screen resolution. 
  The left content will take the remaining space that the right content did not occupy.
  The number after the style indicates the width of the right content for each breakpoints. 
  Fro example, the breakpoint for extra small screen is too wide for the component that we using, 
  thus we added additional breakpoints. 

  Notes:
  ------
  Document all elements with comments.
**********************************************************************************************/

/**********************
      TwoColFlex
***********************/
.right-flex--style-100_17_15_15_20_25 {
  @media @md {
    max-width: 17%;
  }

  @media @sm {
    max-width: 15%;
  }

  @media @xs {
    max-width: 25%;
  }

  @media (width >= 650px) and (width <= 767px) {
    max-width: 15%;
  }

  @media (width >= 480px) and (width <= 649px) {
    max-width: 20%;
  }
}

.right-flex--style-100_20_15_25 {
  @media @md {
    max-width: 20%;
  }

  @media @sm {
    max-width: 15%;
  }

  @media @xs {
    max-width: 25%;
  }
}
