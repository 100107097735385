/*********************************************************************************************
  Purpose:
  --------
  Contains hacks overrides to address specific issues in the applications.

  Notes:
  ------
  Document all elements with comments.
  Do not add anything other than hack overrides.
**********************************************************************************************/

/*********************************************************************************************
 We are setting the font-size to 16px to all mobiles devices whose resolution is 991px and below. 
 This is a hack to prevent the safari browser in IOS devices from zooming-in when the font on 
 select, textarea and input is less than 16px.
**********************************************************************************************/
@media @xssm {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 16px !important;
  }
}
